/*
BaseDto generated at 2023-10-27 18:58:28.590 +11:00
*/
export class BaseDto {
	TotalRecords?: number
    constructor(properties?: any) {
        if (properties) {
            let that: any = this;
            for (let key in properties) {
                if (that.hasOwnProperty(key)) {
                    that[key] = properties[key];
                } else {
                    that[key] = properties[key];
                }
            }
        }
    }
}
