import { BaseDto } from "../baseDto"
/* WARLResourceBaseDto generated at 2024-05-26 13:54:05.428 +10:00*/
export class WARLResourceBaseDto extends BaseDto {
	WebARLResourceId?: string;
	ResourceName?: string;
	ResourceDescription?: string;
	ImageURL?: string;
	ResourceURL?: string;
	Sequence?: number;
	StatusCode?: string;
}
