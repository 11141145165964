<ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app>
<p-confirmDialog #cd name="cd" appendTo="body" icon="fas fa-question-circle">
  <div class="flex-wrap">
      <span>{{cd.message}}</span>
  </div>
  <ng-template pTemplate="footer">
      <div class="flex-wrap">
          <div class="mt2 p2" style="width:50%;">
              <div class="flex-wrap" style="width:fit-content;float:right;">
                  <button type="button" class="button button-highlight button-round center w7" (click)="cd.accept()">
                      <i class="fas fa-check-circle"></i> Yes</button>
              </div>
          </div>
          <div class="mt2 p2" style="width:50%;">
              <div class="flex-wrap" style="width:fit-content;float:left;">
                  <button type="button" class="button button-highlight button-round center w7" (click)="cd.reject()">
                      <i class="fas fa-times"></i> No</button>
              </div>
          </div>
      </div>
  </ng-template>
</p-confirmDialog>
<p-toast id="growlError" closable="true" position="top-right" severity="error"></p-toast>