import { BaseDto } from "../baseDto"
/* ProductPackagingBaseDto generated at 2024-05-26 13:54:05.304 +10:00*/
export class ProductPackagingBaseDto extends BaseDto {
	ProductPackagingId?: string;
	ProductId?: string;
	ProjectId?: string;
	SequenceNo?: number;
	PackageTypeCode?: string;
	PackageName?: string;
	Shape_Style?: string;
	PackageWeight?: number;
	PackageWeightUOM?: string;
	ProductQuantity?: number;
	Org_ProductPackagingId?: string;
	Org_ProductId?: string;
	Org_ProjectId?: string;
	Org_StatusCode?: string;
	PREP_ProjectId?: number;
	StatusCode?: string;
}
