import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { NgModule } from "@angular/core";
import { ARLAboutPage, BisARLForBusinessPage, BisHowToJoinPage, ConsrYourChoiceMattersPage, GovGetInvolvedPage, GovResourcesPage, GovUpdatesLogPage, GovWhyItsImportantPage, HomeMainPage, HomePage, HomePreviewPage, HomePriorityAreasPage, HomePublishedPage } from "./features/home-module/component";
const routes: Routes = [
	{ path: "anzpac", loadChildren: () => import("./features/anzpac-module/anzpac.module").then(m => m.AANZPACModule) },
	{ path: "admin", loadChildren: () => import("./features/config-module/config.module").then(m => m.ConfigModule) },
	{ path: "page", component: HomePublishedPage, pathMatch: "full" },
	{ path: "page/:id", component: HomePublishedPage, pathMatch: "full" },
	{ path: "previewpage", component: HomePreviewPage, pathMatch: "full" },
	{ path: "previewpage/:id", component: HomePreviewPage, pathMatch: "full" },
	{ path: "priority-areas", component: HomePriorityAreasPage, pathMatch: "full" },
	{ path: "get-involved", component: GovGetInvolvedPage, pathMatch: "full" },
	{ path: "resources", component: GovResourcesPage, pathMatch: "full" },
	{ path: "update-logs", component: GovUpdatesLogPage, pathMatch: "full" },
	{ path: "why-its-important", component: GovWhyItsImportantPage, pathMatch: "full" },
	{ path: "about", component: ARLAboutPage, pathMatch: "full" },
	{ path: "your-actions-matter", component: ConsrYourChoiceMattersPage, pathMatch: "full" },
	{ path: "arl-for-business", component: BisARLForBusinessPage, pathMatch: "full" },
	{ path: "how-to-join", component: BisHowToJoinPage, pathMatch: "full" },
	{ path: ":id", component: HomePage, pathMatch: "full" },
	{ path: "", component: HomePage, pathMatch: "full" },
	{ path: "**", component: HomePage }
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			enableTracing: false,
			onSameUrlNavigation: "reload",
			// Don't perform initial navigation in iframes
			//initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
			preloadingStrategy: PreloadAllModules
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
