import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
//import { AppMessages, UserDto } from '../../../../core/model';
import { delay } from 'rxjs';
import { UserDto } from 'src/app/core/model/feature/apcomodel';
@Component({
    selector: "auth-confirm-account-page",
    templateUrl: "./confirm-account-page.component.html"
})
export class AuthConfirmAccountPage extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;   
    pagePath: string = "/confirmaccount"; 
    @ViewChild("pageTop") pageTop: HTMLDivElement | undefined;

    userId: string = "";
    confirmationKey: string = "";
    requiresChangePassword: string = "";
    isRequiresPasswordChange: boolean = false;
    newPassword: string = "";
    
    showSetPassword: boolean = false;
    showPassword: boolean = false;
    constructor(appCtx: AppContextService) {
        super(appCtx);
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    ngOnInit() {
        try {            
            this.appCtx.pagePath = this.pagePath;
            this.loadInitData();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.processRedirect();
            this.initPage();
        } catch (ex) {
            console.error(ex);            
        }
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;
            that.setDivElement();
            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe(data => {
                    that.setDivElement();
                    that.setDivTimer();
                });
            }
        }, 100);
    }
    setDivTimer() {
        let that = this;
        this.delaySetDivTimer = setTimeout(function () {
            if (that.delaySetDivTimer) { clearTimeout(that.delaySetDivTimer); }
            that.delaySetDivTimer = undefined;
            that.setDivElement();
        }, 500);
    }
    setDivElement() {
        try {
            // if (window.innerHeight && this.pageTop) {
            //     let mt = 0;
            //     if (window.innerHeight > this.pageTop["nativeElement"].clientHeight) {
            //         mt = (window.innerHeight - this.pageTop["nativeElement"].clientHeight) / 3;
            //     }
            //     this.pageTop["nativeElement"].style.marginTop = mt.toString() + "px";
            // }
        } catch (ex) {
            console.error(ex);
            
        }
    }
    processRedirect() {          
        this.userId = this.getQueryParams("id");
        this.confirmationKey = this.getQueryParams("ckey");
        this.requiresChangePassword = this.getQueryParams("pc");
        if (this.requiresChangePassword === "true") {
            this.isRequiresPasswordChange = true;
            this.resetAll();
            this.newPassword = "";
            this.showSetPassword = true;
        } else {
            this.confirmUserEmail(this.userId, this.confirmationKey, "");
        }
    }
    isValid() {
        let lvValid: any = false;
        try {
            lvValid = this.thisForm && this.thisForm.form.valid;
        } catch (ex) {
            console.error(ex);
        }
        return lvValid;
    }
    resetAll() {
        this.showSetPassword = false;
    }
    onCloseSuccessDialog() {
        this.resetAll();
        //this.gotoHomePage();
    } 
    onRequest() {
        this.confirmUserEmail(this.userId, this.confirmationKey, this.newPassword);
    }
    onSkipPasswordChange() {
        this.confirmUserEmail(this.userId, this.confirmationKey, "");
    }
    onTryAgain() {
        if (this.isRequiresPasswordChange) {
            this.resetAll();
            this.showSetPassword = true;
        } else {
            this.confirmUserEmail(this.userId, this.confirmationKey, "");
        }
    }
    confirmUserEmail(id: string = "", ckey:string ="", newPwd: string = "") {
        try {
            this.resetAll();
            let user = new UserDto({
                UserId: id,
                ConfirmationKey: ckey,
                NewPassword: newPwd
            });
            // this.authorizeSvc.confirmUserEmail(user).subscribe(result => {
            //     this.resetAll();
            //     this.showSuccessDialog = true;
            // }, err => {
            //     this.resetAll();
            //     this.appMsg.clearAddErrorMsg("Something went wrong while confirming your email.");
            //     this.appMsg.Error = err;
            //     this.showError = true;
            // });
        } catch (ex) {
            console.error(ex);
        }
    }
    onCancel()  {
        this.resetAll();
        //this.gotoHomePage();
    }
}