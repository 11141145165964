<div class="flex-wrap" *ngIf="showComponent && visible">
    <div class="flex-wrap">
        <div class="text-center center" style="width: 200px;font-size:1.2em!important;">
            <b>Becumen - Set Password</b>
        </div>
    </div>
    <div class="flex-wrap mt-2">
        <form name="form" class="flex-wrap" (ngSubmit)="onRequest()" #thisForm="ngForm" autocomplete="off" novalidate>
            <div class="flex-wrap p2">
                <div class="flex-wrap">
                    <span>Please provide your email and the new password</span>
                </div>
                <div class="flex-wrap mt-2">
                    <label style="color:red;">* Email</label>
                </div>
                <div class="flex-wrap mt-2">
                    <input name="username" type="text" [(ngModel)]="user.Username" required pInputText validateEmail />
                </div>
                <div class="flex-wrap mt-2">
                    <label style="color:red;">* Password</label>
                    <i class="fas fa-eye" (click)="showPassword = !showPassword"></i>
                </div>
                <div class="flex-wrap mt-2">
                    <input name="newPassword" type="{{(showPassword) ? 'text' : 'password'}}"
                        [(ngModel)]="user.NewPassword" required pInputText validatePassword />
                </div>
                <div class="flex-wrap mt-2">
                    <span>
                        The password length must be minimum of 8 characters including caps and symbols.
                    </span>
                </div>
                <div class="flex-wrap mt-2">
                    <span style="color:red;">* denotes required details.</span>
                </div>
            </div>
            <div class="flex-wrap">
                <div class="column2 mt-2 p2">
                    <button type="submit" class="button button-highlight button-block center" [disabled]="!isValid"
                        (click)="onRequest()">
                        Set Password
                    </button>
                </div>
                <div class="column2 mt-2 p2">
                    <button type="button" class="button button-highlight button-block center" (click)="onCancel()">
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>