import { BaseDto } from "../baseDto"
/* ContactBaseDto generated at 2024-05-26 13:54:05.222 +10:00*/
export class ContactBaseDto extends BaseDto {
	ContactId?: string;
	UserId?: string;
	SF_ContactId?: string;
	ARLM_UserId?: string;
	FirstName?: string;
	LastName?: string;
	Email?: string;
	CompanyPosition?: string;
	StatusCode?: string;
}
