<p-dialog header="ARL Program Workplan Filters" [appendTo]="'body'" [(visible)]="visible"
    [contentStyle]="{'max-height':'400px', 'overflow':'auto'}" [closable]="true" (onHide)="onCloseEvent($event)"
    [closeOnEscape]="true" [modal]="true">
    <div class="flex-wrap" *ngIf="showComponent" style="width:28em;overflow:auto;">
        <home-program-list-filter name="programListFilterCmpnt" #programListFilterCmpnt class="flex-wrap"
            [lazyLoadArgs]="lazyLoadArgs" [visible]="showComponent && visible"
            [materialList]="materialList" [regionList]="regionList" [statusList]="statusList"
            (onSearch)="onSearchEvent($event)" (onClose)="onCloseEvent($event)">
        </home-program-list-filter>
    </div>
</p-dialog>