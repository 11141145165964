<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;" [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
    <div id="pageTop" #pageTop class="flex-wrap device-layout-top">
        <form name="form" class="flex-wrap" (ngSubmit)="onRequest()" #thisForm="ngForm" autocomplete="off" novalidate>
            <div class="flex-wrap">
                <div class="text-center center" style="width: 200px;font-size:1.2em!important;">
                    <b>Welcome to ARL</b>
                </div>
                <p>Your account is activated with your email address.</p>
                <p>You could reset your account password using the "Forgotten Account" link on the sign in page.</p>
            </div>
            <div class="flex-wrap mt-2" *ngIf="showSetPassword">
                <div class="flex-wrap">
                    <span style="font-size:1.2em!important;">
                        Please set your account password
                    </span>
                </div>
                <div class="flex-wrap mt-2">
                    <div class="flex-wrap">
                        <label style="color:red;">* Password </label>
                        <i class="fas fa-eye" (click)="showPassword = !showPassword"></i>
                    </div>
                    <div class="flex-wrap mt-2">
                        <input name="newPassword" type="{{(showPassword) ? 'text' : 'password'}}"
                            [(ngModel)]="newPassword" required pInputText validatePassword />
                    </div>
                    <div class="flex-wrap mt-2">
                        <span>
                            The password length must be minimum of 8 characters including caps and symbols.
                        </span>
                    </div>
                    <div class="flex-wrap mt-2">
                        <span style="color:red;">* denotes required details.</span>
                    </div>
                </div>
                <div class="flex-wrap mt-2">
                    <button type="submit" class="button button-highlight button-block center" (click)="onRequest()"
                        [disabled]="!isValid()">
                        Confirm
                    </button>
                </div>
                <div class="flex-wrap mt-2">
                    <button type="button" class="button button-highlight button-block center"
                        (click)="onSkipPasswordChange()">
                        Skip password
                    </button>
                </div>
                <div class="flex-wrap mt-2">
                    <button type="button" class="button button-highlight button-block center" (click)="onCancel()">
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>