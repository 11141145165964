import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable, switchMap } from "rxjs";
import { BaseComponent } from "../../../../core/component";
import { DataResult } from "../../../../core/model/shared";
import { AppContextService } from "../../../../core/service";
import { HomeService } from "../../service/home.service";
import { MenuItem } from "primeng/api";
import { Constants, DomainValueDto } from "../../../../core/model/feature/apcomodel";

@Component({
  selector: 'business-arl-for-business-page',
  templateUrl: 'business-arl-for-business-page.component.html'
})
export class BisARLForBusinessPage extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    viewTitle: string = "";
    pagePath: string = "/arl-for-business";
    id: string = "";
    

  images = [
    '/images/logo_aldi.jpg',
    '/images/logo_coles.jpg',
    '/images/logo_cotton-on.jpg',
    '/images/logo_hbi_australia.jpg',
    '/images/logo_kimberlyclark.jpg',
    '/images/logo_kmart.jpg',
    '/images/logo_nestle.jpg',
    '/images/logo_woolworths.jpg',
    '/images/logo_arlec.jpg',
    '/images/logo_bioflex.jpg',
    '/images/logo_biopak.jpg',
    '/images/logo_ecostore.jpg',
    '/images/logo_ehi_australia.jpg',
    '/images/logo_ferrero.jpg',
    '/images/logo_goodman_fielder.jpg',
    '/images/logo_hallmark.jpg',
    '/images/logo_henkel.jpg',
    '/images/logo_isalbi.jpg',
    '/images/logo_legrand.jpg',
    '/images/logo_mayohardware.jpg',
    '/images/logo_mecca.jpg',
    '/images/logo_monde_nissin.jpg',
    '/images/logo_natures_organics.jpg',
    '/images/logo_r_m_williams.jpg',
    '/images/logo_reckitt.jpg',
    '/images/logo_verbatim.jpg',
    '/images/logo_vitaco.jpg',
    '/images/logo_vtech.jpg',
    '/images/logo_wd40.jpg'
   ];

  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 10,
      numScroll: 10
    },
    {
      breakpoint: '991px',
      numVisible: 5,
      numScroll: 5
    },
    {
      breakpoint: '767px',
      numVisible: 3,
      numScroll: 3
    }
  ];

 

    constructor(
        private route: ActivatedRoute,
        appCtx: AppContextService,
        public svc: HomeService) {
        super(appCtx);
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.appCtx.pagePath = this.pagePath;
            this.loadConfigData();
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    loadConfigData() {
        try {
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    setConfig() {
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            await this.getMenu();            
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;

            that.navigationSubscription = that.appCtx.onNavigationEnd().subscribe((e: any) => {
                if (that.showComponent && that.isCurrentPage(that.pagePath)) {
                    that.getMenu();
                    that.setMenu();
                }
            });
            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe(data => {
                    that.setViewMode();
                });
            }
            that.setMenu();
            that.setViewMode();
        }, 1000);
    }
    setMenu() {
        try {
            this.showMenuItems = false;
            let that = this;
            this.delaySetMenuTimer = setTimeout(function () {
                if (that.delaySetMenuTimer) {
                    clearTimeout(that.delaySetMenuTimer);
                }
                that.delaySetMenuTimer = undefined;
                that.browserMenu = that.browserMenuItems;
                that.mobileMenu = that.mobileMenuItems;
                that.showMenuItems = true;
            }, 100);
        } catch (ex) {
            console.error(ex);
        }
    }
  async getMenu() {
    try {
      this.browserMenuItems = [];
      this.mobileMenuItems = [];
      let menuList = this.getARLHomePageMenu();
      menuList.forEach(x => {
        let browserMenuItem: MenuItem = JSON.parse(JSON.stringify(x));
        let mobileMenuItem: MenuItem = JSON.parse(JSON.stringify(x));
        this.browserMenuItems.push(browserMenuItem);
        this.mobileMenuItems.push(mobileMenuItem);
      });
    } catch (ex) {
      console.error(ex);
    }
  }
    setViewMode() {

    }
    getId(): Observable<string> {
        return this.route.paramMap.pipe(switchMap((params: ParamMap) => {
            try {
                let id = params.getAll("id");
                if (id.length) {
                    return id;
                } else {
                    return [""];
                }
            } catch (ex) {
                console.log(ex);
                return [""];
            }
        }));
    }
   
}
