<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;">
    <div class="flex-wrap mt-2">
        <div class="flex-wrap">
            <label class="font-bold">Region</label>
        </div>
        <div class="flex-wrap">
            <div *ngFor="let rgn of regionList; index as h;" class="column50P mt-2 mb-2">
                <p-checkbox name="cbRegion{{h}}" [(ngModel)]="rgn.BooleanValue"
                    label="{{rgn.NameValue}}" [binary]="true">
                </p-checkbox>
            </div>
        </div>
    </div>
    <div class="flex-wrap mt-4">
        <div class="flex-wrap">
            <label class="font-bold">Status</label>
        </div>
        <div class="flex-wrap">
            <div *ngFor="let sts of statusList; index as h;" class="column33P mt-2 mb-2">
                <p-checkbox name="cbStatus{{h}}" [(ngModel)]="sts.BooleanValue" class="center"
                    label="{{sts.NameValue}}" [binary]="true">
                </p-checkbox>
            </div>
        </div>
    </div>
    <div class="flex-wrap mt-4" *ngIf="materialList && materialList.length">
        <div class="flex-wrap">
            <label class="font-bold">Material</label>
        </div>
        <div class="flex-wrap">
            <div class="flex-wrap mt-2 mb-2">
                <p-checkbox name="cbSelectAll" [(ngModel)]="isSelectAllMaterial" (click)="selectAllMaterials()"
                    label="Select all" [binary]="true">
                </p-checkbox>
            </div>
            <div *ngFor="let mat of materialList; index as h;" class="column50P mt-2 mb-2">
                <p-checkbox name="cbMaterial{{h}}" [(ngModel)]="mat.BooleanValue" (click)="toggleMaterials()"
                    label="{{mat.NameValue}}" [binary]="true">
                </p-checkbox>
            </div>
        </div>
    </div>
    <div class="flex-wrap mt-4">
        <div class="center flex-inline">
            <div class="w8 p2"><button class="flex-wrap brad3" (click)="onClickSearch()"><span
                        class="center">Search</span></button></div>
            <div class="w8 p2"><button class="flex-wrap brad3" (click)="onClickClear()"><span
                        class="center">Clear</span></button></div>
            <div class="w8 p2"><button class="flex-wrap brad3" (click)="onClickClose()"><span
                        class="center">Close</span></button></div>
        </div>
    </div>
</div>