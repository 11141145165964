import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "./../../../../core/component";
import { AppContextService } from "./../../../../core/service";
import { delay } from 'rxjs';
import { UserDto } from 'src/app/core/model/feature/apcomodel';
@Component({
    selector: "auth-reset-password-page",
    templateUrl: "./reset-password-page.component.html"
})
export class AuthResetPasswordPage extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    pagePath: string = "/resetpassword";
    delayRequestTimer: any = null;
    @ViewChild("pageTop") pageTop: HTMLDivElement | undefined;

    showResetPassword: boolean = false;
    verifierId: string = "";
    verifierValue: string = "";

    showForgotAccount: boolean = false;

    showPassword: boolean = false;
    delaySetPasswordTimer: any = null;
    user: UserDto = new UserDto();
    showForgotAccountDialog: boolean = false;


    constructor(appCtx: AppContextService) {
        super(appCtx);
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.appCtx.pagePath = this.pagePath;
            this.loadInitData();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.processRedirect();
            this.initPage();
        } catch (ex) {
            console.error(ex);
            
        }
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;
            that.setDivElement();
            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe(data => {
                    that.setDivElement();
                    that.setDivTimer();
                });
            }
        }, 100);
    }
    setDivTimer() {
        let that = this;
        this.delaySetDivTimer = setTimeout(function () {
            if (that.delaySetDivTimer) { clearTimeout(that.delaySetDivTimer); }
            that.delaySetDivTimer = undefined;
            that.setDivElement();
        }, 500);
    }
    setDivElement() {
        try {
            if (window.innerHeight && this.pageTop) {
                // let mt = 0;
                // if (window.innerHeight > this.pageTop["nativeElement"].clientHeight) {
                //     mt = (window.innerHeight - this.pageTop["nativeElement"].clientHeight) / 3;
                // }
                // this.pageTop["nativeElement"].style.marginTop = mt.toString() + "px";
            }
        } catch (ex) {
            console.error(ex);
            
        }
    }
    processRedirect() {
        if (this.getQueryParams("keyid") && this.getQueryParams("keyvalue")) {
            this.verifierId = this.getQueryParams("keyid");
            this.verifierValue = this.getQueryParams("keyvalue");
        }
        this.resetAll();
        this.showResetPassword = true;
    }
    resetAll() {
        this.showResetPassword = false;
        this.showForgotAccount = false;
    }
    onResetPasswordSuccess(success: any) { }
    onResetPasswordFailed(error: any) { }
    onResetPasswordClose(close: any) {
        this.resetAll();
        this.showResetPassword = true;
        //this.gotoHomePage();
    }
    onResetPasswordAgain(value: any) {
        this.resetAll();
        this.showForgotAccount = true;
    }
    onForgotAccountSuccess(success: any) {
    }
    onForgotAccountFailed(error: any) {
    }
    onForgotAccountClose(close: any) {
        this.resetAll();
        this.showResetPassword = true;
        //this.gotoHomePage();
    }
}