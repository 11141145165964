<div class="page-content-container">
    <!-- <div class="flex-wrap">
        <div class="center logo-image">
            <img src="assets/images/logo_280X84.png" alt="" class="center logo-image" />
        </div>
    </div> -->
    <div class="flex-wrap" *ngIf="showSignIn">
        <!-- <div class="flex-wrap">
            <div class="text-center center" style="width: 200px;font-size:1.2em!important;">
                <h1>Sign In</h1>
            </div>
        </div> -->
        <div class="flex-wrap mt-2">
            <auth-local-signin name="signInCmpnt" #signInCmpnt class="flex-wrap" [visible]="visible && showComponent"
                (onSuccess)="onLocalSignInSuccessEvent($event)" (onFailed)="onLocalSignInFailedEvent($event)"
                (onClose)="onLocalSignInCancelEvent()"></auth-local-signin>
        </div>
    </div>
    <!-- <div class="flex-wrap" *ngIf="showChangePassword">
        <div class="flex-wrap">
            <auth-change-password name="changePwdCmpnt" #changePwdCmpnt class="flex-wrap"
                [(visible)]="showChangePassword" (onSuccess)="onChangePasswordSuccessEvent($event)"
                (onFailed)="onChangePasswordFailedEvent($event)" (onClose)="onCloseChangePasswordEvent($event)"
                [(user)]="user"></auth-change-password>
        </div>
    </div>
    <div class="flex-wrap" *ngIf="showForgotAccount">
        <div class="flex-wrap">
            <auth-forgot-account name="forgotAccountCmpnt" #forgotAccountCmpnt class="flex-wrap"
                [visible]="showForgotAccount" (onClose)="onCloseForgotAccountEvent($event)">
            </auth-forgot-account>
        </div>
    </div> -->
</div>