<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>

  <div class="flex-wrap" style=" background-color: #0B605E !important; align-items: stretch;">
    <div class="leftheader">
      <div class="headercontent" style="min-height:10em">
        <h1 class="heading text">Australasian Recycling Label</h1>
        <h2 class="subtext">Providing you with clear instructions for confident recycling</h2>
      </div>
    </div>
    <div class="rightheader">
      <img src="images/hero_about.jpg" alt="Australasian Recycling Label shown on a jar.">
    </div>
  </div>


  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-7">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">About the Australasian Recycling Label (ARL) program</h2>
          </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              The Australasian Recycling Label (ARL) is a labelling system for Australia and New Zealand, that provides consumers with easy-to-understand recycling information on packaging. It removes confusion, saves time and reduces contamination in the recycling stream.  In Australia, the ARL program has been acknowledged in the National Waste Policy Action Plan and National Plastics Plan as a key action to improve consumer awareness and increase recycling rates. The New Zealand Ministry for Environment has also endorsed the ARL as the preferred labelling option in New Zealand.
              <br />
              <br />
              The ARL works by providing clear, detailed instructions on how to dispose of all parts of the packaging:
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="column2 p2 center">
            <iframe style="width: 100%; height: 100%; min-height:25em;max-height:25em;" src="https://www.youtube.com/embed/Yogp07HiHPE" frameborder="0" allowfullscreen></iframe>
          </div>
          <div class="column2 p2 center">
            <img class="flex-wrap" src="images/img_arl_description.png" alt="Visual description of recycling information for different packaging types.">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">What makes the ARL different to other recycling labels?</h2>
          </div>
          <div class="flex-wrap parentfont">
            <img class="flex-wrap h2section psection " src="images/AUSTRALASIAN RECYCLING LABEL (ARL) (2000 x 550 px).jpg" alt="Promotional banner showcasing how ARL works based on technical and council standard.">
          </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              Unlike other recycling labels, the ARL uses an evidence-based system underpinned by the Packaging Recyclability Evaluation Portal (PREP) to assess technical recyclability. PREP evaluates the packaging's shape, weight, size, inks, adhesives, materials and more, simulating behaviour in the Australian and New Zealand recycling system.
              <br />
              <br />
              In Australia, PREP considers the availability of collection services by assessing how many people can recycle a piece of packaging through their council kerbside collection. Kerbside access levels are determined using data from local councils by our partners, Planet Ark:
              <br />
              <br />
            </p>
            <br />
            <div class="flex-wrap parentfont">
              <ul class="fontsize">
                <li class="mt-1">
                  Widely Accepted: If over 80% of the population with kerbside recycling can recycle the item.
                </li>
                <li class="mt-1">
                  Less Widely Accepted: If 60-80% of the population has access, consumers are encouraged to make informed disposal decisions based on local collection availability.
                </li>
                <li class="mt-1">
                  Not-Recyclable: If less than 60% of the population with kerbside recycling can recycle the item.
                </li>
              </ul>
            </div>
              <p class="fontsize">
                In New Zealand, PREP follows the Ministry for the Environment's
                <a class="fc-black" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="https://environment.govt.nz/assets/publications/Waste/Standard-materials-for-kerbside-collections-Guidance-for-territorial-authorities.pdf">
                  Standard materials for kerbside collections: guidance for territorial authorities
                </a> guidelines.
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">Our partnership</h2>
          </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              In 2018, APCO assumed responsibility for the administration of the ARL, including holding exclusive licence of the label. Since 2022, APCO has owned and operated the label with the support of Planet Ark and PREP Design. In 2023, APCO partnered with Adaptation, who deliver the Recycle Mate app featured on this website, which provides time- and location-based options for recycling via kerbside, or alternative options, such as drop-off points.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-4 mb-8">
      <div class="body-container p2">
        <div class="flex-wrap parentfont p2" style="align-items:center;align-content:center">
          <div class="column5ARL h2section psection">
            <a target="_blank" rel="noopener noreferrer" href="https://apco.org.au/">
              <img class="partnerimg" src="images/img_partner_logos_APCO.png" alt="Logo of Australasian Packaging Covenant Organisation.">
            </a>
          </div>
          <div class="column5ARL h2section psection">
            <a target="_blank" rel="noopener noreferrer" href="https://planetark.org/">
              <img class="partnerimg" src="images/img_partner_logos_planetark.png" alt="Logo of Planet Ark.">
            </a>
          </div>
          <div class="column5ARL h2section psection">
            <a target="_blank" rel="noopener noreferrer" href="https://prep.design/">
              <img class="partnerimg" src="images/img_partner_logos_prep.png" alt="Logo of Packaging Recyclability Evaluation Portal.">
            </a>
          </div>
          <div class="column5ARL h2section psection">
            <a target="_blank" rel="noopener noreferrer" href="https://recyclemate.com.au/">
              <img class="partnerimg" src="images/img_partner_logos_recyclemate.png" alt="Logo of Recycle Mate.">
            </a>
          </div>
          <div class="column5ARL h2section psection">
            <a target="_blank" rel="noopener noreferrer" href="https://www.adaptation.net.au/projects-old-layout/recyclemate">
              <img class="partnerimg" src="images/img_partner_logos_adaptation.png" alt="Logo of Adaptation Environmental Support.">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-lime">
    <div class="flex-wrap mt-8 mb-8">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="p2 m-1 p4 bgc-white column49PARL" style="border-radius: 5px;">
            <div class="fc-dark-turquoise ml-4">
              <h2 class="contentheader">Looking to recycle locally?</h2>
            </div>
            <div class="mt-2 ml-4">
              <a style="color: #0B6261;text-decoration: underline;" class="fontsize" target="_blank" rel="noopener noreferrer" href="/">Go to your recycling guide ></a>
            </div>
          </div>
          <div class="p2 m-1 p4 bgc-white column49PARL" style="border-radius:5px;">
            <div class="fc-dark-turquoise ml-4">
              <h2 class="contentheader">Have feedback for ARL?</h2>
            </div>
            <div class="mt-2 ml-4">
              <a style="color: #0B6261;text-decoration: underline;" class="fontsize" target="_blank" rel="noopener noreferrer" href="https://apco.org.au/packaging-complaint?Acc=&Con=&Slug=ARLComplaints">Send your questions and feedback ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap">
    <arl-footer [pagePath]="pagePath" class="flex-wrap"></arl-footer>
  </div>
 </div>
