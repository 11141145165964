import { BaseDto } from "../baseDto"
/* ProductComponentGroupBaseDto generated at 2024-05-26 13:54:05.296 +10:00*/
export class ProductComponentGroupBaseDto extends BaseDto {
	ProductComponentGroupId?: string;
	ProdCompGroupName?: string;
	ProdCompGroupDescription?: string;
	ProductId?: string;
	ProjectId?: string;
	ProjectItemGroupId?: string;
	ProjectItemId?: string;
	Org_ProdCompGroupId?: string;
	Org_ProductId?: string;
	Org_ProjectId?: string;
	Org_ProjectItemGroupId?: string;
	Org_ProjectItemId?: string;
	StatusCode?: string;
}
