import { BaseDto } from "../baseDto"
/* SequenceNoBaseDto generated at 2024-05-26 13:54:05.345 +10:00*/
export class SequenceNoBaseDto extends BaseDto {
	SequenceNoId?: string;
	SequenceType?: string;
	ReferenceName?: string;
	LastSequenceNo?: number;
	Value?: string;
	StatusCode?: string;
}
