<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
    [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
    <div class="sticky-top">
        <div class="flex-wrap bg-white">
            <div class="header-container container flex-inline">
                <div>
                    <a class="navbar-brand py-0" height="100%" href="https://arl.org.au"><img class="brand-logo"
                            src="images/ARL for consumers logo.png"
                            alt="Australasian Recycling Label for consumers logo"></a>
                </div>
                <div *ngIf="showMenuItems" class="flex-fill middle">
                    <div class="right" [ngClass]="{'hidden':IsMobileView}">
                        <p-menubar [model]="browserMenu"></p-menubar>
                    </div>
                    <div class="right" [ngClass]="{'hidden':!IsMobileView}">
                        <p-menu #menu [model]="mobileMenu" [popup]="true"></p-menu>
                        <button pButton type="button" (click)="menu.toggle($event)" icon="pi pi-bars" label=""></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-wrap bgc-lime">
        <div class="container p2 mt-4 mb-4">
            <div class="flex-wrap">
                <div class="column2 p2">
                    <div class="fc-dark-cyan">
                        <h1>ARL program governance</h1>
                    </div>
                    <div class="mt-2">
                        <p>
                            APCO is committed to delivering a robust and transparent ARL program which meets the
                            needs of all stakeholders across the recycling ecosystem and aligns with international
                            best practice.
                        </p>
                        <p class="mt-4">
                            The key principles of the ARL program are to be:
                        </p>
                        <ul class="mt-4">
                            <li>Consistent</li>
                            <li>Continuously improving</li>
                            <li>Evidence-based</li>
                            <li>Representative</li>
                            <li>Transparent</li>
                        </ul>
                        <p>
                            The team are developing a Program Manual which will detail all the key processes for the ARL – check back for an update soon!
                            <!-- <a href="">View the ARL Program Manual here</a> -->
                        </p>
                    </div>
                </div>
                <div class="column2 p2" style="max-width:500px;">
                    <img class="flex-wrap" src="images/ARL-Priority-Area-Image.png" alt="ARL Program Governance">
                </div>
            </div>
        </div>
    </div>
    <div class="flex-wrap bgc-white">
        <div class="container mt-4 mb-4">
            <div class="flex-wrap">
                <div class="flex-wrap">
                    <div class="fc-dark-cyan">
                        <h1>Get involved early</h1>
                    </div>
                </div>
                <div class="flex-wrap mt-4">
                    <p>
                        The recycling context for packaging materials is always evolving. To reflect this, the ARL team
                        is continuously reviewing and recalibrating the thresholds for priority materials and formats.
                        Currently, we are seeking insights and evidence regarding the forward program workplan items
                        below.
                    </p>
                    <p class="mt-4">
                        If you would like to provide feedback, or suggest a material or format for consideration in the
                        forward workplan, please reach out to the team via <a target="_blank"
                            href="mailto:arl@apco.org.au?subject=Contact from arl.org.au website">arl&#64;apco.org.au</a>.
                    </p>
                </div>
            </div>
            <div class="flex-wrap mt-4 p2">
                <div class="flex-wrap flex-inline">
                    <div class="">
                        <h2>ARL program workplan</h2>
                    </div>
                    <div class="right text-underline font-bold fc-blue" (click)="showProgramFilter()">Filter
                    </div>
                </div>
                <div class="flex-wrap mt-4">
                    <home-program-list #programListComponent class="flex-wrap" style="height: calc(100%);"
                        [name]="'programList'" [(isFilterVisible)]="isProgramFilterVisible" [isFromAdmin]="false">
                    </home-program-list>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-wrap bgc-gray">
        <div class="container p2 mt-4 mb-4">
            <div class="flex-wrap">
                <div class="flex-wrap">
                    <div class="fc-dark-cyan">
                        <h1>Have your say</h1>
                    </div>
                </div>
                <div class="flex-wrap">
                    <div class="flex-wrap mt-4">
                        <p>
                            Before finalising any significant proposed changes, the ARL will always offer a right of
                            response via public consultation. This is your opportunity to provide feedback on
                            submissions and the final draft of proposed thresholds. To provide feedback on any of the items currently open for comment, contact the ARL team via <a target="_blank"
                            href="mailto:arl@apco.org.au?subject=Feedback on ARL submissions">arl&#64;apco.org.au</a> 
                        </p>
                    </div>
                    <div class="flex-wrap mt-4">
                        <div class="flex-wrap">
                            <h2>ARL program threshold changes</h2>
                        </div>
                        <div class="flex-wrap">
                            <p>
                                APCO review the technical recyclability thresholds of the program either on a two-yearly
                                basis, or as a result of known changes to the system impacting recyclability.
                            </p>
                        </div>
                    </div>
                    <div class="flex-wrap mt-4">
                        <div class="flex-wrap">
                            <h2>
                                ARL program submissions
                            </h2>
                        </div>
                        <div class="flex-wrap">
                            <p>
                                Factors such as changing kerbside access levels and emerging technologies and
                                infrastructure influence the recyclability of packaging formats and materials in
                                Australia and New Zealand.
                            </p>
                            <p class="mt-2">
                                To manage these changes, the ARL program has a clear process available to all
                                stakeholders. Organisations are welcome to make submissions with supporting evidence to
                                APCO regarding recyclability, consumer behaviour and alternative destinations. These
                                submissions are subject to public comment.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex-wrap mt-4">
                    <h2>
                        Public consultation on program updates
                    </h2>
                </div>
                <div class="flex-wrap mt-4">
                    <home-submission-list #submissionListComponent class="flex-wrap" style="height: calc(100%);"
                        [name]="'submissionList'" [isFromAdmin]="false">
                    </home-submission-list>
                </div>
                <div class="flex-wrap mt-4 font-bold">
                    <p>*Note, Brand owner names will not be published until the submission review is complete.</p>
                </div>
            </div>
        </div>
        <div class="flex-wrap bgc-white">
            <div class="flex-wrap mt-4 mb-4">
                <div class="container p2">
                    <div class="flex-wrap mt-4 mb-4">
                        <div class="flex-wrap">
                            <div class="fc-dark-cyan">
                                <h1>ARL committees</h1>
                            </div>
                        </div>
                        <div class="flex-wrap mb-2">
                            <div class="flex-wrap brad2 b1 p5" style="border-color:#E5E5E5;">
                                <div class="flex-wrap">
                                    <div class="flex-fill mt-3 font-bold" (click)="isIRCVisible=!isIRCVisible">
                                        Internal Review Committee (IRC)
                                    </div>
                                    <div class="w2 mt-3">
                                        <div (click)="isIRCVisible=!isIRCVisible" class="flex-wrap">&nbsp;<i
                                                class="fa-solid"
                                                [ngClass]="{'fa-chevron-up':isIRCVisible,'fa-chevron-down':!isIRCVisible}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isIRCVisible}">
                                    <p class="mb-3">
                                        This committee has final decision-making authority over the ARL. It reviews all
                                        recommendations from the management team and other governance and advisory
                                        committees. It is responsible for making decisions about the ARL program that
                                        are aligned to program principles and informed by accurate and well-evidenced
                                        information.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-wrap mb-2">
                            <div class="flex-wrap brad2 b1 p5" style="border-color:#E5E5E5;">
                                <div class="flex-wrap">
                                    <div class="flex-fill mt-3 font-bold" (click)="isMSCVisible=!isMSCVisible">
                                        Material Stewardship Committees (MSCs)
                                    </div>
                                    <div class="w2 mt-3">
                                        <div (click)="isMSCVisible=!isMSCVisible" class="flex-wrap">&nbsp;<i
                                                class="fa-solid"
                                                [ngClass]="{'fa-chevron-up':isMSCVisible,'fa-chevron-down':!isMSCVisible}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isMSCVisible}">
                                    <p class="mb-3">
                                        The ARL has six <a class="fc-blue"
                                            href="https://apco.org.au/apco-material-stewardship-committees">Material
                                            Stewardship Committees</a> (flexible plastic, rigid plastic, fibre, metal,
                                        glass and labels) providing technical advice on packaging chemistry, composition
                                        and recovery. These specialised sub-committees include representation from
                                        across the packaging value chain. They review stakeholder feedback and provide
                                        recommendations to the ARL program on submissions and proposed thresholds. Their
                                        advice is considered by the IRC in decision making.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-wrap mb-2">
                            <div class="flex-wrap brad2 b1 p5" style="border-color:#E5E5E5;">
                                <div class="flex-wrap">
                                    <div class="flex-fill mt-3 font-bold" (click)="isNZASCVisible=!isNZASCVisible">
                                        New Zealand ARL Steering Committee (NZASC)
                                    </div>
                                    <div class="w2 mt-3">
                                        <div (click)="isNZASCVisible=!isNZASCVisible" class="flex-wrap">&nbsp;<i
                                                class="fa-solid"
                                                [ngClass]="{'fa-chevron-up':isNZASCVisible,'fa-chevron-down':!isNZASCVisible}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isNZASCVisible}">
                                    <p class="mb-3">
                                        The New Zealand ASC provides recommendations to the IRC, using their expertise
                                        in technical matters, the market, and consumer behaviours that are specific to
                                        New Zealand.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-wrap mb-2">
                            <div class="flex-wrap brad2 b1 p5" style="border-color:#E5E5E5;">
                                <div class="flex-wrap">
                                    <div class="flex-fill mt-3 font-bold" (click)="isIRPVisible=!isIRPVisible">
                                        Independent Review Panel (IRP)
                                    </div>
                                    <div class="w2 mt-3">
                                        <div (click)="isIRPVisible=!isIRPVisible" class="flex-wrap">&nbsp;<i
                                                class="fa-solid"
                                                [ngClass]="{'fa-chevron-up':isIRPVisible,'fa-chevron-down':!isIRPVisible}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isIRPVisible}">
                                    <p class="mb-3">
                                        After final decision by IRC, if a conflict arises, an Independent Review Panel
                                        (IRP) can be called upon. The IRP is managed by an external consultancy to
                                        ensure that a fair and transparent process has been undertaken to determine the
                                        IRC ruling.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-wrap bgc-lightblue">
        <div class="container p2 mt-4 mb-4">
            <div class="flex-wrap">
                <div class="center">
                    <h1>Have any feedback?</h1>
                </div>
            </div>
            <div class="flex-wrap">
                <div class="center">
                    <p style="text-align:center;">If you have a question on the program or about a packaging item carrying the ARL.</p>
                </div>
            </div>
            <div class="flex-wrap">
                <div class="center mt-4">
                    <div class="brad4 bgc-yellow big-btn w10">
                        <div class="r-centroid w-fit-content font-bold"><a class="fc-black"
                                href="https://apco.org.au/packaging-complaint?Acc=&Con=&Slug=ARLComplaints">Submit
                                feedback</a></div>
                    </div>
                    <!-- <div class="brad4 bgc-yellow big-btn w10"
                        onclick='window.open("mailto:arl@apco.org.au?subject=Feedback from arl.org.au website");return false;'>
                        <div class="r-centroid w-fit-content font-bold">Submit feedback</div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Footer-->
    <div class="flex-wrap bgc-darkblue">
        <div class="container p2 mt-4 mb-4">
            <div class="flex-wrap fc-white font-bold">
                <div class="left">
                    <div class="fc-white font-bold">&copy; {{CurrentYear}} ARL</div>
                </div>
                <div class="flex-fill">
                    <div class="right flex-inline">
                        <div class="ml-4 w5">
                            <a href="https://apco.org.au/faqs?category=Australasian+Recycling+Label+Program"
                                target="_blank" class="fc-white font-bold">FAQ</a>
                        </div>
                        <div class="ml-4 w5">
                            <a href="https://documents.packagingcovenant.org.au/other-public-documents/APCO%20Privacy%20Policy"
                                target="_blank" class="fc-white font-bold">Privacy</a>
                        </div>
                        <div class="ml-4 w8">
                            <a target="_blank" class="fc-white font-bold"
                                href="mailto:arl@apco.org.au?subject=Contact from arl.org.au website">arl&#64;apco.org.au</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>