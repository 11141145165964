import { Observable, of, map, switchMap } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ApiService, AppContextService } from "../../../core/service";
import { ActionResult } from "src/app/core/model/shared";
import { UserDto } from "src/app/core/model/feature/apcomodel";
import { HttpParams } from "@angular/common/http";
//import { HttpParams } from "@angular/common/http";
//import { ActionResult, Constants, DataResult, AuthKeyDto, UserDto, UserAccessDto, AppAccess } from './../../../core/model';

@Injectable({
    providedIn: "root"
})
export class AuthenticationService {
    private apiSvc: ApiService;
    constructor(private appCtx: AppContextService) {
        this.apiSvc = this.appCtx.apiSvc;
    }
    checkAuthenticated(componentName: string = ""): Observable<boolean> {
        return new Observable(observer => {
            if (this.appCtx.domSvc.Expires && this.appCtx.utilSvc.dateSecondsDiff(new Date().getUTCDate(),this.appCtx.domSvc.Expires) > 60 && this.appCtx.domSvc.Authorisation) {
                observer.next(true);
            } else {
                this.apiSvc.checkAuthenticated(componentName).subscribe(resp => {
                    if (resp) {
                        observer.next(true);
                    } else {
                        observer.next(false);
                    }
                });
            }            
        });
    }
    confirmUserEmail(user: UserDto): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            // if (user && user.UserId && user.ConfirmationKey && this.appCtx.platformContext.DeviceInfo) {
            //     this.apiSvc.getSession().subscribe((keyResult: AuthKeyDto) => {
            //         let tUser: UserDto = new UserDto({
            //             UserId: user.UserId,
            //             ConfirmationKey: user.ConfirmationKey
            //         });
            //         if (user.NewPassword) {
            //             let password: string = this.appCtx.utilSvc.AESEncrypt(user.NewPassword, keyResult.KeyValue, this.appCtx.platformContext.DeviceInfo.UUId);
            //             tUser.NewPassword = password;
            //         }
            //         let data: any = {
            //             "ConfirmUser": tUser,
            //             "KeyId": keyResult.AuthKeyId
            //         };
            //         let httpParams: HttpParams = new HttpParams();
            //         this.apiSvc.postAuthorizeService("/ConfirmUserAccount", data)
            //             .subscribe(resp => {
            //                 resp = this.appCtx.utilSvc.parseSubJsonFields(resp) as ActionResult;
            //                 if (resp.IsSuccess) {
            //                     resp.IsSuccess = true;
            //                 } else {
            //                     resp.IsSuccess = false;
            //                 }
            //                 observer.next(resp.IsSuccess);
            //             }, err => {
            //                 observer.error(err);
            //             });
            //     }, err => {
            //         observer.error(err);
            //     });
            // } else {
            //     observer.error("Change password error.");
            // }
        });
    }
    forgotAccount(username: string = ""): Observable<ActionResult> {
        let that = this;
        return new Observable(observer => {
            let httpParams: HttpParams = new HttpParams()
                .append("username", username);
            this.apiSvc.getAuthorizeService("/ForgotAccount", httpParams).subscribe(resp => {
                resp = this.appCtx.utilSvc.parseSubJsonFields(resp) as ActionResult;
                if (resp.IsSuccess) {
                    resp.IsSuccess = true;
                } else {
                    resp.IsSuccess = false;
                }
                observer.next(resp);
            }, error => {
                observer.error(error);
            });
        });
    }
    resetPassword(user: UserDto, verifierId: string, verifierValue: string): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            // if (user && user.Username && user.NewPassword && verifierId && verifierValue && this.appCtx.platformContext.DeviceInfo) {
            //     this.apiSvc.getSession().subscribe((keyResult: AuthKeyDto) => {
            //         let tUser: UserDto = new UserDto();
            //         tUser.Username = this.appCtx.utilSvc.AESEncrypt(user.Username, keyResult.KeyValue, this.appCtx.platformContext.DeviceInfo.ClientId);
            //         tUser.UserPassword = this.appCtx.utilSvc.AESEncrypt(verifierValue, keyResult.KeyValue, this.appCtx.platformContext.DeviceInfo.UUId);
            //         tUser.NewPassword = this.appCtx.utilSvc.AESEncrypt(user.NewPassword, keyResult.KeyValue, this.appCtx.platformContext.DeviceInfo.UUId);
            //         let data: any = {
            //             "ResetUser": tUser,
            //             "VerifierId": verifierId,
            //             "KeyId": keyResult.AuthKeyId
            //         };
            //         this.apiSvc.postAuthorizeService("/ResetPassword", data).subscribe(resp => {
            //             resp = this.appCtx.utilSvc.parseSubJsonFields(resp) as ActionResult;
            //             if (resp.IsSuccess) {
            //                 resp.IsSuccess = true;
            //                 this.checkAuthenticated("ResetPassword").subscribe(authUser => {
            //                     if (authUser) {
            //                         observer.next(resp.IsSuccess);
            //                     } else {
            //                         let sUser: UserDto = new UserDto();
            //                         sUser.Username = user.Username;
            //                         sUser.UserPassword = user.NewPassword;
            //                         this.apiSvc.signIn(sUser).subscribe(authenticatedUser => {
            //                             if (authenticatedUser) {
            //                                 this.appCtx.CurrentBAUserId = authenticatedUser.UserId;
            //                             }
            //                             observer.next(resp.IsSuccess);
            //                         }, signInError => {
            //                             observer.next(resp.IsSuccess);
            //                         });
            //                     }
            //                 });
            //             } else {
            //                 resp.IsSuccess = false;
            //                 observer.next(resp.IsSuccess);
            //             }
            //         }, err => {
            //             observer.error(err);
            //         });
            //     }, err => {
            //         observer.error(err);
            //     });
            // } else {
            //     observer.error("Change password error.");
            // }
        });
    }
    changePassword(user: UserDto): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            // if (user && user.NewPassword && this.appCtx.platformContext.DeviceInfo) {
            //     this.apiSvc.getSession().subscribe((keyResult: AuthKeyDto) => {
            //         let tUser: UserDto = new UserDto();
            //         if (user.UserPassword) {
            //             tUser.UserPassword = this.appCtx.utilSvc.AESEncrypt(user.UserPassword, keyResult.KeyValue, this.appCtx.platformContext.DeviceInfo.UUId);
            //         }
            //         tUser.NewPassword = this.appCtx.utilSvc.AESEncrypt(user.NewPassword, keyResult.KeyValue, this.appCtx.platformContext.DeviceInfo.UUId);
            //         let data: any = {
            //             "ChangeUser": tUser,
            //             "KeyId": keyResult.AuthKeyId
            //         };
            //         this.apiSvc.postAuthorizeService("/ChangePassword", data).subscribe(resp => {
            //             resp = this.appCtx.utilSvc.parseSubJsonFields(resp) as ActionResult;
            //             if (resp.IsSuccess) {
            //                 resp.IsSuccess = true;
            //             } else {
            //                 resp.IsSuccess = false;
            //             }
            //             observer.next(resp.IsSuccess);
            //         }, err => {
            //             observer.error(err);
            //         });
            //     }, err => {
            //         observer.error(err);
            //     });
            // } else {
            //     observer.error("Change password error.");
            // }
        });
    }
    redeemANZPACAuthorization(id: string,code:string): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            this.apiSvc.redeemAuthorization(id,code).subscribe(data => {
                this.apiSvc.refreshToken(this.appCtx.domSvc.RefreshToken).subscribe(response=>{                    
                observer.next(this.appCtx.Authenticated);
                },respErr=>{observer.error(respErr);})
            }, err => {observer.error(err);});
        });
    }
}
