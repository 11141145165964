import { BaseDto } from "../baseDto"
/* UserRoleBaseDto generated at 2024-05-26 13:54:05.417 +10:00*/
export class UserRoleBaseDto extends BaseDto {
	UserRoleId?: string;
	UserId?: string;
	OrganisationId?: string;
	RoleTypeCode?: string;
	RoleCode?: string;
	StatusCode?: string;
}
