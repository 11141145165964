import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
@Component({
    selector: "auth-forgot-account-dialog",
    templateUrl: "./forgot-account-dialog.component.html"
})
export class AuthForgotAccountDialog extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    @Input() visible: boolean = false;
    @Output() onSuccess = new EventEmitter<any>();
    @Output() onFailed = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();
    
    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
            // this.navigationSubscription = this.appContext.onNavigationEnd().subscribe((e: any) => {
            //     if (this.showComponent) {
            //         this.loadInitData();
            //     }
            // });
        } catch (ex) {
            
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
            
        }
    }
    initPage() {
        try {
            this.showComponent = true;
        } catch (ex) {
            
            console.error(ex);
        }
    }
    onDialogSuccess(success: any) {
        this.onSuccess.emit(success);        
    }
    onDialogFailed(error: any){
        this.onFailed.emit(error);
    }
    onDialogClose(close: any){
        this.onClose.emit(close);
    }
}