<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-lime">
    <div class="body-container p2 mt-7">
      <!--   Intro text   -->
      <div class="flex-wrap pb-2">
        <div class="flex-wrap fc-dark-turquoise parentfont">
          <h2 class="contentheader h2section">Find out how to recycle near you</h2>
        </div>
        <div class="flex-wrap parentfont">
          <ul class="fontsize psection" >
            <li class="mt-1">
              Discover local kerbside collection or nearby away from home recycling options across Australia
            </li>
            <li class="mt-1">
              5,000+ items available and continuously updated
            </li>
          </ul>
        </div>
        <div class="flex-wrap parentfont">
          <p class="fontsize" >
            Please note that the majority of soft plastic searches will currently recommend landfill for your packaging. Our recycling guide will show latest information as new soft plastics services come online.
          </p>
        </div>
      </div>

      <!--    Widget    -->
      <div class="flex-wrap justify-content-center">
        <div class="rm-widget-container iframeborder">
          <iframe *ngIf="!isProd" src="https://app-uat.recyclemate.com.au/sitelet/2" title="Recycle Mate" width="100%"
                  height="700px" style="border:none;" allowfullscreen="true">
          </iframe>
          <iframe *ngIf="isProd" src="https://sitelet.recyclemate.com.au/sitelet/2" title="Recycle Mate" width="100%"
                  height="700px" style="border:none;" allowfullscreen="true">
          </iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="body-container p2 mt-8 mb-8">
      <div class="flex-wrap">
        <div class="center fc-dark-turquoise parentfont">
          <h2 class="contentheader h2section">Right label, right bin</h2>
        </div>
      </div>

      <div class="flex-wrap parentfont">
        <div class="center psection">
          <div class="center column4"><img class="rm-arl-icon" src="images/ARL_label_array.jpg" alt="Multiple Australasian Recycling Labels showing different recycling symbols."></div>
        </div>
      </div>
    </div>
  </div>


  <div class="flex-wrap bgc-white">
    <div class="body-container mt-4 mb-4">
      <div class="accordion accordion-flush py-3" id="accordionParent">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingARL">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseARL" aria-expanded="false" aria-controls="collapseARL">
              <div class="flex-wrap fc-dark-turquoise">
                <h2 class="contentheader">How the Australasian Recycling Label (ARL) helps you recycle better</h2>
              </div>
            </button>
          </h2>
          <div id="collapseARL" class="accordion-collapse collapse" aria-labelledby="headingARL"
               data-bs-parent="#accordionParent">
            <div class="accordion-body fontsize">
              Unlike other labels, the ARL is an evidence-based system underpinned by the Packaging Recyclability Evaluation Portal (PREP). Generally, recycling best practice is to empty, clean and dry your packaging before popping it in the recycling bin. The ARL provides specific instructions for each item of packaging when you are standing at the bin.
              <a class="fc-black fontsize" style="text-decoration:underline ;"
                 href="/about">
                Learn more about ARL >
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


  <div class="flex-wrap bgc-white">
    <div class="body-container mt-4 mb-4">
      <div class="accordion accordion-flush py-3" id="accordionaARLLogo">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingARLLogo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseARLLogo" aria-expanded="false" aria-controls="collapseARLLogo">
              <div class="flex-wrap fc-dark-turquoise">
                <h2 class="contentheader">What do the ARL logos mean?</h2>
              </div>
            </button>
          </h2>
          <div id="collapseARLLogo" class="accordion-collapse collapse" aria-labelledby="headingARLLogo"
               data-bs-parent="#accordionaARLLogo">
            <div class="accordion-body">
              <div class="flex-wrap">
                <div class="column2 flex-inline p2">
                  <div class="w6">
                    <img class="rm-arl-icon" src="images/Recyclable_Box.jpg" alt="Australasian Recycling Label">
                  </div>
                  <div class="flex-fill">
                    <p class="middle pl-2 fontsize">
                      <strong>Recyclable: </strong> Please dispose in your recycling bin. This is recyclable.
                    </p>
                  </div>
                </div>
                <div class="column2 flex-inline p2">
                  <div class="w6">
                    <img class="rm-arl-icon" src="images/Not_Recyclable_Cap.jpg" alt="Australasian Recycling Label">
                  </div>
                  <div class="flex-fill">
                    <p class="middle pl-2  fontsize">
                      <strong>Not Recyclable: </strong>Please dispose in your general waste bin. This is not recyclable.
                    </p>
                  </div>
                </div>

              </div>
              <div class="flex-wrap">
                <div class="column2 flex-inline p2">
                  <div class="w6">
                    <img class="rm-arl-icon" src="images/Conditionally_Recyclable_Foil_Instructions.jpg"
                         alt="Australasian Recycling Label">
                  </div>
                  <div class="flex-fill">
                    <p class="middle pl-2  fontsize">
                      <strong>Conditionally Recyclable: </strong>Follow the instructions below the symbol and place this item in your recycling bin. If you are unable to follow the instructions, please dispose in your general waste bin.
                    </p>
                  </div>
                </div>
                <div class="column2 flex-inline p2">
                  <div class="w6">
                    <img class="rm-arl-icon" src="images/CL_Bag.jpg" alt="Australasian Recycling Label">
                  </div>
                  <div class="flex-fill">
                    <p class="middle pl-2  fontsize">
                      <strong>Check Locally: </strong>This item may be recyclable. Use the interactive local recycling tool on arl.org.au to check for local recyclability options. If no options are available locally, you will be prompted to place the item in the general waste bin.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


  <div class="flex-wrap bgc-white">
    <div class="body-container mt-4 mb-4">
      <div class="accordion accordion-flush py-3" id="accordionARLNZ">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingARL">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseARLNZ" aria-expanded="false" aria-controls="collapseARLNZ">
              <div class="flex-wrap fc-dark-turquoise">
                <h2 class="contentheader">The ARL in New Zealand</h2>
              </div>
            </button>
          </h2>
          <div id="collapseARLNZ" class="accordion-collapse collapse" aria-labelledby="headingARL"
               data-bs-parent="#accordionARLNZ">
            <div class="accordion-body">
              <p class="fontsize">
                The ARL is applicable for both the Australian and New Zealand markets. As of February 1, 2024 the kerbside recyclability of packaging in New Zealand will be as per the
                <a class="fc-black" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="https://environment.govt.nz/assets/publications/Waste/Standard-materials-for-kerbside-collections-Guidance-for-territorial-authorities.pdf">
                  Standard materials for kerbside collections: guidance for territorial authorities
                </a>
                , released by the NZ Ministry for the Environment. The ARL has been progressively reflecting these collection differences between AUS and NZ, in PREP from February 1, 2024.
                <br />
                <br />
                The recycling options for soft plastics are different between Australia and New Zealand. In New Zealand, the Soft Plastics Recycling Scheme is available for partners to message for their eligible soft plastic packaging to be returned or dropped off to participating stores to be recycled. Find more information on the NZSPRS
                <a class="fc-black" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="https://www.recycling.kiwi.nz/">
                  here
                </a>.
                <br />
                <br />
                The recycling options for food and beverage cartons are also different between Australia and New Zealand, with the Food & Beverage Cartons Recycling Scheme available for partners to message for their eligible cartons and caps to be dropped off the designated locations. Find more information on the FBCRS
                <a class="fc-black" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="https://fbcarton.recycling.kiwi.nz/">
                  here
                </a>.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>



  <!-- Footer-->
  <div class="flex-wrap">
    <gov-footer [pagePath]="pagePath" class="flex-wrap"></gov-footer>
  </div>
</div>
<p-dialog header="Disclaimer" [(visible)]="showDisclaimer" [style]="{width: '80vw'}" [closable]="true" (onHide)="onCloseDisclaimer()">
  <p>
    <strong>Please note:</strong> Recycle Mate was developed and is maintained by third parties acting
    independently of the Australian Packaging Covenant Organisation (APCO). APCO is not responsible for
    and,
    to the maximum extent permitted by law, does not represent, warrant or guarantee the results
    produced by
    the use of Recycle Mate, including the currency, the accuracy, completeness or suitability for your
    intended use. Recycle Mate is provided to you on an “as is” and “as available” basis.
  </p><br>
  <p>
    You should review Recycle Mate’s Privacy Policy and Terms and Conditions, available at
    www.recyclemate.com.au, carefully and immediately cease using Recycle Mate if you do not agree to
    its
    Terms and Conditions.
  </p><br>
  <p>
    By using Recycle Mate, you acknowledge and agree that Recycle Mate (including the results produced by
    your use of Recycle Mate) cannot be used for any on-pack or external claims, including but not
    limited
    to recyclability claims or recovery claims. Recycle Mate does not replace the on-pack messaging
    requirement under the rules of the Australasian Recycling Label (ARL) and is designed to give
    additional
    local guidance to consumers where possible.
  </p><br>
  <p>
    APCO accepts no responsibility for errors or omissions in the results produced by the use of Recycle
    Mate, or (to the maximum extent permitted by law) liability for any loss or damage arising from the
    use
    of or reliance on Recycle Mate or results generated through the use of Recycle Mate. Recycle Mate is
    subject to revision and withdrawal from public use without notice. Users should seek appropriate
    advice
    when applying the information to their specific needs.
  </p><br>
</p-dialog>
