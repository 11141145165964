import { BaseDto } from "../baseDto"
/* ProductAttributeBaseDto generated at 2024-05-26 13:54:05.291 +10:00*/
export class ProductAttributeBaseDto extends BaseDto {
	ProductAttributeId?: string;
	ProductId?: string;
	ProductComponentId?: string;
	ProductComponentGroupId?: string;
	ObjectAttributeVersionId?: string;
	AttributeJSON?: string;
	StatusCode?: string;
}
