import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Observable, combineLatest, map } from "rxjs";
import { AppContextService } from "../../../../core/service";
import { DataResult, ColumnFilter, LazyLoadArgs } from "../../../../core/model/shared";
import { DataView } from "primeng/dataview";
import { DataViewBaseComponent } from "../../../../core/component/data-view.base.component";
import { HomeService } from "../../service/home.service";
import { Constants, DomainValueDto, WARLResourceDto } from "../../../../core/model/feature/apcomodel";

@Component({
    selector: "home-resource-list",
    templateUrl: "./resource-list.component.html"
})
export class HomeResourceListCmpnt extends DataViewBaseComponent<WARLResourceDto> implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    viewTitle: string = "";
    pagePath: string = "/";
    delayLoadDataTimer: any = null;
    @ViewChild("dv1") dataview: DataView | undefined;
    @Input() name: string = "";
    @Input() searchValue: string = "";
    @Input() isFromAdmin: boolean = false;
    @Input() isFilterVisible: boolean = false;
    @Output() isFilterVisibleChange = new EventEmitter<boolean>();
    constructor(
        appCtx: AppContextService,
        private svc: HomeService
    ) {
        super(appCtx);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.componentName = this.name;
            this.loadConfigData();
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    loadConfigData() {
        try {
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    setConfig() {
        try {
            this.fetchRows = 12;
            this.defaultSortField = "Sequence";
            this.defaultSortDesc = false;
            this.filterBy = "ResourceName,ResourceDescription";
            this.sortCols = [{ value: "ResourceName", label: "Name" },
            { value: "ResourceDescription", label: "Description" }];

        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;

            that.loadCompletedSubscription = that.loadCompleted.subscribe(result => {
                if (that.dataview && that.dataview.first !== that.lazyLoadArgs.first) {
                    that.dataview.paginate({ first: that.lazyLoadArgs.first, rows: that.lazyLoadArgs.rows });
                }
            });

            that.navigationSubscription = that.appCtx.onNavigationEnd().subscribe((e: any) => {
                if (that.showComponent && that.isCurrentPage(that.pagePath)) {
                    that.getMenu();
                    that.setMenu();
                }
            });
            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe(data => {
                    that.setViewMode();
                });
            }
            
            if (that.thisForm) {
                that.thisForm.form.markAsPristine();
                if (that.formStatusChangeSubscription) {
                    that.formStatusChangeSubscription.unsubscribe();
                }
                if (that.thisForm.statusChanges) {
                    that.formStatusChangeSubscription = that.thisForm.statusChanges.subscribe(result => {
                        that.onFormChangeEvent();
                    });
                }
            }

            that.setMenu();
            that.setViewMode();
        }, 1000);
    }
    onFormChangeEvent() {
        this.lazyLoadArgs.globalSearch = JSON.parse(JSON.stringify(this.searchValue));        
        this.setDelayLoadDataTimer();
        if (this.thisForm) {
            this.thisForm.form.markAsPristine();
        }
    }
    setDelayLoadDataTimer() {
        let that = this;
        this.delayLoadDataTimer = setTimeout(function () {
            if (that.delayLoadDataTimer) {
                clearTimeout(that.delayLoadDataTimer);
            }
            that.delayLoadDataTimer = undefined;            
            that.loadData();           
        }, 1000);
    }
    setMenu() {
        try {
            this.showMenuItems = false;
            let that = this;
            this.delaySetMenuTimer = setTimeout(function () {
                if (that.delaySetMenuTimer) {
                    clearTimeout(that.delaySetMenuTimer);
                }
                that.delaySetMenuTimer = undefined;
                that.browserMenu = that.browserMenuItems;
                that.mobileMenu = that.mobileMenuItems;
                that.showMenuItems = true;
            }, 100);
        } catch (ex) {
            console.error(ex);
        }
    }
    async getMenu() {
        try {
            this.browserMenuItems = [];
            this.mobileMenuItems = [];
        } catch (ex) {
            console.error(ex);
        }
    }
    setViewMode() {

    }
    lazyLoad(event?: LazyLoadArgs): Observable<DataResult<WARLResourceDto>> {
        return this.svc.getWARLResourcePagedList(event);
    }
    onSearchEvent(filterArg: LazyLoadArgs) {
        this.lazyLoadArgs = filterArg;
        this.loadData()
        this.isFilterVisible = false;
        this.isFilterVisibleChange.emit(this.isFilterVisible);
    }
    onCloseEvent(close: any) {
        this.isFilterVisible = false;
        this.isFilterVisibleChange.emit(this.isFilterVisible);
    }
    getIsResourceURL(item: WARLResourceDto) {
        if (item.ResourceURL)
            return true;
        else
            return false;
    }
}