<div *ngIf="showComponent" class="flex-wrap" style="width:calc(100%);height:calc(100%);overflow:auto;">
    <p-dataView #dv1 [value]="data" [rows]="fetchRows" [loading]="loading" [lazy]="true" [paginator]="true"
        [totalRecords]="totalRecords" filterBy="filterBy" (onLazyLoad)="loadData($event)">
        <ng-template let-item let-index="rowIndex" pTemplate="listItem">
            <div class="column3 p4">
                <div class="flex-wrap bb-1 bgc-white resourceheight" style="border-color:#E5E5E5!important;">
                    <div class="flex-wrap bgc-white resourcedesheight">
                        <div *ngIf="getIsResourceURL(item)" class="flex-wrap">
                            <a [href]="item.ResourceURL" target="_blank" rel="noopener noreferrer">
                                <img class="flex-wrap" [src]="item.ImageURL" [alt]="item.ResourceName">
                            </a>
                        </div>
                        <div *ngIf="!getIsResourceURL(item)" class="flex-wrap">
                            <img class="flex-wrap" [src]="item.ImageURL" [alt]="item.ResourceName">
                        </div>
                        <div class="flex-wrap p4">
                            <core-display-html-content class="flex-wrap"
                                [htmlContent]="item.ResourceDescription"></core-display-html-content>
                        </div>
                    </div>
                    <div class="flex-wrap bgc-white" style="height:3em;">
                        <div class="w10 ml-4 mb-4 p3 bgc-darkturquoise brad2">
                            <div class="center middle w-fit-content">
                                <a target="_blank" rel="noopener noreferrer" class="fc-white font-bold fontsizegov"
                                    style="text-decoration:none;" [href]="item.ResourceURL">Read more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>
<form name="thisForm" class="flex-wrap" style="display:none;" #thisForm="ngForm" autocomplete="off" novalidate>
    <input type="text" name="searchValue" [(ngModel)]="searchValue" class="hidden"><input>
</form>
