import { BaseDto } from "../base";

export class SignalRMessageDto extends BaseDto {
    IsS?: boolean;
    RT?: string;
    // Client Message Id
    CMD?: string;
    // Message Type
    MT?: string;
    // Action Type
    AT?: string;
    // Message Date
    MD?: Date;
    // Data
    Data?: string;
    // Error Message
    EMsg?: string;
    TMZ?: string;
    IsSent?: boolean;
    IsEventTriggered?: boolean;
}