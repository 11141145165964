import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from '@angular/forms';
import { BaseComponent } from "../../../../core/component"
import { AppContextService } from "../../../../core/service";
import { UserDto } from "src/app/core/model/feature/apcomodel";
import { AuthenticationService } from "../../service/authentication.service";

@Component({
    selector: "auth-local-signin",
    templateUrl: "./local-signin.component.html"
})
export class AuthLocalSignInCmpnt extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    showLoginPassword: boolean = false;
    delayRequestTimer: any = null;
    showLogin: boolean = false;
    @Input() visible: boolean = false;
    @Output() onSuccess = new EventEmitter<UserDto>();
    @Output() onFailed = new EventEmitter<any>();
    @Output() onClose = new EventEmitter();

    @Input() user: UserDto = new UserDto({ Username: "", Password: "" });
    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.initUser();
            this.resetAll();
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;
            that.showLogin = true;
        }, 100);
    }
    isValid() {
        let lvValid: any = true;
        try {
            if (this.thisForm && this.thisForm.form && this.thisForm.form.valid) {
                lvValid = true;
            } else {
                lvValid = false;
            }
        } catch (ex) {
            console.error(ex);
        }
        return lvValid;
    }
    resetAll() {
    }
    onProcessRequest() {
        try {
            this.resetAll();
            let that = this;
            this.delayRequestTimer = setTimeout(function () {
                if (that.delayRequestTimer) { clearTimeout(that.delayRequestTimer); }
                that.delayRequestTimer = undefined;
                that.executeRequest();
            }, 1000);
        } catch (ex) {
            console.error(ex);
        }
    }
    executeRequest() {
        try {
            if (this.user.Username && this.user.Password) {
                this.appCtx.apiSvc.signin(this.user).subscribe(authenticatedUser => {
                    // authenticatedUser.InvitationId = this.user.InvitationId;
                    // authenticatedUser.InvitationKey = this.user.InvitationKey;
                    if (authenticatedUser) {
                        this.appCtx.Authenticated = true;
                        this.onSuccess.emit(authenticatedUser);
                    } else {
                        this.appCtx.Authenticated = false;
                        this.onFailed.emit({ error: "Login failed. Invalid credentials" });
                    }
                }, (error: any) => {
                    this.resetAll();
                    this.onFailed.emit(error);
                });
            } else {
                this.resetAll();
                this.onFailed.emit({ error: "Invalid credentials" });
            }
        } catch (ex) {
            console.error(ex);
            this.resetAll();
            this.onFailed.emit(ex);
        }
    }
    onCancelRequest() {
        this.resetAll();
        this.visible = false;
        this.onClose.emit();
    }
    initUser() {
        this.user.Username = "";
        this.user.Password = "";
    }
}