<div *ngIf="showComponent" id="lazyloadAutoCompleteDiv{{liKey}}" #lazyloadAutoCompleteDiv
    class="flex-wrap lazyload-autocomplete">
    <form name="thisForm" class="flex-wrap" #thisForm="ngForm" autocomplete="off" novalidate>
        <div id="txtLazyloadAutoCompleteDiv{{liKey}}" name="txtLazyloadAutoCompleteDiv" #txtLazyloadAutoCompleteDiv
            class="flex-wrap">
            <input id="iptInputValue{{liKey}}" name="iptInputValue{{liKey}}" class="lazyload-autocomplete-input-value"
                type="text" (keyup)="onKeyUp($event)" (click)="onInputClick()" (change)="onValueChanged($event)"
                [(ngModel)]="value" autocomplete="off" [placeholder]="placeholder" maxlength="300" pInputText />
        </div>
        <div id="ddLazyloadAutoCompleteDiv{{liKey}}" name="ddLazyloadAutoCompleteDiv" #ddLazyloadAutoCompleteDiv
            class="flex-wrap fadeInDown lazyload-autocomplete-list" [ngClass]="{'hidden': !visible}">
            <div class="flex-wrap lazyload-autocomplete-list-area" id="lazyload-autocomplete-list-area"
                style="overflow: auto;height:calc(100%);">
                <ul appendTo="body">
                    <ng-template ngFor let-child let-i="index" [ngForOf]="suggestions">
                        <li id="lli_{{liKey}}_{{i}}" [ngClass]="{'lazyload-autocomplete-item-active': isActive(i)}"
                            (click)="onSelectClick(child)">
                            <div class="flex-wrap lazyload-autocomplete-item">
                                <label for="cbLlChild_{{i}}" class="lazyload-autocomplete-item-label"
                                    [ngClass]="{'lga-label': (child.LevelNumber && child.LevelNumber===1),'region-label': (child.LevelNumber && child.LevelNumber===2)}">{{child[optionLabel]}}</label>
                            </div>
                        </li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </form>
</div>