import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "./../../../../core/component";
import { AppContextService } from "./../../../../core/service";
import { UserDto } from 'src/app/core/model/feature/apcomodel';
//import { AppMessages, UserDto } from './../../../../core/model';
@Component({
    selector: "auth-reset-password",
    templateUrl: "./reset-password.component.html"
})
export class AuthResetPasswordCmpnt extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    delayRequestTimer: any = null;

    @Input() visible: boolean = false;
    @Output() onSuccess = new EventEmitter<any>();
    @Output() onFailed = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();

    @Input() verifierId: string = "";
    @Input() verifierValue: string = "";
    @Output() onResetPasswordAgain = new EventEmitter<any>();

    showPassword: boolean = false;
    user: UserDto = new UserDto();

    constructor(appContext: AppContextService) {
        super(appContext);
        try {
            // this.navigationSubscription = this.appContext.onNavigationEnd().subscribe((e: any) => {
            //     if (this.showComponent) {
            //         this.loadInitData();
            //     }
            // });
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            if (this.verifierId && this.verifierValue) {
                this.resetAll();
            } else {
                this.resetAll();
            }
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
        } catch (ex) {
            console.error(ex);
        }
    }
    get isValid() {
        let lvValid: any = false;
        try {
            lvValid = this.thisForm && this.thisForm.form.valid;
        } catch (ex) {
            console.error(ex);
        }
        return lvValid;
    }
    resetAll() {
    }
    onRequest() {
        try {
            this.resetAll();
            let that = this;
            this.delayRequestTimer = setTimeout(function () {
                if (that.delayRequestTimer) { clearTimeout(that.delayRequestTimer); }
                that.delayRequestTimer = undefined;
                that.executeRequest();
            }, 1000);
        } catch (ex) {
            console.error(ex);
        }
    }
    executeRequest() {
        try {
            this.authSvc.resetPassword(this.user, this.verifierId, this.verifierValue).subscribe(result => {
                this.resetAll();
                this.onSuccess.emit(result);
            }, error => {
                console.error("Request to reset password error", error);
                this.resetAll();
                this.onFailed.emit(error);
            });
        } catch (ex) {
            console.error(ex);
            this.resetAll();
            this.onFailed.emit(ex);
        }
    }
    onTryAgain() {
        this.resetAll();
        this.visible = false;
        this.onResetPasswordAgain.emit();
    }
    onCancel() {
        this.resetAll();
        this.visible = false;
        this.onClose.emit();
    }
}