import { BaseDto } from "../baseDto"
/* IpAddressLocationBaseDto generated at 2024-05-26 13:54:05.256 +10:00*/
export class IpAddressLocationBaseDto extends BaseDto {
	IpAddressLocationId?: string;
	IpAddress?: string;
	Hostname?: string;
	City?: string;
	Region?: string;
	PostCode?: string;
	CountryCode?: string;
	TimezoneCode?: string;
	TimezoneName?: string;
	DateLastRefreshed?: Date;
	DateLastSeen?: Date;
}
