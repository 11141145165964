import { Observable, Subject, Subscription } from "rxjs";
import { BaseDto } from "../base";
import { SignalRMessageDto } from "./signalr-message";

export class AppContextEventObject {

    Subscriptions: any = new AppContextSubscription();
    Events: AppContextEvent = new AppContextEvent();
    Subjects: AppContextSubject = new AppContextSubject();

    onAuthenticationChanged(): Observable<any> {
        return this.Subjects.onAuthenticationChanged.asObservable();
    }
    /* Navigation Observables */
    onNavigationStart(): Observable<any> {
        return this.Subjects.onNavigationStart.asObservable();
    }
    onNavigationEnd(): Observable<any> {
        return this.Subjects.onNavigationEnd.asObservable();
    }

    /* Windows Observables */
    onWindowLoad(): Observable<any> {
        return this.Subjects.onWindowLoad.asObservable();
    }
    onWindowSizeChanged(): Observable<any> {
        return this.Subjects.onWindowSizeChanged.asObservable();
    }
    onWindowResized(): Observable<any> {
        return this.Subjects.onWindowResized.asObservable();
    }
    onOrientationChanged(): Observable<any> {
        return this.Subjects.onWindowOrientationChanged.asObservable();
    }
    onWindowMouseClick(): Observable<any> {
        return this.Subjects.onWindowMouseClick.asObservable();
    }
    onWindowStorageChanged(): Observable<StorageEvent> {
        return this.Subjects.onWindowStorageChanged.asObservable();
    }    
    onReceivedMessage(): Observable<SignalRMessageDto> {
        return this.Subjects.onReceivedMessage.asObservable();
    }
    constructor(properties?: any) {
        if (properties) {
            let that: any = this;
            for (let key in properties) {
                if (this.hasOwnProperty(key)) {
                    that[key] = properties[key];
                } else {
                    that[key] = properties[key];
                }
            }
        }
        this.Subscriptions = new AppContextSubscription();
        this.Events = new AppContextEvent();
        this.Subjects = new AppContextSubject();
    }
}
export class AppContextSubscription {
    onWindowStorageChangedSubscription: Subscription | undefined;
    onAuthenticationChangedSubscription: Subscription | undefined;    
    onSignalRIsAuthenticatedSubscription: Subscription | undefined;
    /* Page Navigation */
    onContextNavigationSubscription: Subscription | undefined;

    onSignalRReceivedMessageSubscription: Subscription | undefined;
    constructor(properties?: any) {
        if (properties) {
            let that: any = this;
            for (let key in properties) {
                if (this.hasOwnProperty(key)) {
                    that[key] = properties[key];
                } else {
                    that[key] = properties[key];
                }
            }
        }
    }
}
export class AppContextEvent {
    /* Window events */
    onWindowLoad: any;
    onWindowResize: any;
    onWindowOrientation: any;
    onWindowMouseClick: any;
    onWindowStorageChanged: any;    
    constructor(properties?: any) {
        if (properties) {
            let that: any = this;
            for (let key in properties) {
                if (this.hasOwnProperty(key)) {
                    that[key] = properties[key];
                } else {
                    that[key] = properties[key];
                }
            }
        }
    }
}
export class AppContextSubject {
    onAppContextReady = new Subject<any>();
    onAuthenticationChanged = new Subject<boolean>();

    /* Page Navigation */
    onNavigationEnd = new Subject<any>();
    onNavigationStart = new Subject<any>();

    /* Window events */
    onWindowLoad = new Subject<any>();
    onWindowSizeChanged = new Subject<any>();
    onWindowOrientationChanged = new Subject<any>();
    onWindowResized = new Subject<any>();
    onWindowMouseClick = new Subject<any>();
    onWindowStorageChanged = new Subject<StorageEvent>();
        
    /* signalREvent */
    onReceivedMessage = new Subject<SignalRMessageDto>();

    constructor(properties?: any) {
        if (properties) {
            let that: any = this;
            for (let key in properties) {
                if (this.hasOwnProperty(key)) {
                    that[key] = properties[key];
                } else {
                    that[key] = properties[key];
                }
            }
        }
    }
}