<div *ngIf="showComponent" style="width:calc(100%);"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">

  <!-- Footer-->



  <div class="bannercontainer bgc-darkblue ">
    <div class="column2 bannerimage p4">
      <div class="fc-white footerfont  ">&copy; {{CurrentYear}}</div>
      <div class="ml-4">
        <div class="left">
          <div class="fc-white footerfont ml-4 w6" (click)="onShowDisclaimer()">Disclaimer</div>
        </div>
      </div>
      <div class="ml-4 ">
        <a href="https://documents.packagingcovenant.org.au/other-public-documents/APCO%20Privacy%20Policy"
           target="_blank" class="fc-white footerfont">Privacy</a>
      </div>
      <div class="ml-4 ">
        <a target="_blank" class="fc-white footerfont" style="text-decoration: underline;"
           href="mailto:arl@apco.org.au?subject=Contact from arl.org.au website">arl&#64;apco.org.au</a>
      </div>
    </div>
    <div class="column2 bannerimage">
      <div class="ml-4 ">
        <span class="fc-white footerfont">Powered by:</span>
      </div>
      <div class="bgc-darkblue">
        <a href="https://apco.org.au/"
           target="_blank" class="fc-white footerfont"><img style="width: 16rem; height: 100%;" src="images/logo_APCO_white_cut.png" alt="Logo of the Australian Packaging Covenant Organisation." /></a>
     </div>
    </div>
  </div>
</div>
<p-dialog header="Disclaimer" [(visible)]="showDisclaimer" [style]="{width: '80vw'}" [closable]="true" (onHide)="onCloseDisclaimer()">
  <p>
    <strong>Please note:</strong> Recycle Mate was developed and is maintained by third parties acting
    independently of the Australian Packaging Covenant Organisation (APCO). APCO is not responsible for
    and,
    to the maximum extent permitted by law, does not represent, warrant or guarantee the results
    produced by
    the use of Recycle Mate, including the currency, the accuracy, completeness or suitability for your
    intended use. Recycle Mate is provided to you on an “as is” and “as available” basis.
  </p><br>
  <p>
    You should review Recycle Mate’s Privacy Policy and Terms and Conditions, available at
    www.recyclemate.com.au, carefully and immediately cease using Recycle Mate if you do not agree to
    its
    Terms and Conditions.
  </p><br>
  <p>
    By using Recycle Mate, you acknowledge and agree that Recycle Mate (including the results produced by
    your use of Recycle Mate) cannot be used for any on-pack or external claims, including but not
    limited
    to recyclability claims or recovery claims. Recycle Mate does not replace the on-pack messaging
    requirement under the rules of the Australasian Recycling Label (ARL) and is designed to give
    additional
    local guidance to consumers where possible.
  </p><br>
  <p>
    APCO accepts no responsibility for errors or omissions in the results produced by the use of Recycle
    Mate, or (to the maximum extent permitted by law) liability for any loss or damage arising from the
    use
    of or reliance on Recycle Mate or results generated through the use of Recycle Mate. Recycle Mate is
    subject to revision and withdrawal from public use without notice. Users should seek appropriate
    advice
    when applying the information to their specific needs.
  </p><br>
</p-dialog>
