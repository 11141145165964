import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { BaseComponent } from "src/app/core/component";
import { ActionResult } from "src/app/core/model/shared";
import { AppContextService } from "src/app/core/service";
import { HomeService } from "../../service/home.service";
import { MenuItem } from "primeng/api";
import { Observable, combineLatest, map, switchMap } from "rxjs";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
    selector: 'home-page',
    templateUrl: 'home-page.component.html'
})
export class HomePage extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    viewTitle: string = "";
    pagePath: string = "/";
    id: string = "";
    showDisclaimer: boolean = false;
    isProd: boolean = false;
    constructor(
        private route: ActivatedRoute,
        appCtx: AppContextService,
        public svc: HomeService) {
            super(appCtx);
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.appCtx.pagePath = this.pagePath;
            if (this.appCtx.AppInfo.RMUrl == "https://sitelet.recyclemate.com.au/sitelet/2"){
                this.isProd = true;
            }
            this.loadConfigData();
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    loadConfigData() {
        try {
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    setConfig() {
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            await this.getMenu();
            this.getInitData().subscribe(pageData => {
                let isNZ = false;
                if (pageData.id) {
                    this.id = pageData.id;
                    if (this.id.toLowerCase() == "nz") isNZ = true;
                }
                if (pageData.locationData && pageData.locationData.Data && pageData.locationData.Data.CountryCode) {
                    if (pageData.locationData.Data.CountryCode.toUpperCase() == "NZ" || pageData.locationData.Data.CountryCode.toUpperCase() == "NEW ZEALAND") {
                        isNZ = true;
                    }
                }
                this.initPage();
                if (isNZ) {
                    this.confirmSvc.confirm({
                        //message: "Are you looking for information on recycling soft plastics or liquid paperboard cartons in NZ?",
                        message: "Are you looking for information on the ARL and recycling in Aotearoa NZ?",
                        header: "ARL in New Zealand",
                        accept: () => {
                            //document.location.replace("https://www.recycling.kiwi.nz");
                            document.location.replace("https://www.fgc.org.nz/about-us/arl");
                        },
                        reject: () => {
                            this.sharedSvc.navigate(["/"]);                            
                        }
                    });                    
                }
            }, idErr => {
                this.initPage();
            });
        } catch (ex) {
            console.error(ex);
        }
    }
    getInitData(): Observable<any> {
        let id = this.id;
        return combineLatest([
            this.getId(),
            this.svc.getIpLocationDetail()
        ]).pipe(map(resp => {
            return {
                id: resp[0],
                locationData: resp[1] as ActionResult
            };
        }));
    }
    getId(): Observable<string> {
        return this.route.paramMap.pipe(switchMap((params: ParamMap) => {
            try {
                let id = params.getAll("id");
                if (id.length) {
                    return id;
                } else {
                    return [""];
                }
            } catch (ex) {
                console.log(ex);                
                return [""];
            }
        }));
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;

            that.navigationSubscription = that.appCtx.onNavigationEnd().subscribe((e: any) => {
                if (that.showComponent && that.isCurrentPage(that.pagePath)) {
                    that.getMenu();
                    that.setMenu();
                }
            });
            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe(data => {
                    that.setViewMode();
                });
            }
            that.setMenu();
            that.setViewMode();
        }, 1000);
    }
    setMenu() {
        try {
            this.showMenuItems = false;
            let that = this;
            this.delaySetMenuTimer = setTimeout(function () {
                if (that.delaySetMenuTimer) {
                    clearTimeout(that.delaySetMenuTimer);
                }
                that.delaySetMenuTimer = undefined;
                that.browserMenu = that.browserMenuItems;
                that.mobileMenu = that.mobileMenuItems;
                that.showMenuItems = true;
            }, 100);
        } catch (ex) {
            console.error(ex);
        }
    }
    async getMenu() {
        try {
            this.browserMenuItems = [];
            this.mobileMenuItems = [];
            
            let menuList = this.getARLHomePageMenu();
            menuList.forEach(x => {
                let browserMenuItem: MenuItem = JSON.parse(JSON.stringify(x));
                if (browserMenuItem.routerLink && browserMenuItem.routerLink == this.pagePath) {
                    browserMenuItem.styleClass = "bgc-yellow brad4";
                } else {
                    if (browserMenuItem.items && browserMenuItem.items.length != 0) {
                        browserMenuItem.items.forEach(y => {
                            if (y.routerLink && y.routerLink == this.pagePath) {
                                y.styleClass = "bgc-yellow brad4";
                            }
                        });
                    }
                }
                let mobileMenuItem: MenuItem = JSON.parse(JSON.stringify(x));
                if (mobileMenuItem.routerLink && mobileMenuItem.routerLink == this.pagePath) {
                    mobileMenuItem.styleClass = "bgc-yellow";
                } else {
                    if (mobileMenuItem.items && mobileMenuItem.items.length != 0) {
                        mobileMenuItem.items.forEach(y => {
                            if (y.routerLink && y.routerLink == this.pagePath) {
                                y.styleClass = "bgc-yellow";
                            }
                        });
                    }
                }
                this.browserMenuItems.push(browserMenuItem);
                this.mobileMenuItems.push(mobileMenuItem);
            });

        } catch (ex) {
            console.error(ex);
        }
    }
    setViewMode() {
    }
    onCloseDisclaimer() {
        this.showDisclaimer = false;
    }
    onShowDisclaimer() {
        this.showDisclaimer = true;
    }
}