import { NgModule, Injector } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ApiService, AppContextService, ConfigService, LocatorService, UtilityService } from './service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedService } from './service/shared/shared.service';
//import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
//import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
//import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
//import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
//import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
//import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from "primeng/multiselect";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
//import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { RadioButtonModule } from "primeng/radiobutton";
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SplitButtonModule } from "primeng/splitbutton";
import { StepsModule } from "primeng/steps";
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
// import { MatButtonModule } from '@angular/material/button';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatListModule } from '@angular/material/list';
//import { QuillModule } from 'ngx-quill';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { DomService } from './service/base/dom.service';
import { CoreDisplayHtmlContentCmpnt, CoreHelpInfoCmpnt, CoreLazyloadAutoCompleteCmpnt } from './component';
import { SignalRService } from './service/base/signalR.service';
import { EmailValidatorDirective } from './directive/email-validator.directive';
@NgModule({
    imports: [
        IonicModule,
        FormsModule, ReactiveFormsModule,
        //AccordionModule, CardModule, ContextMenuModule, FileUploadModule, InplaceModule, MegaMenuModule, 
        AutoCompleteModule, BlockUIModule, CalendarModule, CarouselModule, ChartModule,
        CheckboxModule, ConfirmDialogModule, DataViewModule, DialogModule,
        DropdownModule, EditorModule, GalleriaModule, ImageModule,
        InputMaskModule, InputSwitchModule, InputTextareaModule,
        InputTextModule, KeyFilterModule, ListboxModule, MenubarModule, MenuModule, MultiSelectModule,
        OverlayPanelModule, PanelModule, PanelMenuModule, PasswordModule,
        //        PickListModule, 
        ProgressSpinnerModule,
        RadioButtonModule, SelectButtonModule, SidebarModule, SplitButtonModule, StepsModule, TableModule,
        TabViewModule, TieredMenuModule, ToastModule, ToolbarModule, TreeModule, ScrollPanelModule,
        //        MatButtonModule, MatToolbarModule, MatListModule, 
        YouTubePlayerModule,
        HttpClientModule, RouterModule
    ],
    declarations: [
        CoreHelpInfoCmpnt, CoreDisplayHtmlContentCmpnt, CoreLazyloadAutoCompleteCmpnt, EmailValidatorDirective
    ],
    exports: [
        IonicModule, FormsModule, ReactiveFormsModule,
        //AccordionModule, CardModule, ContextMenuModule, FileUploadModule, InplaceModule, MegaMenuModule, 
        AutoCompleteModule, BlockUIModule, CalendarModule, CarouselModule, ChartModule,
        CheckboxModule, ConfirmDialogModule, DataViewModule, DialogModule,
        DropdownModule, EditorModule, GalleriaModule, ImageModule,
        InputMaskModule, InputSwitchModule, InputTextareaModule,
        InputTextModule, KeyFilterModule, ListboxModule, MenubarModule, MenuModule, MultiSelectModule,
        OverlayPanelModule, PanelModule, PanelMenuModule, PasswordModule,
        //        PickListModule, 
        ProgressSpinnerModule,
        RadioButtonModule, SelectButtonModule, SidebarModule, SplitButtonModule, StepsModule, TableModule,
        TabViewModule, TieredMenuModule, ToastModule, ToolbarModule, TreeModule, ScrollPanelModule,
        //        MatButtonModule, MatToolbarModule, MatListModule, 
        YouTubePlayerModule,
        HttpClientModule, RouterModule,
        CoreHelpInfoCmpnt, CoreDisplayHtmlContentCmpnt, CoreLazyloadAutoCompleteCmpnt, EmailValidatorDirective
    ],
    providers: [
        SharedService,
        ApiService,
        DomService,
        UtilityService,
        SignalRService,
        ConfigService
    ]
})
export class CoreModule {
    constructor(private injector: Injector) {
        LocatorService.injector = this.injector;
    }
}