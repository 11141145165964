import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class Globals {
	viewTitle!: string | "";
	viewTitleTimer: any;
    constructor(private cookiesSvc: CookieService) {
        Object.defineProperty(this, "token", {
            get: function () {
                let authToken = this.cookiesSvc.get("ARL");
                if (authToken === undefined || authToken === "") {
                    authToken = localStorage['Authorisation'];
                    if (!authToken) {
                        authToken = null;
                    }
                }
                return authToken;
            }
        });
    }
	get ViewTitle(): string {
		return this.viewTitle;
	}
	set ViewTitle(value: string) {
		let that = this;
		if (!this.viewTitleTimer) {
			this.viewTitleTimer = setTimeout(function () {
				if (that.viewTitleTimer) { clearTimeout(that.viewTitleTimer); }
				that.viewTitleTimer = undefined;
				that.viewTitle = value;
			}, 100);
		}
	}
}