import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UtilityService } from "./utility.service";
import { ApplicationInfo } from "../../model/shared/app-info";
import { UserDto } from "../../model/feature/apcomodel";

@Injectable({
    providedIn: "root"
})
export class DomService {
	private utilSvc: UtilityService = new UtilityService();
    constructor() { }
    
    get AppInfo(): ApplicationInfo {
        let appInfo = new ApplicationInfo();
        try {
            let appInfoEncrypt = localStorage["AppInfo"];
            if (appInfoEncrypt) {
                appInfo = JSON.parse(appInfoEncrypt) as ApplicationInfo;
            }
        } catch (ex) { }
        return appInfo;
    }
    set AppInfo(appInfo: ApplicationInfo) {
        if (!appInfo) {
            appInfo = new ApplicationInfo();
        }
        let appInfoJSON = JSON.stringify(appInfo);
        localStorage["AppInfo"] = appInfoJSON;
    }
    get ANZPACSRMessage(): string {
        return localStorage["ANZPACSRMessage"];
    }
    set ANZPACSRMessage(value: string) {
        if (value) {
            localStorage["ANZPACSRMessage"] = value;
        } else {
            delete localStorage["ANZPACSRMessage"];
        }
    }
    get AdminSRMessage(): string {
        return localStorage["AdminSRMessage"];
    }
    set AdminSRMessage(value: string) {
        if (value) {
            localStorage["AdminSRMessage"] = value;
        } else {
            delete localStorage["AdminSRMessage"];
        }
    }
    get SRMessage(): string {
        return localStorage["SRMessage"];
    }
    set SRMessage(value: string) {
        if (value) {
            localStorage["SRMessage"] = value;
        } else {
            delete localStorage["SRMessage"];
        }
    }
    get Authorisation(): string {
        return localStorage["Authorisation"];
    }
    set Authorisation(value: string) {
        localStorage["Authorisation"] = value;
    }
    get LastMessageDate(): any {
        if (localStorage["LastMessageDate"]) {
            return new Date(localStorage["LastMessageDate"]);
        } else {
            return null;
        }
    }
    set LastMessageDate(value: Date) {
        if (value) {
            localStorage["LastMessageDate"] = new Date(value).toUTCString();
        } else {
            delete localStorage["LastMessageDate"];
        }
    }
    get LastConnectionDate(): any {
        if (localStorage["LastConnectionDate"]) {
            return new Date(localStorage["LastConnectionDate"]);
        } else {
            return null;
        }
    }
    set LastConnectionDate(value: Date) {
        if (value) {
            localStorage["LastConnectionDate"] = new Date(value).toUTCString();
            if (!this.FirstConnectionDate) {
                this.FirstConnectionDate = value;
            }
        } else {
            delete localStorage["LastConnectionDate"];
            delete localStorage["FirstConnectionDate"];
        }
    }
    get FirstConnectionDate(): any {
        if (localStorage["FirstConnectionDate"]) {
            return new Date(localStorage["FirstConnectionDate"]);
        } else {
            return null;
        }
    }
    set FirstConnectionDate(value: Date) {
        if (value) {
            localStorage["FirstConnectionDate"] = new Date(value).toUTCString();
        } else {
            delete localStorage["FirstConnectionDate"];
        }
    }
    get Expires(): any {
        if (localStorage["Expires"]) {
            return new Date(localStorage["Expires"]);
        } else {
            return null;
        }
    }
    set Expires(value: Date) {
        localStorage["Expires"] = new Date(value).toUTCString();
    }
    get RefreshToken(): string {
        return localStorage["RefreshToken"];
    }
    set RefreshToken(value: string) {
        localStorage["RefreshToken"] = value;
    }
    get UserInfo(): UserDto {
        let userInfo = new UserDto();
        try {
            let userInfoEncrypt = localStorage["UserInfo"];
            if (userInfoEncrypt) {
                userInfo = JSON.parse(userInfoEncrypt) as UserDto;
            }
        } catch (ex) { }
        return userInfo;
    }
    set UserInfo(userInfo: UserDto) {
        if (!userInfo) {
            userInfo = new UserDto();
        }
        let userInfoJSON = JSON.stringify(userInfo);
        localStorage["UserInfo"] = userInfoJSON;
    }
    public credentialCleanUp() {
        delete localStorage["UserInfo"]
        delete localStorage["Authorisation"];
        delete localStorage["Expires"];
        delete localStorage["RefreshToken"];
        delete localStorage["LastMessageDate"];
    }
}