import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../globals';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ApiService } from './api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfo, Guid, LazyLoadArgs, SignalRMessageDto } from '../../model/shared';
import { UtilityService } from './utility.service';
import { SharedService } from '../shared/shared.service';
import { DomService } from './dom.service';
import { Observable } from 'rxjs';
import { AppContextEventObject } from '../../model/shared/app-context';
import { ConfirmationService } from 'primeng/api';
import { SignalRService } from './signalR.service';
declare var window: any;

@Injectable()
export class AppContextService implements OnDestroy {
    public InstanceId: string = Guid.newGuid();
    public isAuthenticated: boolean = false;
    public AppInfo: ApplicationInfo = new ApplicationInfo();
    public iAppCtx: AppContextEventObject = new AppContextEventObject();
    public IsSideMenuView: boolean = false;
    public IsTopMenuView: boolean = false;
    public IsMobileView: boolean = false;
    public IsPortraitView: boolean = false;
    public pagePath: string = "";
    public refreshTokenTimer: any = null;
    public logoutTimer: any = null;
    public routineTimer: any = null;
    private listViewFilters: { id: string, componentName: string, componentGuid: string, listViewPath: string, filter: LazyLoadArgs }[] = [];
    constructor(
        public apiSvc: ApiService,
        public sharedSvc: SharedService,
        public confirmSvc: ConfirmationService,
        public domSvc: DomService,
        public utilSvc: UtilityService,
        public srSvc: SignalRService,
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public httpClient: HttpClient,
        public globals: Globals,
        public sanSvc: DomSanitizer
    ) {
        try {
            this.loadConfigData();
        } catch (ex) {
            console.error(ex);
        }
    }
    private loadConfigData() {
        try {
            this.AppInfo = environment;
            this.domSvc.AppInfo = this.AppInfo;
            this.setRefreshTokenTimer();
            this.setLogoutTimer();
            this.setRoutineTimer();
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    private async setConfig() {
        try {
            this.initSubscriptions();
            this.srSvc.Enabled = true;
            this.setIsMobileView();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
        } catch (ex) {
            console.error(ex);
        }
    }
    get Authenticated(): boolean {
        if (this.domSvc.Authorisation && this.domSvc.Expires) {
            if (this.utilSvc.dateSecondsDiff(new Date(), this.domSvc.Expires) > 0) {
                this.Authenticated = true;
                return true;
            } else {
                this.Authenticated = false;
                return false;
            }
        } else {
            this.Authenticated = false;
            return false;
        }
    }
    set Authenticated(value: boolean) {
        if (this.isAuthenticated != value) {
            this.isAuthenticated = value;
            if (!this.isAuthenticated) {
                this.domSvc.credentialCleanUp();
            }
            this.iAppCtx.Subjects.onAuthenticationChanged.next(value);
        }
        if (!this.isAuthenticated && this.domSvc.Authorisation){
            this.domSvc.credentialCleanUp();
        }
    }

    public IsRole(roleTypeCode: string, roleCode: string) {
        if (this.domSvc.UserInfo && this.domSvc.UserInfo?.UserRoles) {
            if (this.domSvc.UserInfo?.UserRoles.length) {
                return (this.domSvc.UserInfo.UserRoles.filter(x => x.RoleTypeCode === roleTypeCode && x.RoleCode === roleCode).length >= 1);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    private initSubscriptions(): void {
        this.initWindowSubscription();
        this.initWindowStorageChangeSubscription();
    }
    private destroySubscriptions(): void {
        for (let key in Object.keys(this.iAppCtx.Subscriptions)) {
            if (this.iAppCtx.Subscriptions[key]) {
                this.iAppCtx.Subscriptions[key].unsubscribe();
            }
        }
    }
    private initWindowSubscription(): void {
        let that = this;
        this.iAppCtx.Events.onWindowLoad = window.addEventListener("onload", function (event: any) {
            that.iAppCtx.Subjects.onWindowLoad.next(event);
        });
        this.iAppCtx.Events.onWindowResize = window.addEventListener("resize", function (event: any) {
            that.setIsMobileView();
            that.iAppCtx.Subjects.onWindowResized.next(event);
            that.iAppCtx.Subjects.onWindowSizeChanged.next(event);
        });
        if (window && window.screen && window.screen.orientation) {
            this.iAppCtx.Events.onWindowOrientation = window.screen.orientation.addEventListener("change", function (event: any) {
                that.setIsMobileView();
                that.iAppCtx.Subjects.onWindowOrientationChanged.next(event);
                that.iAppCtx.Subjects.onWindowSizeChanged.next(event);
            });
        }
        this.iAppCtx.Events.onWindowMouseClick = window.addEventListener('click', function (event: any) {
            that.iAppCtx.Subjects.onWindowMouseClick.next(event);
        });
        this.iAppCtx.Events.onWindowStorageChanged = window.addEventListener('storage', function (event: any) {
            that.iAppCtx.Subjects.onWindowStorageChanged.next(event);
        });
        // this.iAppCtx.Subscriptions.onAuthenticatedSubscription = this.apiSvc.onAuthenticated.subscribe(value => {
        //     if (this.Authenticated !== value) {
        //         this.Authenticated = value;
        //     }
        // }, error => { });
    }
    initWindowStorageChangeSubscription() {
        this.iAppCtx.Subscriptions.onWindowStorageChangedSubscription = this.iAppCtx.onWindowStorageChanged().subscribe((sEvent: StorageEvent) => {
            if (sEvent.key === "AppLogin" && sEvent.newValue) {
                let newAppLoginEnc = sEvent.newValue;
            } else if (sEvent.key === "RefreshingToken") {
                // if (sEvent.newValue && sEvent.newValue !== "null") {
                //     this.domSvc.RefreshingToken = new Date(sEvent.newValue);
                // } else {
                //     this.domSvc.RefreshingToken = null;
                // }
            }
        });
        this.iAppCtx.Subscriptions.onSignalRReceivedMessageSubscription = this.srSvc.onReceivedMessage.subscribe((result: SignalRMessageDto) => {
            this.iAppCtx.Subjects.onReceivedMessage.next(result);
        }, err => {
            this.iAppCtx.Subjects.onReceivedMessage.next(err);
        });
        this.iAppCtx.Subscriptions.onSignalRIsAuthenticatedSubscription = this.srSvc.onIsAuthenticated.subscribe((authenticated: boolean) => {
            this.Authenticated = authenticated;
        }, err => {

        });
    }
    isCurrentPage(currentPagePath: string) {
        let url = location.href;
        let foundIndex = url.indexOf(currentPagePath);
        if (foundIndex >= 0) {
            return true;
        } else {
            return false;
        }
    }
    setRefreshTokenTimer() {
        let that = this;
        if (this.refreshTokenTimer) {
            clearTimeout(that.refreshTokenTimer);
        }
        this.refreshTokenTimer = setTimeout(function () {
            if (that.refreshTokenTimer) {
                clearTimeout(that.refreshTokenTimer);
            }
            that.refreshTokenTimer = undefined;
            that.checkRefreshToken();
        }, this.getRefreshTimerTicks());
    }
    getRefreshTimerTicks() {
        let refreshTimerTicks = (this.utilSvc.getRandomInt(50) * 11000) + 11000;
        return refreshTimerTicks;
    }
    checkRefreshToken() {
        if (this.Authenticated && this.isCurrentPage(this.pagePath)) {
            if (this.utilSvc.dateSecondsDiff(new Date(), this.domSvc.Expires) < 600) {
                this.apiSvc.refreshToken(this.domSvc.RefreshToken).subscribe(response => {
                    this.setRefreshTokenTimer();
                }, error => {
                    this.setRefreshTokenTimer();
                });
            } else {
                this.setRefreshTokenTimer();
            }
        } else {
            this.setRefreshTokenTimer();
        }
    }
    setRoutineTimer() {
        let that = this;
        if (this.routineTimer) {
            clearTimeout(that.routineTimer);
        }
        this.routineTimer = setTimeout(function () {
            if (that.routineTimer) {
                clearTimeout(that.routineTimer);
            }
            that.routineTimer = undefined;
            that.checkRoutineTask();
        }, this.getRefreshTimerTicks());
    }
    checkRoutineTask() {
        try {
            if ((this.domSvc.FirstConnectionDate && (this.utilSvc.dateSecondsDiff(this.domSvc.FirstConnectionDate, new Date()) > 7200)
                && this.domSvc.LastMessageDate && (this.utilSvc.dateSecondsDiff(this.domSvc.LastMessageDate, new Date()) > 1800))
                || (this.domSvc.FirstConnectionDate && (this.utilSvc.dateSecondsDiff(this.domSvc.FirstConnectionDate, new Date()) > 28800))) {
                delete localStorage["LastConnectionDate"];
                delete localStorage["FirstConnectionDate"];
                document.location.replace(document.location.href);
                this.setRoutineTimer();
            } else if (this.domSvc.LastConnectionDate && (this.utilSvc.dateSecondsDiff(this.domSvc.LastConnectionDate, new Date()) > 3600)) {
                this.srSvc.Disconnect();
                this.setRoutineTimer();
            } else {
                this.srSvc.SendMessage(Guid.newGuid(), "Connection", "Ticks", null).subscribe(r => {
                    this.setRoutineTimer();
                }, e => {
                    this.setRoutineTimer();
                    console.log(e);
                });
            }
        } catch (e) {
            this.setRoutineTimer();
        }
    }
    setLogoutTimer() {
        let that = this;
        if (this.logoutTimer) {
            clearTimeout(that.logoutTimer);
        }
        this.logoutTimer = setTimeout(function () {
            if (that.logoutTimer) {
                clearTimeout(that.logoutTimer);
            }
            that.logoutTimer = undefined;
            that.setLogout();
        }, this.getRefreshTimerTicks());
    }
    setLogout() {
        try {
            if (this.Authenticated && this.domSvc.LastMessageDate) {
                if (this.utilSvc.dateSecondsDiff(this.domSvc.LastMessageDate, new Date()) > 3000) {
                    this.apiSvc.signOut().subscribe((signoutResult: any) => {
                        this.credentialCleanUp();
                        this.navigateOnLogout();
                        this.Authenticated = false;
                        this.setLogoutTimer();
                    }, (error: any) => {
                        this.credentialCleanUp();
                        this.navigateOnLogout();
                        this.Authenticated = false;
                        this.setLogoutTimer();
                    });
                } else {
                    this.setLogoutTimer();
                }
            } else {
                this.setLogoutTimer();
            }

        } catch (ex) {
            this.setLogoutTimer();
        }
    }
    onNavigationEnd(): Observable<any> {
        return new Observable(observer => {
            this.router.events.subscribe((event: any) => {
                if (event instanceof NavigationEnd) {
                    observer.next(event);
                }
            });
        });
    }
    public setIsMobileView() {
        if (window.innerWidth <= 960) {
            //if (window.innerWidth <= 820 || window.innerHeight <= 820) {
            this.IsMobileView = true;
        } else {
            this.IsMobileView = false;
        }
        if (window.innerWidth <= window.innerHeight) {
            this.IsPortraitView = true;
        } else {
            this.IsPortraitView = false;
        }
    }
    public navigateOnAuthenticated() {
        if (location.href.indexOf("/admin/home") >= 0) {
            this.sharedSvc.navigateByUrl("/admin/config");
        } else {
            this.sharedSvc.navigateByUrl(this.sharedSvc.replaceHref(location.href));
        }
    }
    public navigateOnLogout() {
        if (location.href.indexOf("/admin/configure") >= 0) {
            this.sharedSvc.navigate(["/admin/home"]);
        } else if (location.href.indexOf("/anzpac/auth/memberreport") >= 0) {
            this.sharedSvc.navigate(["/anzpac/memberreporterror"]);
        } else {
            this.sharedSvc.navigateByUrl(this.sharedSvc.replaceHref(location.href));
        }
    }
    public credentialCleanUp() {
        this.domSvc.credentialCleanUp();
    }
    getListViewFilter(componentName: string, componentGuid: string): LazyLoadArgs {
        let suffix = location.pathname.substring(location.pathname.lastIndexOf("/") + 1, location.pathname.length);
        let id = "";
        if (suffix.indexOf("-") >= 0 || suffix.toLowerCase() === "new") {
            id = suffix;
        }
        let currentPath = location.pathname;
        let currentFilter = this.listViewFilters.find(x => {
            if (id) {
                if (componentName) {
                    return x.listViewPath === currentPath && x.componentName === componentName && x.id === id;
                } else {
                    return x.listViewPath === currentPath && x.componentGuid === componentGuid && x.id === id;
                }
            } else {
                if (componentName) {
                    return x.listViewPath === currentPath && x.componentName === componentName;
                } else {
                    return x.listViewPath === currentPath && x.componentGuid === componentGuid;
                }
            }
        });
        return currentFilter ? currentFilter.filter : new LazyLoadArgs();
    }
    saveListViewFilter(componentName: string, componentGuid: string, filter: LazyLoadArgs) {
        let path = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
        let suffix = location.pathname.substring(location.pathname.lastIndexOf("/") + 1, location.pathname.length);
        let id = "";
        if (suffix.indexOf("-") >= 0 || suffix.toLowerCase() === "new") {
            id = suffix;
        }
        let currentPath = location.pathname;
        let currentFilter = this.listViewFilters.find(x => {
            if (id) {
                if (componentName) {
                    return x.listViewPath === currentPath && x.componentName === componentName && x.id === id;
                } else {
                    return x.listViewPath === currentPath && x.componentGuid === componentGuid && x.id === id;
                }
            } else {
                if (componentName) {
                    return x.listViewPath === currentPath && x.componentName === componentName;
                } else {
                    return x.listViewPath === currentPath && x.componentGuid === componentGuid;
                }
            }
        });
        if (!componentName) {
            componentName = "";
        }
        filter.isSet = true;
        if (!currentFilter) {
            currentFilter = {
                id: id,
                componentName: componentName,
                componentGuid: componentGuid,
                listViewPath: currentPath,
                filter: filter
            };
            this.listViewFilters.push(currentFilter);
        } else {
            currentFilter.filter = filter;
        }
    }
}
