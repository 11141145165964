<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;" [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
    <div id="pageTop" #pageTop class="flex-wrap device-layout-top">
        <div class="device-layout-fix-width center">
            <div class="flex-wrap" [ngClass]="{'hidden': !showResetPassword}" *ngIf="showComponent && showResetPassword">
                <auth-reset-password name="resetPasswordCmpnt" #resetPasswordCmpnt class="flex-wrap"
                    [visible]="showComponent && showResetPassword" (onSuccess)="onResetPasswordSuccess($event)"
                    (onFailed)="onResetPasswordFailed($event)" (onClose)="onResetPasswordClose($event)"
                    [verifierId]="verifierId" [verifierValue]="verifierValue"
                    (onResetPasswordAgain)="onResetPasswordAgain($event)">
                </auth-reset-password>
            </div>
            <div class="flex-wrap" [ngClass]="{'hidden': !showForgotAccount}" *ngIf="showComponent && showForgotAccount">
                <auth-forgot-account name="forgotAccountCmpnt" #forgotAccountCmpnt class="flex-wrap"
                    [visible]="showComponent && showForgotAccount" (onSuccess)="onForgotAccountSuccess($event)"
                    (onFailed)="onForgotAccountFailed($event)" (onClose)="onForgotAccountClose($event)">
                </auth-forgot-account>
            </div>
        </div>
    </div>
</div>