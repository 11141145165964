import { BaseDto } from "../baseDto"
/* TableFieldBaseDto generated at 2024-05-26 13:54:05.411 +10:00*/
export class TableFieldBaseDto extends BaseDto {
	TableFieldId?: string;
	TableName?: string;
	ColumnId?: number;
	ColumnName?: string;
	DataType?: string;
	MaxLength?: number;
	Precision?: number;
	Scale?: number;
	IsNullable?: boolean;
	IsPrimaryKey?: boolean;
}
