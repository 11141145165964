import { BaseDto } from "../baseDto"
/* APCOAnnualReportBaseDto generated at 2024-05-26 13:54:05.188 +10:00*/
export class APCOAnnualReportBaseDto extends BaseDto {
	APCOAnnualReportId?: string;
	OrganisationId?: string;
	SubmissionYear?: string;
	DomainTypeName?: string;
	FileURL?: string;
	ReportingPeriod?: string;
	TextData?: string;
	LastErrorMessage?: string;
	StatusCode?: string;
}
