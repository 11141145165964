import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable, switchMap } from "rxjs";
import { BaseComponent } from "src/app/core/component";
import { ActionResult } from "src/app/core/model/shared";
import { AppContextService } from "src/app/core/service";
import { HomeService } from "../../service/home.service";

@Component({
    selector: 'home-main-page',
    templateUrl: 'main-page.component.html'
})
export class HomeMainPage extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    viewTitle: string = "";
    pagePath: string = "/page";
    id: string = "";
    constructor(
        private route: ActivatedRoute,
        appCtx: AppContextService, 
        public svc: HomeService) {
            super(appCtx);
    }
    ngOnDestroy() {
        try {
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.appCtx.pagePath = this.pagePath;
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.getId().subscribe((id:string) => {
                if (id) {
                    this.id = id;
                    this.initPage();
                } else {
                    this.sharedSvc.navigate(["/"]);
                }
            }, idErr => {
                this.initPage();
            });
        } catch (ex) {
            console.error(ex);
        }
    }
    getId(): Observable<string> {
        return this.route.paramMap.pipe(switchMap((params: ParamMap) => {
            try {
                let id = params.getAll("id");
                if (id.length) {
                    return id;
                } else {
                    return [""];
                }
            } catch (ex) {
                console.log(ex);                
                return [""];
            }
        }));
    }
    initPage() {
        try {
            this.showComponent = true;
        } catch (ex) {
            console.error(ex);
        }
    }
}