import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BaseComponent } from '../base.component';
import { AppContextService } from '../../service/base/app-context.service';
import { Guid } from '../../model/shared';
import { BaseDto } from '../../model/base';

@Component({
    selector: "core-lazyload-autocomplete",
    templateUrl: "./lazyload-autocomplete.component.html",
    styleUrls: ['./lazyload-autocomplete.component.scss']
})
export class CoreLazyloadAutoCompleteCmpnt extends BaseComponent implements OnInit, OnDestroy {
    liKey: string = Guid.newGuid();
    visible: boolean = false;
    delaySetElementDimension: any;
    delayTriggerQueryComplete: any;
    activeIndex: number = 0;
    @Input() valueField: string = "";
    @Input() optionLabel: string = "";
    @Input() value: string = "";
    @Input() suggestions: any = [];
    @Input() placeholder: string = "";
    @Output() onChange = new EventEmitter<string>();
    @Output() onSelected = new EventEmitter<LazyloadAutoCompleteResult>();
    @Output() onQueryComplete = new EventEmitter<string>();
    @ViewChild("lazyloadAutoCompleteDiv") lazyloadAutoCompleteDiv: HTMLDivElement | any;
    @ViewChild("txtLazyloadAutoCompleteDiv") txtLazyloadAutoCompleteDiv: HTMLDivElement | any;
    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            if (!this.suggestions) {
                this.suggestions = [];
            }
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;
            that.initDetectOutsideDivClick();
            that.setDivElement();
            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe((data:any) => {
                    that.setDivTimer();
                });
            }
        }, this.getRandomStart(5));
    }
    setDivTimer() {
        let that = this;
        if (this.delaySetDivTimer) { clearTimeout(this.delaySetDivTimer); }
        this.delaySetDivTimer = undefined;
        this.delaySetDivTimer = setTimeout(function () {
            if (that.delaySetDivTimer) { clearTimeout(that.delaySetDivTimer); }
            that.delaySetDivTimer = undefined;
            that.setDivElement();
        }, 500);
    }
    setDivElement() {
        try {
            if (this.txtLazyloadAutoCompleteDiv) {
                let txtW = 0;
                if (this.txtLazyloadAutoCompleteDiv["nativeElement"] && this.txtLazyloadAutoCompleteDiv["nativeElement"].clientWidth) {
                    txtW = this.txtLazyloadAutoCompleteDiv["nativeElement"].clientWidth;
                }
                let txtLazyloadAutoCompleteDivId = document.getElementById("txtLazyloadAutoCompleteDiv" + this.liKey);
                if (txtLazyloadAutoCompleteDivId) {
                    let txtLazyloadAutoCompleteRect = txtLazyloadAutoCompleteDivId.getBoundingClientRect();

                    let ddLazyloadAutoCompleteDiv:any = document.getElementById("ddLazyloadAutoCompleteDiv" + this.liKey);
                    if (ddLazyloadAutoCompleteDiv) {
                        ddLazyloadAutoCompleteDiv.style.width = Math.ceil(txtLazyloadAutoCompleteRect.width).toString() + "px";
                        if (ddLazyloadAutoCompleteDiv.offsetParent &&
                            ddLazyloadAutoCompleteDiv.offsetParent["offsetTop"] &&
                            ddLazyloadAutoCompleteDiv.offsetParent["offsetParent"] &&
                            ddLazyloadAutoCompleteDiv.offsetParent["offsetParent"].clientHeight) {
                            let bufferRate = 1.3;
                            let requiredHeight = ddLazyloadAutoCompleteDiv.offsetParent["offsetTop"] + (ddLazyloadAutoCompleteDiv.offsetHeight * bufferRate);
                            let parentHeight = ddLazyloadAutoCompleteDiv.offsetParent["offsetParent"].clientHeight;
                            if (requiredHeight > parentHeight) {
                                if (ddLazyloadAutoCompleteDiv.offsetParent["offsetTop"] > ddLazyloadAutoCompleteDiv.offsetHeight) {
                                    ddLazyloadAutoCompleteDiv.style.top = "-" + (ddLazyloadAutoCompleteDiv.offsetHeight + 1).toString() + "px";
                                } else {
                                    ddLazyloadAutoCompleteDiv.style.top = "-" + (Math.ceil(ddLazyloadAutoCompleteDiv.offsetHeight / 2)).toString() + "px";
                                }
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    initDetectOutsideDivClick() {
        this.windowMouseClickSubscription = this.appCtx.iAppCtx.onWindowMouseClick().subscribe(e => {
            if (!this.lazyloadAutoCompleteDiv["nativeElement"].contains(e.target)) {
                this.visible = false;
            }
        });
    }
    onInputClick() {
        let that = this;
        if (this.delayTriggerQueryComplete) {
            clearTimeout(this.delayTriggerQueryComplete);
        }
        this.delayTriggerQueryComplete = undefined;
        this.delayTriggerQueryComplete = setTimeout(function () {
            if (that.delayTriggerQueryComplete) {
                clearTimeout(that.delayTriggerQueryComplete);
            }
            that.delayTriggerQueryComplete = undefined;
            that.onQueryComplete.emit(that.value);
            that.setDivTimer();
        }, 1000);
        if (!this.visible) {
            this.visible = true;            
        }
        this.setDivTimer();
    }
    isActive(index: number) {
        if (this.activeIndex === index + 1) {
            return true;
        } else {
            return false;
        }
    }
    onSelectClick(child: any) {
        try {
            if (child && this.optionLabel) {
                this.value = child[this.optionLabel];
            }
            this.onChange.emit(this.value);
            if (child) {
                if (this.valueField) {
                    this.onSelected.emit(new LazyloadAutoCompleteResult({ SelectedCode: child[this.valueField], SelectedName: this.value, SelectedNode: child }));
                } else {
                    this.onSelected.emit(new LazyloadAutoCompleteResult({ SelectedCode: child, SelectedName: this.value, SelectedNode: child }));
                }
            }
            this.visible = false;
        } catch (ex) {
            console.error(ex);
        }
    }
    onValueChanged(event: Event) {
        this.onChange.emit(this.value);
    }
    onKeyUp(event: any) {
        if (event["keyCode"] === 13 || event["keyCode"] === 27 || event["keyCode"] === 33 || event["keyCode"] === 34 || event["keyCode"] === 38 || event["keyCode"] === 40) {
            this.keyboardNavigation(event);
        } else {
            let that = this;
            this.delayTriggerQueryComplete = setTimeout(function () {
                if (that.delayTriggerQueryComplete) {
                    clearTimeout(that.delayTriggerQueryComplete);
                }
                that.delayTriggerQueryComplete = undefined;
                that.onQueryComplete.emit(that.value);
            }, 500);
            if (!this.visible) {
                this.visible = true;
            }
            this.onChange.emit(this.value);
            this.onSelected.emit(new LazyloadAutoCompleteResult({ SelectedCode: null, SelectedName: this.value, SelectedNode: null }));
        }
    }
    keyboardNavigation(event: any) {
        if (event["keyCode"] === 13) {
            // Enter
            event.preventDefault();
            if (this.activeIndex > 0 && this.suggestions.length && this.activeIndex <= this.suggestions.length) {
                this.onSelectClick(this.suggestions[this.activeIndex - 1]);
            }
        } else if (event["keyCode"] === 27) {
            // Escape
            event.preventDefault();
            this.activeIndex = 0;
            this.visible = false;
        } else if (event["keyCode"] === 33) {
            // PageUp
            event.preventDefault();
            if (this.activeIndex <= 1) {
                if (this.suggestions.length) {
                    this.activeIndex = this.suggestions.length;
                } else {
                    this.activeIndex = 0;
                }
            } else {
                if (this.suggestions.length) {
                    if (this.activeIndex <= 5 && this.activeIndex !== 1) {
                        this.activeIndex = 1;
                    } else {
                        if (this.activeIndex === 1) {
                            if (this.suggestions.length > 5) {
                                this.activeIndex = this.suggestions.length - 4;
                            } else {
                                this.activeIndex = 1;
                            }
                        } else {
                            this.activeIndex -= 4;
                        }
                    }
                } else {
                    this.activeIndex = 0;
                }
            }
            let element = document.getElementById("lli_" + this.liKey + "_" + (this.activeIndex - 1).toString());
            if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else if (event["keyCode"] === 34) {
            // PageDown
            event.preventDefault();
            if (this.activeIndex >= this.suggestions.length) {
                if (this.suggestions.length) {
                    this.activeIndex = 1;
                } else {
                    this.activeIndex = 0;
                }
            } else {
                if (this.suggestions.length) {
                    if (this.activeIndex === 0 && this.suggestions.length >= 5) {
                        this.activeIndex = 5;
                    } else {
                        this.activeIndex += 4;
                    }
                    if (this.activeIndex > this.suggestions.length) {
                        this.activeIndex = 1;
                    }
                } else {
                    this.activeIndex = 0;
                }
            }
            let element = document.getElementById("lli_" + this.liKey + "_" + (this.activeIndex - 1).toString());
            if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else if (event["keyCode"] === 38) {
            // ArrowUp
            event.preventDefault();
            if (this.activeIndex <= 1) {
                if (this.suggestions.length) {
                    this.activeIndex = this.suggestions.length;
                } else {
                    this.activeIndex = 0;
                }
            } else {
                if (this.suggestions.length) {
                    this.activeIndex -= 1;
                } else {
                    this.activeIndex = 0;
                }
            }
            let element = document.getElementById("lli_" + this.liKey + "_" + (this.activeIndex - 1).toString());
            if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            var input:any = document.getElementById("iptInputValue" + this.liKey);
            if (input) {
                this.setCaretPosition(input, input["value"].length);
            }
        } else if (event["keyCode"] === 40) {
            // ArrowDown
            event.preventDefault();
            if (this.activeIndex >= this.suggestions.length) {
                if (this.suggestions.length) {
                    this.activeIndex = 1;
                } else {
                    this.activeIndex = 0;
                }
            } else {
                if (this.suggestions.length) {
                    this.activeIndex += 1;
                } else {
                    this.activeIndex = 0;
                }
            }
            let element = document.getElementById("lli_" + this.liKey + "_" + (this.activeIndex - 1).toString());
            if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }
    setCaretPosition(ctrl:any, pos:any) {
        // Modern browsers
        if (ctrl.setSelectionRange) {
            ctrl.focus();
            ctrl.setSelectionRange(pos, pos);

            // IE8 and below
        } else if (ctrl.createTextRange) {
            var range = ctrl.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    }
}
export class LazyloadAutoCompleteResult extends BaseDto {
    SelectedCode: string | undefined;
    SelectedName: string | undefined;
    SelectedNode: any;
}