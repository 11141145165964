import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { Globals } from './globals';
import { AuthModule } from './features/auth-module/auth.module';
import { GovFooterCmpnt, GovGetInvolvedPage, GovHeaderCmpnt, GovResourcesPage, GovUpdatesLogPage, GovWhyItsImportantPage, HomeMainPage, HomePage, HomePreviewPage, HomePriorityAreasPage, HomeProgramListCmpnt, HomeProgramListFilterCmpnt, HomeProgramListFilterDialog, HomePublishedPage, HomeResourceListCmpnt, HomeSubmissionListCmpnt, HomeSubmissionListFilterCmpnt, HomeSubmissionListFilterDialog, HomeUpdateLogListCmpnt, HomeUpdateLogListFilterCmpnt, HomeUpdateLogListFilterDialog, ARLAboutPage, ConsrYourChoiceMattersPage, BisARLForBusinessPage, BisHowToJoinPage, ARLFooterCmpnt, ARLHeaderCmpnt, } from './features/home-module/component';
import { HomeService } from './features/home-module/service/home.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppContextService } from './core/service';
import { CarouselModule } from 'primeng/carousel';

@NgModule({
  declarations: [
    AppComponent,
    HomePage, HomeMainPage, HomePreviewPage, HomePublishedPage,
    HomePriorityAreasPage,
    HomeProgramListCmpnt, HomeProgramListFilterCmpnt, HomeProgramListFilterDialog,
    HomeSubmissionListCmpnt, HomeSubmissionListFilterDialog, HomeSubmissionListFilterCmpnt,
    HomeUpdateLogListCmpnt, HomeUpdateLogListFilterDialog, HomeUpdateLogListFilterCmpnt, HomeResourceListCmpnt,
    GovHeaderCmpnt, GovFooterCmpnt, GovGetInvolvedPage, GovResourcesPage, GovUpdatesLogPage, GovWhyItsImportantPage,
    ARLAboutPage, ConsrYourChoiceMattersPage, BisARLForBusinessPage, BisHowToJoinPage, ARLFooterCmpnt, ARLHeaderCmpnt,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    CoreModule,
    AppRoutingModule,
    AuthModule,
    CarouselModule
    //RouterModule.forRoot([]),    
  ],
  providers: [
    Globals, CookieService, HomeService, ConfirmationService, MessageService, AppContextService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
