import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from '@angular/forms';
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
import { Globals } from "../../../../globals";
@Component({
    selector: "auth-signin-dialog",
    templateUrl: "./signin-dialog.component.html"
})
export class AuthSignInDialog extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    @Input() visible: boolean = false;
    @Output() onSignInDialogReturn = new EventEmitter<boolean>();
    @Output() onSignInDialogCancel = new EventEmitter<boolean>();

    constructor(public override globals: Globals, appContext: AppContextService) {        
        super(appContext);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
        } catch (ex) {
            console.error(ex);
        }
    }
    onSignInReturnEvent(event: boolean) {
        this.onSignInDialogReturn.emit(true);
    }    
    onSignInCancelEvent(event: boolean) {
        this.onSignInDialogCancel.emit(true);
    }
}