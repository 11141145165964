<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;" [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
    <div class="flex-wrap sticky-top bgc-white"><gov-header class="flex-wrap"></gov-header></div>
    <div *ngIf="isError==false" class="flex-wrap">
        <core-display-html-content class="flex-wrap" [htmlContent]="item.PublishedContent"></core-display-html-content>
    </div>   
    <div *ngIf="isError==true" class="flex-wrap bgc-white">
        <div class="container pt-4 pb-4">
            <p class="mt-4 mb-4">
                The page you are looking for is not available. Please check to ensure you have the correct URL link.
            </p>
        </div>
    </div>
    <div class="flex-wrap"><gov-footer class="flex-wrap"></gov-footer></div>
</div>