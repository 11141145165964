import { BaseDto } from "../baseDto"
/* DbResourceVersionBaseDto generated at 2024-05-26 13:54:05.229 +10:00*/
export class DbResourceVersionBaseDto extends BaseDto {
	DbResourceVersionId?: string;
	SystemResourceId?: string;
	ResourceDbType?: string;
	Version?: number;
	ApplyDate?: Date;
	DateCompleted?: Date;
	UpgradeDbScript?: string;
	StatusCode?: string;
}
