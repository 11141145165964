<div *ngIf="showComponent" class="flex-wrap" style="width:calc(100%);height:calc(100%);overflow:auto;">
    <p-dataView #dv1 [value]="data" [rows]="fetchRows" [loading]="loading" [lazy]="true" [paginator]="true"
        [totalRecords]="totalRecords" filterBy="filterBy" (onLazyLoad)="loadData($event)">
        <ng-template let-item let-index="rowIndex" pTemplate="listItem">
            <div class="flex-wrap bgc-white brad2 mb-2 p5 b1">
                <div class="flex-wrap">
                    <!-- <div [ngClass]="{'w20':IsMobileView,'flex-fill':!IsMobileView}" (click)="setVisible(item)"> -->
                    <div [ngClass]="{'w12':IsMobileView,'w20':!IsMobileView}" (click)="setVisible(item)">
                        <div class="p2 font-bold text-multiline fontsizegov">{{item.Topic}}
                        </div>
                    </div>
                    <!-- <div [ngClass]="{'flex-fill':IsMobileView,'column40P':!IsMobileView}"> -->
                    <div class="flex-fill">
                        <div class="w-fit-content">
                            <div class="flex-wrap">
                                <div class="brad2 p2 mr-2 mb-2 w8 bgc-green" style="height:2em;"><label
                                        class="w-fit-content r-centroid font-bold">{{getCurrencyStatus(item)}}</label>
                                </div>
                                <div class="brad2 p2 mr-2 mb-2 w5 bgc-amber" style="height:2em;">
                                    <label class="w-fit-content r-centroid font-bold">{{getRegion(item)}}</label>
                                </div>
                                <div class="flex-fill">
                                    <div *ngIf="item.Material" class="flex-wrap">
                                        <div *ngFor="let mat of getMaterial(item); index as h;"
                                            class="brad2 p2 mr-2 mb-2 w10 bgc-silver" style="height:2em;">
                                            <div class="w-fit-content center font-bold">{{mat}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w2 mt-3">
                        <div (click)="setVisible(item)" class="flex-wrap">&nbsp;<i class="fa-solid font-bold"
                                [ngClass]="{'fa-chevron-up':item.isVisible,'fa-chevron-down':!item.isVisible}"></i>
                        </div>
                    </div>
                </div>
                <div class="flex-wrap mt-2 p2" [ngClass]="{'hidden':!item.isVisible}">
                    <div class="flex-wrap">
                        <div class="font-bold fontsizegov">Description</div>
                    </div>
                    <div class="flex-wrap">
                        <core-display-html-content class="flex-wrap" [htmlContent]="item.ProgramDescription"></core-display-html-content>
                    </div>
                    <!-- <div class="flex-wrap text-multiline">
                        {{item.ProgramDescription}}
                    </div> -->
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>
<home-program-list-filter-dialog class="flex-wrap" *ngIf="isFilterVisible" [visible]="isFilterVisible"
    [materialList]="materialList" [regionList]="regionList" [statusList]="statusList" [lazyLoadArgs]="lazyLoadArgs"
    (onSearch)="onSearchEvent($event)" (onClose)="onCloseEvent($event)">
</home-program-list-filter-dialog>
