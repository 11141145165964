import { BaseDto } from "../baseDto"
/* WARLProgramBaseDto generated at 2024-05-26 13:54:05.425 +10:00*/
export class WARLProgramBaseDto extends BaseDto {
	WebARLProgramId?: string;
	ProgramCode?: string;
	Topic?: string;
	ProgramDescription?: string;
	ProgramStartDate?: Date;
	ProgramEndDate?: Date;
	CommentStartDate?: Date;
	CommentEndDate?: Date;
	Material?: string;
	ProgramRegion?: string;
	ProgramStatus?: string;
	StatusCode?: string;
}
