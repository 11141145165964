import { BaseDto } from "../baseDto"
/* ProductComponentBaseDto generated at 2024-05-26 13:54:05.299 +10:00*/
export class ProductComponentBaseDto extends BaseDto {
	ProductComponentId?: string;
	ParentComponentId?: string;
	SequenceNo?: number;
	ComponentType?: string;
	PartNo?: string;
	ProductCode?: string;
	ProductName?: string;
	ComponentQuantity?: number;
	ProductId?: string;
	ProductComponentGroupId?: string;
	ProjectId?: string;
	ProjectItemGroupId?: string;
	ProjectItemId?: string;
	Org_ProductComponentId?: string;
	Org_ParentComponentId?: string;
	Org_ProductId?: string;
	Org_ProdCompGroupId?: string;
	Org_ProjectId?: string;
	Org_ProjectItemGroupId?: string;
	Org_ProjectItemId?: string;
	Supplier_OrganisationId?: string;
	Supplier_ContactId?: string;
	Supplier_ProductId?: string;
	Supplier_ProductComponentId?: string;
	Supplier_ProjectId?: string;
	ARLM_ProductComponentId?: string;
	ARLM_SupplierOrganisationId?: string;
	ARLM_SupplierProductId?: string;
	ARLM_SupplierProjectId?: string;
	StatusCode?: string;
}
