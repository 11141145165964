<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>


  <div class="flex-wrap bgc-darkturquoise">
    <div class="body-container p2 mt-4 mb-4">
      <div class="flex-wrap">
        <div class="flex-wrap p2">
          <div class="flex-wrap fc-white">
            <h2 class="govheaderfontsize">GOVERNANCE</h2>
          </div>
          <div class="flex-wrap fc-white">
            <h1 class="heading text headinglineheight">Why is governance important?</h1>
          </div>
        </div>
      </div>
    </div>
  </div>






  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-7">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="column2">
            <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">ARL program governance</h2>
            </div>
            <div class="parentfont">
              <p class="fontsize psection">
                APCO is committed to delivering a robust and transparent ARL program which meets the needs
                of all stakeholders across the recycling ecosystem and aligns with international best
                practice.
              </p>
              <p class="fontsize">
                The key principles of the ARL program are to be:
              </p>
              <br />
              <ul class="fontsize">
                <li>Consistent</li>
                <li>Continuously improving</li>
                <li>Evidence-based</li>
                <li>Representative</li>
                <li>Transparent</li>
              </ul>
            </div>
          </div>
          <div class="column2" style="max-width:500px;">
            <img class="flex-wrap" src="images/ARL-Priority-Area-Image.png" alt="ARL Program Governance">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">How the ARL program determines recyclability</h2>
          </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize">
              The ARL program’s definition of ‘recyclable’ or ‘recycled’ aligns with the Sustainable
              Packaging
              Guidelines which requires that:
              <br />
              <br />
            </p>
         
            <div class="flex-wrap parentfont">
              <ul class="fontsize">
                <li class="">
                  In a particular geographical area, at least 80% of the overall
                  population has convenient
                  access to a service that collects the packaging or packaging component, and
                </li>
                <li class="">
                  At least 70% of an item by weight must be able to be recycled into a
                  product, component
                  incorporated into a product, or a secondary (recycled) raw material; excluding
                  energy
                  recovery.
                </li>
              </ul>
            </div>
            <p class="fontsize">
              In order to be considered recyclable through the ARL program, an item must also be able to
              be:
              <br />
              <br />
            </p>
            <div class="flex-wrap parentfont">
              <ul class="fontsize">
                <li class="">
                  Accurately sorted at a MRF
                </li>
                <li class="">
                  Effectively reprocessed into a new product and,
                </li>
                <li class="">
                  Have a valuable, existing end market.
                </li>
              </ul>
            </div>
            <p class="fontsize">
              The thresholds for recyclability of packaging under the ARL program are set per major common
              material type – i.e. rigid plastics, flexible plastics, fibre, metals and glass. For each
              material type, there are additional specific thresholds set based on more detailed
              sub-material
              types – e.g. polymer types. Additional thresholds are set based on factors that impact the
              recyclability of each of the major material types – e.g. size, shape, weight, inks,
              adhesives
              and secondary materials such as labels.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2 bgc-gray">
        <div class="flex-wrap  ">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section psection">ARL committees</h2>
          </div>
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isIRCVisible=!isIRCVisible">
                  <p class="fontsize">Internal Review Committee (IRC)</p>

                </div>
                <div class="w2 mt-3">
                  <div (click)="isIRCVisible=!isIRCVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isIRCVisible,'fa-chevron-down':!isIRCVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isIRCVisible}">
                <p class="mb-3 fontsize">
                  This committee has final decision-making authority over the ARL. It reviews all
                  recommendations from the management team and other governance and advisory
                  committees. It is responsible for making decisions about the ARL program that
                  are aligned to program principles and informed by accurate and well-evidenced
                  information.
                </p>
              </div>
            </div>
          </div>
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isMSCVisible=!isMSCVisible">
                  <p class="fontsize">Material Stewardship Committees (MSCs)</p>

                </div>
                <div class="w2 mt-3">
                  <div (click)="isMSCVisible=!isMSCVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isMSCVisible,'fa-chevron-down':!isMSCVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isMSCVisible}">
                <p class="mb-3 fontsize">
                  The ARL has six <a class="fc-blue"
                                     href="https://apco.org.au/apco-material-stewardship-committees">
                    Material
                    Stewardship Committees
                  </a> (flexible plastic, rigid plastic, fibre, metal,
                  glass and labels) providing technical advice on packaging chemistry, composition
                  and recovery. These specialised sub-committees include representation from
                  across the packaging value chain. They review stakeholder feedback and provide
                  recommendations to the ARL program on submissions and proposed thresholds. Their
                  advice is considered by the IRC in decision making.
                </p>
              </div>
            </div>
          </div>
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isNZASCVisible=!isNZASCVisible">
                  <p class="fontsize">New Zealand ARL Steering Committee (NZASC)</p>

                </div>
                <div class="w2 mt-3">
                  <div (click)="isNZASCVisible=!isNZASCVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isNZASCVisible,'fa-chevron-down':!isNZASCVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isNZASCVisible}">
                <p class="mb-3 fontsize">
                  The New Zealand ASC provides recommendations to the IRC, using their expertise
                  in technical matters, the market, and consumer behaviours that are specific to
                  New Zealand.
                </p>
              </div>
            </div>
          </div>
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isIRPVisible=!isIRPVisible">
                  <p class="fontsize">Independent Review Panel (IRP)</p>

                </div>
                <div class="w2 mt-3">
                  <div (click)="isIRPVisible=!isIRPVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isIRPVisible,'fa-chevron-down':!isIRPVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isIRPVisible}">
                <p class="mb-3 fontsize">
                  After final decision by IRC, if a conflict arises, an Independent Review Panel
                  (IRP) can be called upon. The IRP is managed by an external consultancy to
                  ensure that a fair and transparent process has been undertaken to determine the
                  IRC ruling.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8 mb-8">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">How do we maintain ARL compliance and integrity?</h2>
          </div>
        </div>
        <div class="flex-wrap parentfont">
          <p class="fontsize psection">
            The correct use of the ARL on-pack underpins the program’s credibility and integrity. APCO
            maintain and monitor ARL compliance by:
          </p>
          <br />
          <br />
          <div class="flex-wrap parentfont">
            <ul class="fontsize">
              <li class="">
                Auditing ARL program Members annually through an independent third-party
                auditor to assess
                applications of the label on the market for consistency and accuracy.
              </li>
              <li class="">
                Requesting Members to complete annual reporting to track the volume of labels
                on the market.
              </li>
              <li class="">
                Inviting and addressing complaints or feedback concerning the program. A formal
                complaints
                process exists to support timely and fair resolution of complaints or non-compliances.
              </li>
              <li class="">
                Undertaking market surveillance including bin and shelf-audits to identify
                non-compliance in
                the market.
              </li>
              <li class="">
                Providing training tools and resources to support program Members, including
                online learning
                modules, user manuals and webinars.
              </li>
            </ul>
          </div>
        </div>
        </div>
    </div>
  </div>
  <div class="flex-wrap">
    <gov-footer [pagePath]="pagePath" class="flex-wrap"></gov-footer>
  </div>
</div>
