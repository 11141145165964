<div *ngIf="showComponent" class="flex-wrap grid-border-round" style="height:calc(100%);">
    <p-dataView #dv1 id="{{name}}" [value]="data" [rows]="fetchRows" [loading]="loading" [lazy]="true"
        [paginator]="true" [totalRecords]="totalRecords" filterBy="filterBy" (onLazyLoad)="loadData($event)">
        <ng-template pTemplate="header">
            <div *ngIf="!IsMobileView" class="flex-wrap brad-tl4 brad-tr4 pt-3 pb-3" [ngClass]="{'hidden':IsMobileView}"
                style="background-color:#EBF2F8;">
                <div class="column15P">
                    <div class="center w-fit-content font-bold fontsizegov">Stakeholders</div>
                </div>
                <div class="flex-fill font-bold fontsizegov">Description, Material and Update Category</div>
                <div class="column15P">
                    <div class="center w-fit-content font-bold fontsizegov">Status</div>
                </div>
                <div class="column15P">
                    <div class="center w-fit-content font-bold fontsizegov">Date approved</div>
                </div>
                <div class="column15P">
                    <div class="center w-fit-content font-bold fontsizegov">Date of Expiry</div>
                </div>
                <div class="column15P">
                    <div class="center w-fit-content font-bold fontsizegov">Comment Period</div>
                </div>
                <div *ngIf="!isHistorical" class="column15P">
                    <div class="center w-fit-content font-bold fontsizegov">Comments</div>
                </div>
            </div>
        </ng-template>
        <ng-template let-item let-index="rowIndex" pTemplate="listItem">
            <div *ngIf="!IsMobileView" class="flex-wrap bb-1 pt-3 pb-3 bgc-white"
                style="border-color:#E5E5E5!important;">
                <div class="flex-wrap">
                    <div class="column15P pl-3 pr-2">
                        <div class="text-multiline fontsizegov">{{item.Stakeholder}}</div>
                    </div>
                    <div class="flex-fill">
                        <div class="flex-wrap">
                            <div class="flex-fill text-multiline fontsizegov" (click)="setVisible(item)">
                                {{item.SubmissionDescription}}
                            </div>
                            <div class="w2 mt-3">
                                <div (click)="setVisible(item)" class="flex-wrap">&nbsp;<i class="fa-solid"
                                        [ngClass]="{'fa-chevron-up':item.isVisible,'fa-chevron-down':!item.isVisible}"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column15P">
                        <div class="center w-fit-content font-bold text-center fontsizegov"
                            [ngClass]="{'fc-dark-turquoise':isNgClassCyan(item),'fc-dark-brown':isNgClassBrown(item)}">
                            {{item.SubmissionStatus}}</div>
                    </div>
                    <div class="column15P">
                        <div class="center w-fit-content text-center fontsizegov">{{item.DisplayDateApprovedText}}</div>
                    </div>
                    <div class="column15P">
                        <div class="center w-fit-content text-center fontsizegov">{{item.DateExpiry | date :
                            'dd/MM/yy'}}</div>
                    </div>
                    <div class="column15P">
                        <div class="center w-fit-content text-center fontsizegov">{{item.CommentStartDate | date :
                            'dd/MM/yy'}}
                            -
                            {{item.CommentEndDate | date : 'dd/MM/yy'}}</div>
                    </div>
                    <div *ngIf="!isHistorical" class="column15P">
                        <div class="center w-fit-content">
                            &nbsp;
                            <div *ngIf="item.FormURL" class="center">
                                <div class="brad2 bgc-yellow big-btn m2 p2">
                                    <div class="r-centroid w-fit-content font-bold"><a class="fc-black" target="_blank" rel="noopener noreferrer"
                                        [href]="item.FormURL">Feedback Form</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-wrap mt-4 mb-4" [ngClass]="{'hidden':!item.isVisible}">
                    <div class="column15P pl-3 pr-2">&nbsp;</div>
                    <div class="flex-fill">
                        <div class="flex-wrap pl-2 pr-2">
                            <div class="flex-wrap mt-4">
                                <div class="flex-wrap">
                                    <div class="font-bold fontsizegov">Material | Region</div>
                                </div>
                                <div class="flex-wrap fontsizegov">
                                    <div>{{item.SubmissionMaterial}} | {{item.SubmissionRegion}}</div>
                                </div>
                            </div>
                            <div class="flex-wrap mt-4">
                                <div class="flex-wrap">
                                    <div class="font-bold fontsizegov">Update Category</div>
                                </div>
                                <div class="flex-wrap fontsizegov">
                                    <div>{{item.SubmissionType}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column60P">
                        <div class="flex-wrap mt-4 pl-2 pr-4">
                            <div class="flex-wrap">
                                <div class="font-bold fontsizegov">Description</div>
                            </div>
                            <div class="flex-wrap">
                                <core-display-html-content class="flex-wrap"
                                    [htmlContent]="item.SubmissionLongDesc"></core-display-html-content>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="IsMobileView" class="flex-wrap bb-1 bgc-white mb-4" style="border-color:#E5E5E5!important;">
                <div class="flex-wrap bgc-lightblue bb-1">
                    <div class="w8 pt-3 pb-3 pl-2 fontsizegov" style="min-height:2em;">Stakeholders</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 text-multiline fontsizegov bgc-white" style="min-height:4em;">{{item.Stakeholder}}</div>
                </div>
                <div class="flex-wrap bb-1 bgc-lightblue">
                    <div class="w8 pt-3 pb-3 pl-2 fontsizegov" style="min-height:6em;">Description, Material and Update Category</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 bgc-white" style="min-height:9em;">
                        <div class="flex-wrap">
                            <div class="flex-fill text-multiline fontsizegov" (click)="setVisible(item)">
                                {{item.SubmissionDescription}}</div>
                            <div class="w2 mt-3">
                                <div (click)="setVisible(item)" class="flex-wrap">&nbsp;<i class="fa-solid"
                                        [ngClass]="{'fa-chevron-up':item.isVisible,'fa-chevron-down':!item.isVisible}"></i>
                                </div>
                            </div>
                        </div>
                        <div class="flex-wrap bgc-white" [ngClass]="{'hidden':!item.isVisible}">
                            <div class="flex-wrap mt-4">
                                <div class="flex-wrap">
                                    <div class="font-bold fontsizegov">Material | Region</div>
                                </div>
                                <div class="flex-wrap fontsizegov">
                                    <div>{{item.SubmissionMaterial}} | {{item.SubmissionRegion}}</div>
                                </div>
                            </div>
                            <div class="flex-wrap mt-4 fontsizegov">
                                <div class="flex-wrap">
                                    <div class="font-bold">Update Category</div>
                                </div>
                                <div class="flex-wrap">
                                    <div>{{item.SubmissionType}}</div>
                                </div>
                            </div>
                            <div class="flex-wrap mt-4 fontsizegov">
                                <div class="flex-wrap">
                                    <div class="font-bold">Description</div>
                                </div>
                                <div class="flex-wrap">
                                    <core-display-html-content class="flex-wrap"
                                        [htmlContent]="item.SubmissionLongDesc"></core-display-html-content>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-wrap bgc-lightblue bb-1">
                    <div class="w8 pt-3 pb-3 pl-2 fontsizegov" style="min-height:5em;">Status</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 font-bold bgc-white fontsizegov" style="min-height:5em;"
                        [ngClass]="{'fc-dark-turquoise':isNgClassCyan(item),'fc-dark-brown':isNgClassBrown(item)}">
                        {{item.SubmissionStatus}}</div>
                </div>
                <div class="flex-wrap bb-1 fontsizegov">
                    <div class="w8 bgc-lightblue pt-3 pb-3 pl-2">Date approved</div>
                    <div class="flex-fill pt-3 pb-3 pl-2">{{item.DisplayDateApprovedText}}</div>
                </div>
                <div class="flex-wrap bb-1 fontsizegov">
                    <div class="w8 bgc-lightblue pt-3 pb-3 pl-2">Date of Expiry</div>
                    <div class="flex-fill pt-3 pb-3 pl-2">{{item.DateExpiry | date : 'dd/MM/yy'}}</div>
                </div>
                <div class="flex-wrap bb-1 fontsizegov">
                    <div class="w8 bgc-lightblue pt-3 pb-3 pl-2">Comment Period</div>
                    <div class="flex-fill pt-3 pb-3 pl-2">{{item.CommentStartDate | date : 'dd/MM/yy'}} -
                        {{item.CommentEndDate | date : 'dd/MM/yy'}}</div>
                </div>
                <div *ngIf="!isHistorical" class="flex-wrap bb-1 fontsizegov" style="height:4em;">
                    <div class="w8 bgc-lightblue pt-3 pb-3 pl-2" style="height:4em;">Comment</div>
                    <div class="flex-fill pb-3 pl-2">
                        <div class="center">
                            <div *ngIf="item.FormURL" class="center">
                                <div class="brad2 bgc-yellow big-btn m2 p2 w9">
                                    <div class="r-centroid w-fit-content font-bold"><a class="fc-black" target="_blank" rel="noopener noreferrer"
                                        [href]="item.FormURL">Feedback Form</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>
<home-submission-list-filter-dialog class="flex-wrap" *ngIf="isFilterVisible" [visible]="isFilterVisible"
    [materialList]="materialList" [regionList]="regionList" [lazyLoadArgs]="lazyLoadArgs"
    (onSearch)="onSearchEvent($event)" (onClose)="onCloseEvent($event)">
</home-submission-list-filter-dialog>
