import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { ConfigService, LocatorService } from "./../service";
import { Globals } from "./../../globals";
import { AppContextService } from "./../service";
import { AuthenticationService } from "src/app/features/auth-module";
import { MenuItem, MenuItemCommandEvent } from "primeng/api";
import { Constants } from "../model/constants/constants";
import { Guid } from "../model/shared";

@Injectable()
export abstract class BaseComponent {
  protected destroyed$: Subject<void> = new Subject<void>();
  globals: Globals = LocatorService.injector.get(Globals);
  public windowSizeChangedSubscription: Subscription | undefined;
  public windowResizedSubscription: Subscription | undefined;
  public orientationChangedSubscription: Subscription | undefined;
  public windowMouseClickSubscription: Subscription | undefined;
  public authChangedSubscription: Subscription | undefined;
  public navigationSubscription: Subscription | undefined;
  public formStatusChangeSubscription: Subscription | undefined;
  public receivedMessageSubscription: Subscription | undefined;

  // Used to show the sign in dialog
  public isShowSigninDialog: boolean = false;

  public loadCompleted = new BehaviorSubject<boolean>(false);
  public loadError = new BehaviorSubject<any>(null);
  public loadCompletedSubscription: Subscription | undefined;

  public CMId = Guid.newGuid();
  public delaySendMsgTimer: any;

  public delayLoadInitTimer: any;
  public delayGetMenuTimer: any;
  public delaySetMenuTimer: any;
  public delaySetDivTimer: any;
  public showComponent: boolean = false;
  public showMenuItems: boolean = false;
  browserMenuItems: MenuItem[] = [];
  mobileMenuItems: MenuItem[] = [];
  sideMenuItems: MenuItem[] = [];
  cmpntMenuItems: MenuItem[] = [];
  browserMenu: MenuItem[] = [];
  mobileMenu: MenuItem[] = [];
  sideMenu: MenuItem[] = [];
  cmpntMenu: MenuItem[] = [];
  previousMenu: string = "";
  public IsSideMenuView: boolean = false;
  public IsTopMenuView: boolean = false;
  public showClose: boolean = false;
  private queryParams: any;
  private authenticationService: AuthenticationService;
  private configService: ConfigService;
  constructor(public appCtx: AppContextService) {
    this.queryParams = {
      queryParams: this.appCtx.route.snapshot.queryParams,
      queryParamMap: this.appCtx.route.snapshot.queryParamMap,
      fragment: this.appCtx.route.snapshot.fragment
    };
    this.authenticationService = new AuthenticationService(this.appCtx);
    this.configService = new ConfigService(this.appCtx);
  }
  get authSvc() {
    return this.authenticationService;
  }
  get cfgSvc() {
    return this.configService;
  }
  get sharedSvc() {
    return this.appCtx.sharedSvc;
  }
  get confirmSvc() {
    return this.appCtx.confirmSvc;
  }
  get utilSvc() {
    return this.appCtx.utilSvc;
  }
  get IsMobileView() {
    return this.appCtx.IsMobileView;
  }
  get IsPortraitView() {
    return this.appCtx.IsPortraitView;
  }
  get CurrentYear() {
    return new Date().getFullYear();
  }
  get isSA() {
    return this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.SystemAdministrator);
  }
  get isAdmin() {
    return this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.Administrator);
  }
  get isSupportUser() {
    return this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.SupportUser);
  }
  get isUser() {
    return this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.User);
  }
  get baseURL() {
    let port = window.location.port;
    let url = window.location.protocol + "//" + window.location.hostname;
    if (port) {
      url = window.location.protocol + "//" + window.location.hostname + ":" + port;
    }
    return url;
  }
  onDestroy(): void {
    try {
      this.destroyed$.next(undefined);
      this.destroyed$.complete();
    } catch (ex) {
      console.error(ex);
    }
  }
  destroySubscriptions() {
    if (this.authChangedSubscription) {
      this.authChangedSubscription.unsubscribe();
    }
    if (this.windowSizeChangedSubscription) {
      this.windowSizeChangedSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.formStatusChangeSubscription) {
      this.formStatusChangeSubscription.unsubscribe();
    }
    if (this.windowResizedSubscription) {
      this.windowResizedSubscription.unsubscribe();
    }
    if (this.orientationChangedSubscription) {
      this.orientationChangedSubscription.unsubscribe();
    }
    if (this.windowMouseClickSubscription) {
      this.windowMouseClickSubscription.unsubscribe();
    }
    if (this.receivedMessageSubscription) {
      this.receivedMessageSubscription.unsubscribe();
    }
  }
  getQueryParams(key: string) {
    if (this.queryParams) {
      if (this.queryParams[key]) {
        return this.queryParams[key];
      } else {
        if (this.queryParams.queryParams) {
          if (this.queryParams.queryParams[key]) {
            return this.queryParams.queryParams[key];
          }
        }
        if (this.queryParams.queryParamMap) {
          if (this.queryParams.queryParamMap[key]) {
            return this.queryParams.queryParamMap[key];
          }
        }
        if (this.queryParams.fragment) {
          let fragments = this.queryParams.fragment.split("&");
          if (fragments && fragments.length && !this.queryParams.fragments) {
            this.queryParams.fragments = {};
            fragments.forEach((x: any) => {
              let subFragment = x.split("=");
              if (subFragment && subFragment.length) {
                let theKey = "";
                let theValue = "";
                for (var i = 0; i < subFragment.length; i++) {
                  if (i === 0) {
                    theKey = subFragment[i];
                  } else {
                    if (theValue) {
                      theValue = theValue + "=" + subFragment[i];
                    } else {
                      theValue = subFragment[i];
                    }
                  }
                }
                this.queryParams.fragments[theKey] = theValue;
              }
            });
          }
          if (this.queryParams.fragments) {
            if (this.queryParams.fragments[key]) {
              return this.queryParams.fragments[key];
            }
          }
        }
      }
    }
    return null;
  }
  isCurrentPage(currentPagePath: string) {
    return this.appCtx.isCurrentPage(currentPagePath);
  }
  gotoHomePage() {
    this.sharedSvc.navigate(["/"]);
  }
  getRandomStart(range: number): number {
    let numberRange = 100 * range;
    return this.utilSvc.getRandomInt(numberRange);
  }
  checkAuthentications(componentName: string = "") {
    return new Promise((resolve, reject) => {
      try {
        this.appCtx.apiSvc.checkAuthenticated(componentName).subscribe(authenticatedUser => {
          if (authenticatedUser) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } catch (e) {
        resolve(false);
      }
    });
  }
  onClickSignin(event: Event) {
    try {
      this.isShowSigninDialog = true;
      //this.ReturnUri = location.href;
      //this.sharedSvc.navigateByUrl("/signin?return=" + encodeURIComponent(this.replaceHref(location.href)));
    } catch (ex) {
      console.error(ex);
    }
  }
  onSignInDialogReturnEvent(value: boolean) {
    try {
      this.isShowSigninDialog = false;
      let that = this;
      this.delayLoadInitTimer = setTimeout(function () {
        if (that.delayLoadInitTimer) {
          clearTimeout(that.delayLoadInitTimer);
        }
        that.delayLoadInitTimer = undefined;
        that.appCtx.navigateOnAuthenticated();
      }, 100);

    } catch (ex) {
      console.error(ex);
    }
  }
  onSignInDialogCancelEvent(value: boolean) {
    try {
      this.isShowSigninDialog = false;
    } catch (ex) {
      console.error(ex);
    }
  }
  onClickSignout() {
    if (this.appCtx.Authenticated) {
      this.signOut();
    } else {
      this.appCtx.credentialCleanUp();
      this.gotoHomePage();
    }
  }
  public signOut() {
    try {
      this.appCtx.apiSvc.signOut().subscribe((signoutResult: any) => {
        this.appCtx.credentialCleanUp();
        this.appCtx.navigateOnLogout();
      }, (error: any) => {
        console.error(error);
        this.appCtx.credentialCleanUp();
        this.gotoHomePage();
      });
    } catch (ex) {
      console.error(ex);
      this.appCtx.credentialCleanUp();
      this.gotoHomePage();
    }
  }
  public getARLHomePageMenu(): MenuItem[] {
    return [
      {
        label: "About ARL",
        routerLink: "/about",
        replaceUrl: true
      }, {
        label: "Consumer",
        items: [{
          label: "Your actions matter",
          routerLink: "/your-actions-matter",
          replaceUrl: true
        }, {
          label: "Your recycling guide",
          routerLink: "/",
          replaceUrl: true
        }]
      }, {
        label: "Business",
        items: [{
          label: "ARL for business",
          routerLink: "/arl-for-business",
          replaceUrl: true
        }, {
          label: "Join the ARL",
          routerLink: "/how-to-join",
          replaceUrl: true
        }
          , {
          label: "ARL Marketplace",
          url: "https://www.arlmarketplace.org.au/",
          replaceUrl: true
        }
          , {
          label: "Governance",
          items: [{
            label: "Why it's important",
            routerLink: "/why-its-important",
            replaceUrl: true
          }, {
            label: "Get involved",
            routerLink: "/get-involved",
            replaceUrl: true
          }, {
            label: "Update logs",
            routerLink: "/update-logs",
            replaceUrl: true
          }, {
            label: "Resources",
            routerLink: "/resources",
            replaceUrl: true
          }]
        }
        ]
      }
    ];
  }
  public getConfigurationPageMenu() {
    if (this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.SystemAdministrator)) {
      return [
        {
          label: "Test ANZPAC Membership Form",
          url: this.baseURL + "/previewpage/anzpacmembership?flowName=SF_ANZPAC_Membership_Form",
          replaceUrl: true
        },
        { label: "Home", routerLink: "/admin/configure/home", replaceUrl: true, visible: (this.isSA || this.isAdmin) },
        { label: "User", routerLink: "/admin/configure/user", replaceUrl: true, visible: (this.isSA || this.isAdmin) },
        { label: "Organisation", routerLink: "/admin/configure/organisation", replaceUrl: true, visible: (this.isSA || this.isAdmin) },
        { label: "Domain Value", routerLink: "/admin/configure/domain", replaceUrl: true, visible: (this.isSA || this.isAdmin) },
        { label: "Page Content", routerLink: "/admin/configure/pagecontent", replaceUrl: true, visible: (this.isSA || this.isAdmin) },
        { label: "System Resources", routerLink: "/admin/configure/sysresource", replaceUrl: true, visible: (this.isSA || this.isAdmin) },
        { label: "ARL Program", routerLink: "/admin/configure/arlprogram", replaceUrl: true, visible: (this.isSA || this.isAdmin || this.isSupportUser || this.isUser) },
        { label: "ARL Submission", routerLink: "/admin/configure/arlsubmission", replaceUrl: true, visible: (this.isSA || this.isAdmin || this.isSupportUser || this.isUser) },
        { label: "PREP Update Log", routerLink: "/admin/configure/arlupdatelog", replaceUrl: true, visible: (this.isSA || this.isAdmin || this.isSupportUser || this.isUser) },
        { label: "ARL Resources", routerLink: "/admin/configure/arlresource", replaceUrl: true, visible: (this.isSA || this.isAdmin || this.isSupportUser || this.isUser) }

      ];
    } else {
      return [];
    }
  }
}
