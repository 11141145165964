import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Observable, combineLatest, map } from "rxjs";
import { AppContextService } from "../../../../core/service";
import { DataResult, ColumnFilter, LazyLoadArgs } from "../../../../core/model/shared";
import { DataView } from "primeng/dataview";
import { DataViewBaseComponent } from "../../../../core/component/data-view.base.component";
import { HomeService } from "../../service/home.service";
import { Constants, DomainValueDto, WARLProgramDto } from "../../../../core/model/feature/apcomodel";

@Component({
    selector: "home-program-list",
    templateUrl: "./program-list.component.html"
})
export class HomeProgramListCmpnt extends DataViewBaseComponent<WARLProgramDto> implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    viewTitle: string = "";
    pagePath: string = "/";
    @ViewChild("dv1") dataview: DataView | undefined;
    materialList: DomainValueDto[] = [];
    regionList: DomainValueDto[] = [];
    statusList: DomainValueDto[] = [];
    @Input() name: string = "";
    @Input() isFromAdmin: boolean = false;
    @Input() isFilterVisible: boolean = false;
    @Output() isFilterVisibleChange = new EventEmitter<boolean>();
    constructor(
        appCtx: AppContextService,
        private svc: HomeService
    ) {
        super(appCtx);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.componentName = this.name;
            this.loadConfigData();
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    loadConfigData() {
        try {
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    setConfig() {
        try {
            this.fetchRows = 5;
            this.defaultSortField = "ProgramStartDate";
            this.defaultSortDesc = true;
            this.filterBy = "ProgramCode,Topic";
            this.sortCols = [{ value: "ProgramCode", label: "Code" },
            { value: "Topic", label: "Topic" },
            { value: "ProgramStartDate", label: "Start Date" }];

            let tfilter = new ColumnFilter("Status", "Status", "textValues", false);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            tfilter = new ColumnFilter("Material", "Material", "textValues", false);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            tfilter = new ColumnFilter("ProgramRegion", "Region", "textValues", true);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            this.setFilters(this.filterConfigs);

            this.regionList = [];
            this.regionList.push(new DomainValueDto({ CodeValue: "AUS", NameValue: "Australia" }));
            this.regionList.push(new DomainValueDto({ CodeValue: "NZ", NameValue: "New Zealand" }));

        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.getInitData().subscribe(data => {
                this.materialList = JSON.parse(JSON.stringify(data.programMaterialTypes)).filter((x: DomainValueDto) => x.CodeValue != Constants.WProgramMaterialType.Allmaterials);
                this.statusList = JSON.parse(JSON.stringify(data.programStatuses)).filter((x: DomainValueDto) => x.CodeValue != Constants.WProgramStatus.Approved);
                this.initPage();
            }, error => {
                this.initPage();
            });
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;

            that.loadCompletedSubscription = that.loadCompleted.subscribe(result => {
                if (that.dataview && that.dataview.first !== that.lazyLoadArgs.first) {
                    that.dataview.paginate({ first: that.lazyLoadArgs.first, rows: that.lazyLoadArgs.rows });
                }
            });

            that.navigationSubscription = that.appCtx.onNavigationEnd().subscribe((e: any) => {
                if (that.showComponent && that.isCurrentPage(that.pagePath)) {
                    that.getMenu();
                    that.setMenu();
                }
            });
            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe(data => {
                    that.setViewMode();
                });
            }
            that.setMenu();
            that.setViewMode();
        }, 1000);
    }
    setMenu() {
        try {
            this.showMenuItems = false;
            let that = this;
            this.delaySetMenuTimer = setTimeout(function () {
                if (that.delaySetMenuTimer) {
                    clearTimeout(that.delaySetMenuTimer);
                }
                that.delaySetMenuTimer = undefined;
                that.browserMenu = that.browserMenuItems;
                that.mobileMenu = that.mobileMenuItems;
                that.showMenuItems = true;
            }, 100);
        } catch (ex) {
            console.error(ex);
        }
    }
    async getMenu() {
        try {
            this.browserMenuItems = [];
            this.mobileMenuItems = [];
        } catch (ex) {
            console.error(ex);
        }
    }
    setViewMode() {

    }
    getInitData(): Observable<any> {
        return combineLatest([
            this.svc.getInitWARLProgramDomain()
        ]).pipe(map(resp => {
            return {
                programMaterialTypes: resp[0].programMaterialTypes.DataList,
                programStatuses: resp[0].programStatuses.DataList
            };
        }));
    }
    getCurrencyStatus(item: WARLProgramDto) {
        if (item.ProgramStatus) {
            return item.ProgramStatus;
        } else {
            if (item.ProgramStartDate && this.utilSvc.dateSecondsDiff(new Date(), item.ProgramStartDate) > 0 && !item.ProgramEndDate) {
                return "Upcoming";
            } else {
                if (item.ProgramStartDate && item.ProgramEndDate) {
                    return "Closed";
                } else {
                    return "In progress";
                }
            }
        }
    }
    getRegion(item: WARLProgramDto) {
        if (item.ProgramRegion) {
            return item.ProgramRegion; //.replace(",", " ");
        } else {
            return "AUS";
        }
    }
    getMaterial(item: WARLProgramDto) {
        if (item.Material) {
            return item.Material.split(",");
        } else {
            return null;
        }
    }
    setVisible(item: WARLProgramDto) {
        if (item.isVisible) {
            item.isVisible = false;
        } else {
            item.isVisible = true;
        }
    }
    lazyLoad(event?: LazyLoadArgs): Observable<DataResult<WARLProgramDto>> {
        return this.svc.getWARLProgramPagedList(event);
    }
    onSearchEvent(filterArg: LazyLoadArgs) {
        this.lazyLoadArgs = filterArg;
        this.loadData()
        this.isFilterVisible = false;
        this.isFilterVisibleChange.emit(this.isFilterVisible);
    }
    onCloseEvent(close: any) {
        this.isFilterVisible = false;
        this.isFilterVisibleChange.emit(this.isFilterVisible);
    }
}