import { Injectable, AfterViewInit, Input, OnInit, OnDestroy } from "@angular/core";
import { InfiniteScrollCustomEvent } from "@ionic/angular";
import { BehaviorSubject, Observable, takeUntil, skip } from "rxjs";
import { debounceTime, catchError } from "rxjs/operators";
//import { Guid } from "../../../platform/model/guid";
import { BaseComponent } from "./base.component";
//import { CoreAdvancedFilterCmpnt } from "../layout/advanced-filter/advanced-filter.component";
import { ColumnFilter, LazyLoadArgs } from "../model/shared/lazy-load-args";
import { Guid } from "../model/shared/guid";
import { DataResult } from "../model/shared/data-result";
import { SelectItem } from "primeng/api";

@Injectable()
export abstract class DataViewBaseComponent<T> extends BaseComponent implements AfterViewInit {
	data: T[] = [];
	first: number = 0;
	displayRows: number = 20;
	fetchRows: number = 20;
	totalRecords: number = -1;
	filterBy: string = "";
	filterConfigs: ColumnFilter[] = [];
	loading: boolean = true;
	dataView: any = null;
	lazyLoadArgs: LazyLoadArgs = new LazyLoadArgs();
	isFilterActive: boolean = false;
	isFilterAvailable: boolean = false;
	searchInputChanged = new BehaviorSubject<string>("");
	searchInputKeyUp = new BehaviorSubject<string>("");
	primaryKey: string = "";
	infinteScrollTarget: any = null;
	isInfiniteScrollEnabled: boolean = false;
	showSearch: boolean = false;
	searchPlaceholder: string = "";
	private prevHeight: number = 0;

	private _componentGuid: string = Guid.newGuid();
	private _fetchedRecordCount: number = 0;
	private _componentName: string = "";
	private _sortField: string = "";
	private _sortDesc: boolean = false;
	private _isInit: boolean = true;
	private _setFilterAvailableTimer: any;
	private _sortCols: SelectItem[] = [];

	private _pGrid: any = null;
	set pGrid(value: any) {
		let that = this;
		let scroller = function () {
			if (that._pGrid.scrollTop + that._pGrid.offsetHeight + 20 > that._pGrid.scrollHeight) {
				if (that.lazyLoadArgs) {
					if (!that.lazyLoadArgs.first) {
						that.lazyLoadArgs.first = that.first;
					}
					if (!that.loading) {
						if (that.prevHeight < (that._pGrid.scrollTop + that._pGrid.offsetHeight)) {
							if ((that.lazyLoadArgs.first + that.fetchRows) < that.totalRecords) {
								that.lazyLoadArgs.first = that.lazyLoadArgs.first + that.fetchRows;
								that.loadInfiniteScroll(that.lazyLoadArgs);
							}
						}
					}
				}
			}
			that.prevHeight = that._pGrid.scrollTop + that._pGrid.offsetHeight;
		}
		if (value && value.length && !this._pGrid && this.isInfiniteScrollEnabled) {
			this._pGrid = value[0];
			if (this._pGrid.addEventListener) {
				this._pGrid.addEventListener("scroll", scroller, false);

			} else if (this._pGrid.attachEvent) {
				this._pGrid.attachEvent("onscroll", scroller);
			}
		}
	}
	get pGrid() {
		return this._pGrid;
	}
	set defaultSortField(value: string) {
		this.lazyLoadArgs.sortField = value;
		this._sortField = value;
	}
	set defaultSortDesc(value: boolean) {
		this.lazyLoadArgs.sortDesc = value;
		this._sortDesc = value;
	}
	get componentName() {
		return this._componentName;
	}
	set componentName(value: string) {
		this._componentName = value;
		this.lazyLoadArgs = this.appCtx.getListViewFilter(this._componentName, this._componentGuid);
	}
	get sortCols() {
		return this._sortCols;
	}
	set sortCols(value: any) {
		this._sortCols.length = 0;
		if (value && value.length) {
			this._sortCols.push({ value: null, label: "-- sort by--" });
			value.forEach((x: any) => {
				this._sortCols.push(x);
			});
		}
	}
	get loadedAllRecords(): boolean {
		if (this.totalRecords === -1) {
			return false;
		} else {
			return (this._fetchedRecordCount === this.totalRecords);
		}
	}
	onSearchChanged(filter: string) {
		if (this.dataView && filter === "") {
			this.dataView.filter("");
		}
		this.searchInputChanged.next(filter);

	}
	onSearchKeyUp(filter: string, dv: any = null) {
		if (dv) {
			this.dataView = dv;
		}
		this.searchInputChanged.next(filter);
		this.searchInputKeyUp.next(filter);
	}
	abstract lazyLoad(event: LazyLoadArgs): Observable<DataResult<T>>;

	ngAfterViewInit(): void {
		try {
			this.pGrid = document.getElementsByClassName("p-grid");
			if (this.lazyLoadArgs) {
				this.lazyLoadArgs.rows = this.fetchRows;
			}
			if (this.lazyLoadArgs.filters) {
				this.lazyLoadArgs.filters.forEach(x => {
					let filterConfig: any = this.filterConfigs.find(fc => {
						return fc.Name === x.Name;
					});
					if (filterConfig) {
						if (x.Values != null && x.Values.length > 0 && filterConfig.Options) {
							filterConfig.Values = filterConfig.Options.filter((opt: any) => {
								return x.Values.findIndex(xval => xval === opt[filterConfig.OptionValuePropertyName]) > -1;
							});
						}
						filterConfig.TextValues = x.TextValues;
						filterConfig.TextValue = x.TextValue;
						filterConfig.DateValues = x.DateValues;
						filterConfig.DateValue = x.DateValue;
						filterConfig.StartDate = x.StartDate;
						filterConfig.EndDate = x.EndDate;
						filterConfig.NumberValues = x.NumberValues;
						filterConfig.NumberValue = x.NumberValue;
						filterConfig.StartNumber = x.StartNumber;
						filterConfig.EndNumber = x.EndNumber;
						filterConfig.BoolValue = x.BoolValue;
						if (!filterConfig.IsHidden) {
							this.isFilterActive = this.isFilterActive || this.isFilterSet(filterConfig);
						}
					}
				});
			}
			this.searchInputChanged
				.pipe(skip(1))
				.pipe(debounceTime(500))
				.pipe(takeUntil(this.destroyed$))
				.subscribe((value) => {
					this.lazyLoadArgs.globalSearch = value;
					this.loadData();
				});
		} catch (e) {
			console.log(e);
		}
	}

	onSortChanged() {
		this.loadData();
	}
	onSortDirectionChanged() {
		this.lazyLoadArgs.sortDesc = !this.lazyLoadArgs.sortDesc;
		this.loadData();
	}

	///call this method to refresh the grid.
	loadData(event?: any, infinite: boolean = false) {
		try {
			let eventVal = new LazyLoadArgs();
			if (this._isInit) {
				// retrieves previous search filters if initialised again
				if (!this.lazyLoadArgs.isSet) {
					this.lazyLoadArgs.rows = this.fetchRows;
					this.lazyLoadArgs.sortField = this._sortField;
					this.lazyLoadArgs.sortDesc = this._sortDesc;
				}
				this.setFilters(this.filterConfigs);
				this._isInit = false;
				eventVal = JSON.parse(JSON.stringify(this.lazyLoadArgs)) as LazyLoadArgs;
			} else {
				eventVal = JSON.parse(JSON.stringify(this.lazyLoadArgs)) as LazyLoadArgs;
				if (event) {
					eventVal.first = event.first;
					eventVal.rows = this.fetchRows;
				}
				this.lazyLoadArgs = JSON.parse(JSON.stringify(eventVal)) as LazyLoadArgs;
			}
			this.appCtx.saveListViewFilter(this._componentName, this._componentGuid, this.lazyLoadArgs);
			this.loading = true;
			if (eventVal) {
				if (eventVal && eventVal.filters && eventVal.filters.length) {
					eventVal.filters.forEach(x => {
						if (!this.isFilterSet(x)) {
							eventVal.filters = eventVal.filters.filter(y => y.Name !== x.Name);
						}
					});
				}
			}
			this.lazyLoad(eventVal).subscribe(resp => {
				this.setIsFilterAvailable();
				if (infinite && this.primaryKey) {
					if (resp.DataList && resp.DataList.length) {
						resp.DataList.forEach((x: any) => {
							if (this.data && this.data.length) {
								this.data = this.data.filter((y: any) => y[this.primaryKey] !== x[this.primaryKey]);
							}
							this.data.push(x);
						});
					}
				} else {
					if (infinite) {
						this.data = [...this.data, ...(resp.DataList as T[])];
					} else {
						this.data = resp.DataList as T[];
					}
				}
				this._fetchedRecordCount = this.data.length;
				if (eventVal) {
					//if (typeof eventVal.globalSearch === "undefined") {
						this.totalRecords = (resp.TotalRecords) ? resp.TotalRecords : -1;
					//}
				}
				this.loading = false;
				this.loadCompleted.next(true);
			}, error => {
				if (!this.data) {
					this.data = [];
				}
				this.loading = false;
				this.setIsFilterAvailable();
				this.sharedSvc.showError(error);
				if (error.status && error.status === 401) {
					this.sharedSvc.navigate(["/"], { replaceUrl: true });
				}
				this.loadError.next(error);
			});
		} catch (e) {
			this.setIsFilterAvailable();
			this.loading = false;
			this.loadError.next(e);
			console.log(e);
		}
	}
	isFilterSet(x: ColumnFilter) {
		if ((x.Values && x.Values !== undefined && x.Values.length !== 0)
			|| (x.TextValues && x.TextValues !== undefined && x.TextValues.length !== 0)
			|| (x.TextValue !== null && x.TextValue !== undefined)
			|| (x.DateValues && x.DateValues !== undefined && x.DateValues.length !== 0)
			|| (x.DateValue !== null && x.DateValue !== undefined)
			|| (x.StartDate !== null && x.StartDate !== undefined)
			|| (x.EndDate !== null && x.EndDate !== undefined)
			|| (x.NumberValues && x.NumberValues !== undefined && x.NumberValues.length !== 0)
			|| (x.NumberValue !== null && x.NumberValue !== undefined)
			|| (x.StartNumber !== null && x.StartNumber !== undefined)
			|| (x.EndNumber !== null && x.EndNumber !== undefined)
			|| (x.BoolValue !== null && x.BoolValue !== undefined)) {
			return true;
		} else {
			return false;
		}
	}
	loadInfiniteScroll(event: any) {
		//let lazyEvent = Object.assign({}, this.lazyLoadArgs);
		let lazyEvent = JSON.parse(JSON.stringify(this.lazyLoadArgs)) as LazyLoadArgs;
		if (this.data) {
			lazyEvent.first = this.data.length;
		}
		lazyEvent.infinteScrollTarget = event as InfiniteScrollCustomEvent;
		this.infinteScrollTarget = event as InfiniteScrollCustomEvent;
		this.loadData(lazyEvent, true);
	}
	setIsFilterAvailable() {
		let that = this;
		try {
			let setAvailable: boolean = false;
			let isAvailable: boolean = false;
			if (this.filterConfigs) {
				let visibleFilters = this.filterConfigs.filter(x => !x.IsHidden);
				if (visibleFilters.length !== 0 && !this.isFilterAvailable) {
					isAvailable = true;
					setAvailable = true;
				} else if (visibleFilters.length === 0 && this.isFilterAvailable) {
					isAvailable = false;
					setAvailable = true;
				}
			} else {
				if (this.isFilterAvailable) {
					isAvailable = false;
					setAvailable = true;
				}
			}
			if (setAvailable) {
				if (this._setFilterAvailableTimer) {
					clearTimeout(this._setFilterAvailableTimer);
				}
				this._setFilterAvailableTimer = setTimeout(function () {
					if (that._setFilterAvailableTimer) {
						clearTimeout(that._setFilterAvailableTimer);
					}
					that._setFilterAvailableTimer = undefined;
					that.isFilterAvailable = isAvailable;
				}, 400);
			}
		} catch (e) {
			console.log(e);
		}
	}
	setFilters(filterConfigs: ColumnFilter[]) {
		try {
			let inpFilterConfigs = JSON.parse(JSON.stringify(filterConfigs)) as ColumnFilter[];
			this.lazyLoadArgs.filters = [];
			this.isFilterActive = false;
			inpFilterConfigs.forEach((x: ColumnFilter) => {
				if (!x.IsHidden) {
					if (!this._isInit) {
						this.isFilterActive = true;
					}
				}
				let colFilter = new ColumnFilter(x.Name, x.DisplayName, x.FilterType, x.IsHidden);
				colFilter.Name = x.Name;
				colFilter.FilterType = x.FilterType;
				if (x.OptionValuePropertyName) {
					colFilter.Values = x.Values.map(x => x[x.OptionValuePropertyName]);
				} else {
					colFilter.Values = x.Values;
				}
				colFilter.TextValues = x.TextValues;
				colFilter.TextValue = x.TextValue;
				colFilter.DateValues = x.DateValues;
				colFilter.DateValue = x.DateValue;
				colFilter.StartDate = x.StartDate;
				colFilter.EndDate = x.EndDate;
				colFilter.NumberValues = x.NumberValues;
				colFilter.NumberValue = x.NumberValue;
				colFilter.StartNumber = x.StartNumber;
				colFilter.EndNumber = x.EndNumber;
				colFilter.BoolValue = x.BoolValue;
				this.lazyLoadArgs.filters = this.lazyLoadArgs.filters.filter(y => y.Name !== colFilter.Name);
				this.lazyLoadArgs.filters.push(colFilter);
			});
			this.setIsFilterAvailable()
		} catch (e) {
			console.log(e);
		}
	}
	filterUpdated(filterConfigs: ColumnFilter[]) {
		this.setFilters(filterConfigs);
		this.loadData(this.lazyLoadArgs);
	}
}
