import { BaseDto } from "../baseDto"
/* DataFieldBaseDto generated at 2024-05-26 13:54:05.226 +10:00*/
export class DataFieldBaseDto extends BaseDto {
	DataFieldId?: string;
	TableName?: string;
	ColumnId?: number;
	ColumnName?: string;
	ColumnDataType?: string;
	ColumnLength?: number;
	ColumnPrecision?: number;
	ColumnScale?: number;
	IsNullable?: boolean;
	IsPrimaryKey?: boolean;
	PhysicalFieldId?: string;
	PhysicalTableName?: string;
	PhysicalColumnName?: string;
}
