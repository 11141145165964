import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
import { ColumnFilter, DataResult, LazyLoadArgs } from 'src/app/core/model/shared';
import { DomainValueDto } from 'src/app/core/model/feature/apcomodel/DomainValueDto';
@Component({
    selector: "home-submission-list-filter",
    templateUrl: "./submission-list-filter.component.html"
})
export class HomeSubmissionListFilterCmpnt extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    goLiveDate?: string = "";
    @Input() visible: boolean = false;
    @Input() lazyLoadArgs: LazyLoadArgs = new LazyLoadArgs();
    @Input() materialList: DomainValueDto[] = [];
    @Input() regionList: DomainValueDto[] = [];
    @Input() statusList: DomainValueDto[] = [];
    @Output() onSearch = new EventEmitter<LazyLoadArgs>();
    @Output() onClose = new EventEmitter<any>();

    public isSelectAllMaterial: boolean = false;
    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {

            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.isSelectAllMaterial = false;
            if (this.materialList && this.materialList.length) {
                let foundSelected = this.materialList.filter(x => x.BooleanValue);
                if (foundSelected.length == this.materialList.length) {
                    this.isSelectAllMaterial = true;
                }
            }
            this.initPage();
        } catch (ex) {
            console.error(ex);
            this.initPage();
        }
    }
    initPage() {
        try {
            this.showComponent = true;
        } catch (ex) {
            console.error(ex);
        }
    }
    onClickSearch() {

        if (this.lazyLoadArgs && this.lazyLoadArgs.filters) {
            let tFilter = this.lazyLoadArgs.filters.find(x => x.Name == "SubmissionRegion");
            if (tFilter) {
                tFilter.TextValues = [];
                this.regionList.forEach(x => {
                    if (x.BooleanValue && x.CodeValue)
                        tFilter?.TextValues.push(x.CodeValue);
                });
            }
            tFilter = this.lazyLoadArgs.filters.find(x => x.Name == "SubmissionMaterial");
            if (tFilter) {
                tFilter.TextValues = [];
                this.materialList.forEach(x => {
                    if (x.BooleanValue && x.NameValue)
                        tFilter?.TextValues.push(x.NameValue);
                });
            }
            tFilter = this.lazyLoadArgs.filters.find(x => x.Name == "SubmissionMaterial");
            if (tFilter) {
                tFilter.TextValues = [];
                this.materialList.forEach(x => {
                    if (x.BooleanValue && x.NameValue)
                        tFilter?.TextValues.push(x.NameValue);
                });
            }
        }
        this.onSearch.emit(this.lazyLoadArgs);
    }
    onClickClear() {
        this.isSelectAllMaterial = false;
        if (this.regionList && this.regionList.length) {
            this.regionList.forEach(x => x.BooleanValue = false);
        }
        if (this.statusList && this.statusList.length) {
            this.statusList.forEach(x => x.BooleanValue = false);
        }
        if (this.materialList && this.materialList.length) {
            this.materialList.forEach(x => x.BooleanValue = false);
        }
    }
    selectAllMaterials() {
        if (this.materialList && this.materialList.length) {
            this.materialList.forEach(x => x.BooleanValue = this.isSelectAllMaterial);
        }
    }
    toggleMaterials() {
        let selectedAll = false;
        if (this.materialList && this.materialList.length) {
            let found = this.materialList.filter(x => !x.BooleanValue);
            if (found && found.length) {
                selectedAll = false;
            } else {
                selectedAll = true;
            }
        }
        this.isSelectAllMaterial = selectedAll;
    }
    onChangeGoLiveDate() {
        if (this.lazyLoadArgs && this.lazyLoadArgs.filters && this.lazyLoadArgs.filters.length) {
            var displayDateTextFilter = this.lazyLoadArgs.filters.find((x: ColumnFilter) => x.Name == "DisplayDateText");
            if (displayDateTextFilter) {
                if (this.goLiveDate) {
                    displayDateTextFilter.TextValue = (new Date(this.goLiveDate)).toDateString();
                } else {
                    displayDateTextFilter.TextValue = undefined;
                }
            }
        }
    }
    onClickClose() {
        this.onClose.emit();
    }
}