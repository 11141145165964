import { ANZPACReportBaseDto } from "../../base/apcomodel";
import { ANZPACBaselineDto } from "./ANZPACBaselineDto";
import { ANZPACDetailsDto } from "./ANZPACDetailsDto";

export class ANZPACReportDto extends ANZPACReportBaseDto
{
  BaselineSetup?: ANZPACBaselineDto[];
  ANZPACBaselines?: ANZPACBaselineDto[];
  ANZPACDetails?: ANZPACDetailsDto[];
  AllowEdit?: boolean
}
