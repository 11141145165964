import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, FormControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[validateEmail]',
    providers: [{
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true
  }]
})
export class EmailValidatorDirective implements Validator {
  
  emailRegExp: RegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value === '') {
      return null;
    }
    let validator = new RegExp(this.emailRegExp);
    let ret = (validator.test(control.value) ? null : {email : 'not matched'});
    return ret;
  }    
}
