<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>
  
  <div class="flex-wrap" style=" background-color: #0B605E !important; align-items: stretch;">
    <div class="leftheader">
      <div class="headercontent" style="min-height:10em">
        <h1 class="heading text">Right label, right bin</h1>
        <h2 class="subtext">Discover your power as a consumer in recycling better and minimising waste</h2>
      </div>
    </div>
    <div class="rightheader">
      <img src="images/hero_consumer.jpg" alt="ARL mascot looks at recycling label on packaging.">
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-7">
      <div class="body-container p2">
        <div class="flex-wrap">
              <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">Your actions matter</h2>
            </div>
        <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              Your actions, from selecting the right packaging at the store to properly disposing of it at home, contribute to better waste separation. The ARL plays a vital role in reducing confusion by providing clear, on-pack recycling information for each part of packaging. By following the ARL’s instructions, you are helping to increase recycling rates and reduce contamination. When choosing products with the ARL, you are also supporting the organisations who are working towards more sustainable packaging in Australia and New Zealand.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2">
        <div class="flex-wrap ">
            <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">ARL’s impact</h2>
            </div>
          <div class="flex-wrap parentfont">
            <ul class="fontsize psection">
              <li class="mt-1">
                Three quarters of Australians agree the ARL is a great idea and want to see it on all packaging.
              </li>
              <li class="mt-1">
                44% of consumers say the ARL encourages them to recycle more than they do.
              </li>
              <li class="mt-1">
                The ARL is helping to cut confusion around problematic materials, e.g. 60% of aluminium foil was correctly disposed with ARL vs 40% without the ARL.
              </li>
            </ul>
            <div class="flex-wrap parentfont">
              <p class="fontsize">
                (source:
                <a class="fc-black" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="https://documents.packagingcovenant.org.au/public-documents/ARL%20Consumer%20Insights%20Report%202023">
                  ARL Consumer Insight Report 2023
                </a>)
              </p>
            </div>
            <br />
            <br />
            <p class="fontsize">
              Looking to recycle better in your neighbourhood? Discover local kerbside collection or alternative recycling locations across Australia with
              <a class="fc-black" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                 href="https://www.arl.org.au/">
                your recycling guide.
              </a>

            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8 ">
      <div class="body-container p2">
        <div class="flex-wrap ">
            <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">Empowering more positive choices</h2>
            </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              The ARL and Planet Ark work together to empower communities to recycle correctly. This partnership leverages Planet Ark's expertise in consumer behaviour change. This is demonstrated through successful initiatives of Planet Ark, including National Recycling Week and National Tree Day, which have engaged millions of Australians in positive environmental actions. Planet Ark actively provides valuable resources and tools to schools and councils, aimed to both educate and raise awareness of the ARL.  Previous consumer awareness campaigns of the ARL established with Planet Ark included the "Check It Before You Chuck It," campaign, which encouraged consumers to ‘Check’ their packaging for the ARL, before they ‘Chuck It’.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="p2 center">
            <iframe class="videoPlayer" src="https://www.youtube.com/embed/0FbSIZtXiAo" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2">
        <div class="flex-wrap">
            <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">Be part of the movement</h2>
            </div>
            <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              These toolkits are complimentary resources available to be shared within your network. They are ideal for use in schools, local councils, and community groups to educate and promote awareness of the ARL and the importance of recycling.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8 mb-8">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="flex-wrap center ">
            <div class="column25PARL">
              <div class="caption"><strong>Councils</strong></div>
              <a target="_blank" rel="noopener noreferrer" height="100%" href="https://recyclingnearyou.com.au/documents/doc-14051-local-government-tool-kit---arl.pdf">
                <img style="width: 300px; height: 428px" src="images/img_ARL for councils.png" alt="Cover of informational document for councils." />
              </a>
            </div>
            <div class="column25PARL">
              <div class="caption"><strong>Waste Groups</strong></div>
              <a target="_blank" rel="noopener noreferrer" height="100%" href="https://recyclingnearyou.com.au/documents/doc-14052-waste-groups-tool-kit---arl-.pdf">
                <img style="width: 300px; height: 428px" src="images/img_ARL for waste groups.png" alt="Cover of informational document for waste management groups." />
              </a>
            </div>
            <div class="column25PARL">
              <div class="caption"><strong>Educators</strong></div>
              <a target="_blank" rel="noopener noreferrer" height="100%" href="https://recyclingnearyou.com.au/documents/doc-14053-educators-tool-kit---arl-.pdf">
                <img style="width: 300px; height: 428px" src="images/img_ARL for educators.png" alt="Cover of informational document for educators." />
              </a>
            </div>
            <div class="column25PARL">
              <div class="caption"><strong>NGO's & Community Groups</strong></div>
              <a target="_blank" rel="noopener noreferrer" height="100%" href="https://recyclingnearyou.com.au/documents/doc-14054-ngos-and-community-group-tool-kit---arl.pdf">
                <img style="width: 300px; height: 428px" src="images/img_ARL for NGOs community groups.png" alt="Cover of informational document for non profit organisations and community groups." />
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap">
    <arl-footer [pagePath]="pagePath" class="flex-wrap"></arl-footer>
  </div>
</div>
