import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
import { DataResult, LazyLoadArgs } from '../../../../core/model/shared';
import { Constants, DomainValueDto } from '../../../../core/model/feature/apcomodel';
@Component({
    selector: "home-submission-list-filter-dialog",
    templateUrl: "./submission-list-filter-dialog.component.html"
})
export class HomeSubmissionListFilterDialog extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    @Input() materialList: DomainValueDto[] = [];
    @Input() regionList: DomainValueDto[] = [];
    @Input() visible: boolean = false;
	@Input() lazyLoadArgs: LazyLoadArgs = new LazyLoadArgs();
    @Output() onSearch = new EventEmitter<LazyLoadArgs>();
    @Output() onClose = new EventEmitter<any>();
    
    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
        } catch (ex) {
            
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);            
        }
    }
    initPage() {
        try {
            this.showComponent = true;
        } catch (ex) {            
            console.error(ex);
        }
    }
    onSearchEvent(filterArg: LazyLoadArgs) {
        this.onSearch.emit(filterArg);        
    }
    onCloseEvent(close: any){
        this.onClose.emit(close);
    }
}