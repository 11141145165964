import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
import { UserDto } from '../../../../core/model/feature/apcomodel';
import { AuthenticationService } from '../../service/authentication.service';
@Component({
    selector: "auth-change-password",
    templateUrl: "./change-password.component.html"
})
export class AuthChangePasswordCmpnt extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    showLoginPassword: boolean = false;
    showNewPassword: boolean = false;
    showConfirmPassword: boolean = false;
    delayRequestTimer: any = null;    
    @Input() visible: boolean = false;
    @Output() onSuccess = new EventEmitter<any>();
    @Output() onFailed = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();

    @Input() user: UserDto = new UserDto();
    @Input() showCurrentPassword: boolean = false;
    confirmPassword?: string;
    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
            // this.navigationSubscription = this.appContext.onNavigationEnd().subscribe((e: any) => {
            //     if (this.showComponent) {
            //         this.loadInitData();
            //     }
            // });
        } catch (ex) {

            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
        } catch (ex) {

            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {

            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {

            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);

        }
    }
    initPage() {
        try {
            this.resetAll();
            this.showComponent = true;
        } catch (ex) {

            console.error(ex);
        }
    }
    get isValid() {
        let lvValid: boolean | undefined;
        try {
            lvValid = this.thisForm && this.thisForm.form.valid && this.user.NewPassword === this.confirmPassword;
        } catch (ex) {
            console.error(ex);
        }
        return lvValid;
    }
    resetAll() {
    }
    onRequest() {
        try {
            this.resetAll();
            let that = this;
            this.delayRequestTimer = setTimeout(function () {
                if (that.delayRequestTimer) { clearTimeout(that.delayRequestTimer); }
                that.delayRequestTimer = undefined;
                that.executeRequest();
            }, 500);
        } catch (ex) {
            console.error(ex);
        }
    }
    executeRequest() {
        try {
            if (this.user && this.user.NewPassword && this.user.NewPassword === this.confirmPassword && (!this.showCurrentPassword || (this.showCurrentPassword && this.user.UserPassword))) {
                this.authSvc.changePassword(this.user).subscribe((result: any) => {
                    this.resetAll();
                    this.onSuccess.emit(result);
                }, (error: any) => {
                    console.error("Request to change password error", error);
                    this.resetAll();
                    this.onFailed.emit(error);
                });
            } else {
                this.resetAll();
                this.onFailed.emit("");
            }
        } catch (ex) {
            console.error(ex);
            this.resetAll();
            this.onFailed.emit(ex);
        }
    }
    onTryAgain() {
        this.resetAll();
    }
    onCancel() {
        this.resetAll();
        this.visible = false;
        this.onClose.emit();
    }
    get showChangePasswordLater() {
        if (this.user && this.user.DatePasswordChangeExpiry) {
            return (this.appCtx.utilSvc.dateSecondsDiff(new Date(),new Date(this.user.DatePasswordChangeExpiry))>0);
        } else {
            return false;
        }
    }
}