import { BaseDto } from "../baseDto"
/* WARLUpdateLogBaseDto generated at 2024-05-26 13:54:05.434 +10:00*/
export class WARLUpdateLogBaseDto extends BaseDto {
	WebARLUpdateLogId?: string;
	Material?: string;
	UpdateDescription?: string;
	ProgramRegion?: string;
	ProgramStatus?: string;
	GoLiveDate?: Date;
	DisplayGoLiveDateText?: string;
	PREPUpdateTimeline?: string;
	ArtworkUpdateTimeline?: string;
	StatusCode?: string;
}
