<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;" [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
    <!--  Bootstrap nav   -->
    <nav class="navbar navbar-expand-md bg-white shadow-sm py-0">
        <div class="container bg-white">
            <a class="navbar-brand py-0" height="100%" href="https://arl.org.au"><img class="brand-logo"
                    src="images/ARL for consumers logo.png" alt="Australasian Recycling Label for consumers logo"></a>
            <!-- Disabled navigation
  
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse justify-content-end align-items-center flex" id="navbarSupportedContent" class="yellow-border">
                  <ul class="navbar-nav">
                
        Dropdown 1   
                  <li class="nav-item">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Learn More About the ARL
                      </a>
                      <div class="dropdown align-items-center">
                          <ul class="dropdown-menu shadow bg-light">
                              <li><a class="dropdown-item" href="https://www.arlmarketplace.org.au/what-is-the-arl"><span class="yellow-border">What is the ARL</span></a></li>
                              <li><a class="dropdown-item" href="https://www.arlmarketplace.org.au/benefits-of-the-arl"><span class="yellow-border">Benefits of the ARL</span></a></li>
                              <li><a class="dropdown-item" href="https://www.arlmarketplace.org.au/how-to-start-using-the-arl"><span class="yellow-border">How to Start Using the ARL</span></a></li>
                          </ul>
                      </div>
                  </li>
  
        Dropdown 2   		 
                    <li class="nav-item">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Resources  </a>
                      <div class="dropdown">
                          <ul class="dropdown-menu shadow bg-light">
                              <li><a class="dropdown-item hover-item" href="https://www.arlmarketplace.org.au/resources"><span class="yellow-border">News & Resources</span></a>
                              </li>
                              <li>
                                  <a class="dropdown-item" href="https://www.arlmarketplace.org.au/calculations"><span class="yellow-border">Calculator</span></a>
                              </li>
                          </ul>
                      </div>
                  </li>
  
                  <li class="nav-item btm-link">
                      <a class="nav-link" href="https://www.arlmarketplace.org.au/events"><span class="yellow-border">Events</span></a>
                  </li>
                  </ul>
                </div>
          -->
        </div>
    </nav>
    <!--   Main container   -->
    <div class="container">

        <!--   Intro text   -->
        <div class="row pt-4 pb-2">
            <h1 class="text-center"><abbr title="Australasian Recycling Label">ARL</abbr> Local Recycling</h1>
            <p>Use this tool to check locally for the kerbside recyclability of soft plastics and other packaging, as
                well as nearby away-from-home recycling and disposal destinations.</p>
            <p><strong>Please note: </strong>the majority of soft plastics searches will currently recommend landfill
                for your packaging. Get latest updates here as more soft plastics services come online in the near
                future.</p>
        </div>
        <!--    Widget    -->
        <div class="row pb-2 d-flex justify-content-center">
            <div class="col-12 col-md-7 g-0 iframeborder">
                <iframe src="https://sitelet.recyclemate.com.au/sitelet/2" title="Recycle Mate" height="700px"
                    width="100%" allowfullscreen="true"></iframe>
            </div>
            <!--    tiles  
              <div class="col-12 col-md-3 g-0">
                  <a href="#">
                      <div class="squares d-flex flex-wrap text-white p-2" style="height:25%;">
                          <h2 class="m-auto text-center fs-5">Soft Plastics</h2>
                      </div>
                  </a>
                  <a href="#">
                      <div class="squares d-flex flex-wrap text-white  p-2" style="height:25%;">
                          <h2 class="m-auto text-center fs-5">Why can't some soft plastics be recycled?</h2>
                      </div>
                  </a>
                  <a href="https://recyclingnearyou.com.au/arl/">
                      <div class="squares d-flex flex-wrap text-white p-2" style="height:25%;">
                          <h2 class="m-auto text-center fs-5">What does my label mean?</h2>
                      </div>
                  </a>
                  <a href="https://recyclingnearyou.com.au/">
                      <div class="squares d-flex flex-wrap text-white  p-2" style="height:25%;">
                          <h2 class="m-auto text-center fs-5">Additional Resources and Toolkits</h2>
                      </div>
                  </a>
              </div>
                -->
        </div>
        <!--    Accordions    -->
        <div class="accordion accordion-flush py-3" id="accordionRecycle">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        The latest on soft plastics recycling
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionRecycle">
                    <div class="accordion-body">
                        Industry is working hard to re-establish soft plastics collection
                        <br><br>
                        <a href="https://apco.org.au/latest-update-for-soft-plastics" class="'"><strong>Learn
                                more..</strong></a>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        What are Soft Plastics?
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionRecycle">
                    <div class="accordion-body">
                        Soft plastics refer to flexible plastics that can be easily scrunched. Soft plastics pose
                        challenges to traditional recycling equipment and require specialised recycling to be managed
                        effectively.
                        <br><br>
                        <a href="https://apco.org.au/what-is-soft-plastics" class="'"><strong>Learn more..</strong></a>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What is the ARL Program?
                    </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionRecycle">
                    <div class="accordion-body">
                        The Australasian Recycling Label (ARL) is an on-pack label that provides simple recycling
                        information for packaging sold across Australia and New Zealand.
                        <br><br>
                        <a href="https://apco.org.au/the-australasian-recycling-label" class="'"><strong>Learn
                                more..</strong></a>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                        Additional resources and toolkits
                    </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                    data-bs-parent="#accordionRecycle">
                    <div class="accordion-body">
                        Get more recycling information and resources for your home and community.
                        <br><br>
                        <a href="https://recyclingnearyou.com.au/education/" class="'"><strong>Click here..</strong></a>
                    </div>
                </div>
            </div>
        </div>
        <!--  Disclaimer  -->
        <div class="disclaimer p-3 mt-2 mb-5">
            <h2>Disclaimer</h2>
            <p><strong>Please note:</strong> Recycle Mate was developed and is maintained by third parties acting
                independently of the Australian Packaging Covenant Organisation (APCO). APCO is not responsible for and,
                to the maximum extent permitted by law, does not represent, warrant or guarantee the results produced by
                the use of Recycle Mate, including the currency, the accuracy, completeness or suitability for your
                intended use. Recycle Mate is provided to you on an “as is” and “as available” basis.</p>
            <p>You should review Recycle Mate’s Privacy Policy and Terms and Conditions, available at
                www.recyclemate.com.au, carefully and immediately cease using Recycle Mate if you do not agree to its
                Terms and Conditions. </p>
            <p>By using Recycle Mate, you acknowledge and agree that Recycle Mate (including the results produced by
                your use of Recycle Mate) cannot be used for any on-pack or external claims, including but not limited
                to recyclability claims or recovery claims. Recycle Mate does not replace the on-pack messaging
                requirement under the rules of the Australasian Recycling Label (ARL) and is designed to give additional
                local guidance to consumers where possible. </p>
            <p>APCO accepts no responsibility for errors or omissions in the results produced by the use of Recycle
                Mate, or (to the maximum extent permitted by law) liability for any loss or damage arising from the use
                of or reliance on Recycle Mate or results generated through the use of Recycle Mate. Recycle Mate is
                subject to revision and withdrawal from public use without notice. Users should seek appropriate advice
                when applying the information to their specific needs. </p>
        </div>
    </div>

    <!--    Feedback button    -->
    <!--    Email to be updated    -->
    <div>
        <a class="btn py-3" role="button" href="mailto:arl@apco.org.au?subject=Feedback from arl.org.au website"
            onclick='window.open("mailto:arl@apco.org.au?subject=Feedback from arl.org.au website");return false;'>Feedback</a>
    </div>

    <!--  Footer  -->
    <div class="footer py-2">
        <div class="container d-flex justify-content-center">
            <!--    Copyright    -->
            <span class="footer-links px-2">Copyright &copy;
                <script>document.write(new Date().getFullYear())</script><abbr title="Australasian Recycling Label">
                    ARL</abbr>
            </span>
            <!--    Email link    -->
            <span class="footer-links px-2">
                <a href="mailto:arl@apco.org.au?subject=Feedback from arl.org.au website"
                    onclick='window.open("mailto:arl@apco.org.au?subject=Feedback from arl.org.au website");return false;'>E:
                    arl@apco.org.au </a>
            </span>
            <!--    Privacy    -->
            <span class="footer-links px-2">
                <a href="https://documents.packagingcovenant.org.au/other-public-documents/APCO%20Privacy%20Policy"
                    target="_blank" class="footer-links  px-1">Privacy Policy</a>
            </span>
        </div>
    </div>
</div>