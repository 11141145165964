<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);" class="container flex-inline bgc-white"
    [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
    <div>
        <a class="navbar-brand py-0" height="100%" href="https://arl.org.au"><img class="brand-logo"
                src="images/ARL for consumers logo.png" alt="Australasian Recycling Label for consumers logo"></a>
    </div>
    <div *ngIf="showMenuItems" class="flex-fill middle">
        <div class="right" [ngClass]="{'hidden':IsMobileView}">
            <p-menubar [model]="browserMenu"></p-menubar>
        </div>
        <div class="right" [ngClass]="{'hidden':!IsMobileView}">
            <button pButton type="button" (click)="toggleTopMenu()" icon="pi pi-bars" label=""></button>
        </div>
    </div>    
    <div *ngIf="IsTopMenuView && IsMobileView" style="position:absolute;z-index:10;right:1em;top:5em;border:1px solid black;">
        <div class='right pr-2 bgc-white'>
            <p-panelMenu #menu [model]="mobileMenu"></p-panelMenu>
        </div>
    </div>
</div>