<div class="flex-wrap" *ngIf="showComponent && visible">
        <form name="form" class="flex-wrap" (ngSubmit)="onRequest()" #thisForm="ngForm" autocomplete="off" novalidate>            
            <div class="flex-wrap p2">
                <div class="flex-wrap">
                    <span><b>Please change your password.</b></span>
                </div>
                <div class="flex-wrap mt-2" *ngIf="showCurrentPassword">
                    <div class="flex-wrap">
                        <label style="color:red;">* Current Password </label>
                        <i class="fas fa-eye" (click)="showLoginPassword = !showLoginPassword"></i>
                    </div>
                    <div class="flex-wrap mt-2">
                        <input name="password" type="{{(showLoginPassword) ? 'text' : 'password'}}"
                            [(ngModel)]="user.UserPassword" required pInputText validatePassword />
                    </div>
                </div>
                <div class="flex-wrap mt-2">
                    <label style="color:red;">* New Password</label>
                    <i class="fas fa-eye" (click)="showNewPassword = !showNewPassword"></i>
                </div>
                <div class="flex-wrap mt-2">
                    <input name="newPassword" type="{{(showNewPassword) ? 'text' : 'password'}}"
                        [(ngModel)]="user.NewPassword" required pInputText validatePassword />
                </div>
                <div class="flex-wrap mt-2">
                    <label>The password length must be minimum of 8 characters including caps and symbols.</label>
                </div>
                <div class="flex-wrap mt-2">
                    <label style="color:red;">* Confirm New Password</label>
                    <i class="fas fa-eye" (click)="showConfirmPassword = !showConfirmPassword"></i>
                </div>
                <div class="flex-wrap mt-2">
                    <input name="confPassword" type="{{(showConfirmPassword) ? 'text' : 'password'}}"
                        [(ngModel)]="confirmPassword" required pInputText validatePassword />
                </div>
                <div class="flex-wrap mt-2">
                    <span style="color:red;">* denotes required details.</span>
                </div>
            </div>
            <div class="flex-wrap">
                <div class="column2 mt-2 p2">
                    <button type="submit" class="button button-highlight button-block center" [disabled]="!isValid"
                        (click)="onRequest()">
                        Change Password
                    </button>
                </div>
                <div class="column2 mt-2 p2">
                    <button type="button" class="button button-highlight button-block center" (click)="onCancel()">
                        <span *ngIf="showChangePasswordLater">Change Later</span>
                        <span *ngIf="!showChangePasswordLater">Close</span>
                    </button>
                </div>
            </div>
        </form>
</div>