<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>

  <div class="flex-wrap" style=" background-color: #0B605E !important; align-items: stretch;">
    <div class="leftheader">
      <div class="headercontent" style="min-height:10em">
        <h1 class="heading text">Join the ARL program</h1>
        <h2 class="subtext">Empower your packaging design with an evidence-backed label</h2>
      </div>
    </div>
    <div class="rightheader">
      <img src="images/hero_join.jpg" alt="Photo of male and female smiling and standing in a packaging warehouse environment.">
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-7 mb-8">
      <div class="body-container p2">
        <div class="flex-wrap ">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">How to join</h2>
          </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              Access to the Australasian Recycling Label (ARL) program is available to members of the Australian Packaging Covenant Organisation (APCO). Participation in the ARL program, including access to the Packaging Recyclability Evaluation Portal (PREP) and the ARL is included within APCO membership and covered by APCO membership fees.
              <br />
              <br />
            </p>
           
            <div class="flex-wrap parentfont">
              <ul style="list-style-type: none; padding: 0; margin: 0; " class="fontsize">
                <li class="mt-1">
                  <strong>1. Join APCO: </strong>The Australian Packaging Covenant applies to all businesses in the packaging supply chain with a turnover over $5 Million. Businesses with a turnover up to $5 Million are eligible to join as SME members with reduced fee and streamlined reporting obligation.
                </li>
                <li class="mt-1">
                  <strong>2. Assess your packaging through PREP: </strong>The ARL is generated using the evidence supplied by PREP, an online tool that allows packaging manufacturers and brand owners to assess how their packaging will perform in the Australian and New Zealand resource recovery systems.
                </li>
                <li class="mt-1">
                  <strong>3. Get your ARL artwork files: </strong>Download the files from APCO Member Center.
                </li>
              </ul>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-lime">
    <div class="flex-wrap mt-4 mb-4">
      <div class="body-container p2">
        <div class="flex-wrap ">
          <div class="fc-dark-turquoise center parentfont">
            <h2 class="contentheader h2section" style="text-align:center">The ARL is available to Australian Packaging Covenant Organisation (APCO) members</h2>
          </div>
          <div class="flex-wrap">
            <div class="center ">
              <div class="brad2 bgc-yellow big-btn w20 round-button">
                <div class="r-centroid w-fit-content font-bold">
                  <a class="fc-black fontsize" target="_blank" rel="noopener noreferrer"
                     href="https://apco.org.au/about-membership-signatory">
                    Become an APCO Member
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-wrap ">
            <div class="fc-dark-turquoise center">
              <a class="fc-dark-turquoise fontsize" target="_blank" rel="noopener noreferrer"
                 href="https://apco.org.au/faqs?category=Australasian+Recycling+Label+Program">
                <span style="text-decoration:underline ;"><strong>ARL FAQs</strong></span>   <span><strong> ></strong></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8 ">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="flex-wrap parentfont">
            <div class="fc-dark-turquoise">
              <h2 class="contentheader h2section">APCO member benefits</h2>
            </div>
          </div>
          <div class="flex-wrap  ml-4 parentfont">
            <ul class="fontsize psection">
              <li class="mt-1">
                Access to the Australasian Recycling Label (ARL) program, including the Packaging Recyclability Evaluation Portal (PREP) and use of the ARL on packaging.
              </li>
              <li class="mt-1">
                Access to APCO Working and Advisory Groups: active discussions on upcoming key initiatives in the sustainable packaging industry.
              </li>
              <li class="mt-1">
                Access to the
                <a class="fc-black" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="https://documents.packagingcovenant.org.au/public-documents/Sustainable%20Packaging%20Guidelines%20(SPGs)">
                  Sustainable Packaging Guidelines
                </a> (SPGs), a government-supported, national resource for packaging best practice.
              </li>
              <li class="mt-1">
                Exclusive access to the APCO Member & Signatory Centre and member resources, including case studies, webinars, technical guides, trainings and (discounted) partner events.
              </li>
              <li class="mt-1">
                One streamlined pathway for packaging reporting under the NEPM.
              </li>
              <li class="mt-1">
                Discounted courses from the
                <a class="fc-black" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="http://aipack.com.au/">
                  Australian Institute of Packaging (AIP).
                </a>
              </li>
              <li class="mt-1">
                Recognition for stand-out reporting at the APCO Annual Awards.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-4">
      <div class="body-container p2">
        <div class="flex-wrap ">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">About APCO</h2>
          </div>
          <div class="flex-wrap parentfont">
            <div class="column15PARL psection" style="padding-right:40px;">
              <img style="width: 181px; height: 88px" src="images/logo_APCO.png" />
            </div>
            <div class="column85PARL">
              <p class="fontsize psection">
                The Australian Packaging Covenant Organisation (APCO) is a not-for-profit organisation leading the development of a circular economy for packaging in Australia. APCO is the entity in charge of managing and administering the Australian Packaging Covenant (the Covenant), which is a national regulatory framework under the
                <a class="fc-black fontsize" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="https://www.legislation.gov.au/F2011L02093/latest/text">
                  National Environment Protection (Used Packaging Materials) Measure 2011
                </a>
                (NEPM). This framework defines the collective responsibility shared by governments and businesses across Australia in managing the environmental impact of packaging. This pivotal role bridges industry participants along the packaging supply chain with Commonwealth, state, and territory governments, endorsed by environment ministers.  APCO works actively with all its members, governments, and strategic partners in develop insights, resources, and programs towards a sustainable national packaging ecosystem.
                <br />
                <a class="fc-black fontsize" target="_blank" rel="noopener noreferrer" style="text-decoration:underline ;"
                   href="https://apco.org.au/">
                  Find out more about APCO here.
                </a>

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-4 mb-8">
      <div class="body-container p2">
        <div class="flex-wrap ">
          <div class="flex-wrap">
            <div class="center ">
              <div class="brad2 bgc-yellow big-btn w20 round-button">
                <div class="r-centroid w-fit-content font-bold">
                  <a class="fc-black fontsize" target="_blank" rel="noopener noreferrer"
                     href="https://apco.org.au/about-membership-signatory">
                    Become an APCO member
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-wrap ">
            <div class="fc-dark-turquoise center">
              <a class="fc-dark-turquoise fontsize" target="_blank" rel="noopener noreferrer"
                 href="https://apco.org.au/faqs?category=Australasian+Recycling+Label+Program">
                <span style="text-decoration:underline ;"><strong>ARL FAQs</strong></span>   <span><strong> ></strong></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap">
    <arl-footer [pagePath]="pagePath" class="flex-wrap"></arl-footer>
  </div>
</div>
