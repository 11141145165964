import { BaseDto } from "../baseDto"
/* SFAccountBaseDto generated at 2024-05-26 13:54:05.348 +10:00*/
export class SFAccountBaseDto extends BaseDto {
	Id?: string;
	IsDeleted?: boolean;
	MasterRecordId?: string;
	Name?: string;
	Type?: string;
	RecordTypeId?: string;
	ParentId?: string;
	BillingStreet?: string;
	BillingCity?: string;
	BillingState?: string;
	BillingPostalCode?: string;
	BillingCountry?: string;
	BillingLatitude?: number;
	BillingLongitude?: number;
	BillingGeocodeAccuracy?: string;
	ShippingStreet?: string;
	ShippingCity?: string;
	ShippingState?: string;
	ShippingPostalCode?: string;
	ShippingCountry?: string;
	ShippingLatitude?: number;
	ShippingLongitude?: number;
	ShippingGeocodeAccuracy?: string;
	Phone?: string;
	Fax?: string;
	AccountNumber?: string;
	Website?: string;
	PhotoUrl?: string;
	Sic?: string;
	Industry?: string;
	AnnualRevenue?: number;
	NumberOfEmployees?: number;
	Ownership?: string;
	TickerSymbol?: string;
	Description?: string;
	Rating?: string;
	Site?: string;
	OwnerId?: string;
	CreatedDate?: Date;
	CreatedById?: string;
	LastModifiedDate?: Date;
	LastModifiedById?: string;
	SystemModstamp?: Date;
	LastActivityDate?: Date;
	LastViewedDate?: Date;
	LastReferencedDate?: Date;
	Jigsaw?: string;
	JigsawCompanyId?: string;
	AccountSource?: string;
	SicDesc?: string;
	ABN__c?: number;
	APCO_Sign_up_Date__c?: Date;
	AP_Submission_Confirmed_By__c?: string;
	ARL_Access__c?: boolean;
	ARL_Submission_Confirmed_By__c?: string;
	ARL_T_C_s_First_Signed__c?: Date;
	ARL_T_C_s_Last_Signed__c?: Date;
	ARL_T_C_s_Signed_Within_Last_Year__c?: boolean;
	AR_Submission_Confirmed_By__c?: string;
	Action_Plan_Due_Date__c?: Date;
	Action_Plan_Status__c?: string;
	Active_Withdrawn__c?: string;
	Additional_PREP_Licenses__c?: number;
	Alternate_Fee_Model__c?: boolean;
	Annual_Report_Due_Date__c?: Date;
	Annual_Report_Status__c?: string;
	Annual_Reporting_Tool_Access__c?: boolean;
	Brands_Sold__c?: string;
	Count_Employees__c?: string;
	Date_AP_Submitted__c?: Date;
	Date_AR_Submitted__c?: Date;
	Date_Membership_Fees_Paid__c?: Date;
	Date_Turnover_Declaration_Submitted__c?: Date;
	Date_Withdrawn__c?: Date;
	Edited_By_App__c?: boolean;
	Email_Record_Type_Name__c?: string;
	Events_Access__c?: boolean;
	External_ID__c?: string;
	First_ARL_Artwork_Approved__c?: Date;
	First_FY__c?: boolean;
	Invoice_Status__c?: string;
	Knowledge_Centre_Access__c?: boolean;
	Logo_Preview__c?: string;
	Logo__c?: string;
	Member_Centre_Access__c?: boolean;
	Member_Group__c?: string;
	Member_Status__c?: string;
	Membership_Fees_Due_Date__c?: Date;
	Other_Supply_Chain_Positions__c?: string;
	PREP_Access__c?: boolean;
	PREP_Submission_Confirmed_By__c?: string;
	PREP_T_C_s_First_Signed__c?: Date;
	PREP_T_C_s_Last_Signed__c?: Date;
	PREP_T_C_s_Signed_Within_Last_Year__c?: boolean;
	PREP_User_Allowance__c?: number;
	PREP_User_Turnover_Threshhold__c?: string;
	Packaging_Material_Type__c?: string;
	Primary_Industry_Sector_Sub_categories__c?: string;
	Primary_Industry_Sector__c?: string;
	Primary_Supply_Chain_Position__c?: string;
	RedCycle_Partner__c?: boolean;
	Relevant_Membership_Product__c?: string;
	Secondary_Industry_Sector_Sub_categories__c?: string;
	Secondary_Industry_Sector__c?: string;
	Shortname__c?: string;
	Total_PREP_Users__c?: number;
	Trading_Name__c?: string;
	Turnover_Band__c?: string;
	Turnover_Declaration_Due_Date__c?: Date;
	Turnover_Tier__c?: string;
	Voting_Rights_Access__c?: boolean;
	Withdrawn_Reason__c?: string;
	Company_Email__c?: string;
	Date_RedCycle_Partnership__c?: Date;
	Date_NZ_soft_plastics_partnership__c?: Date;
	Date_ARL_Report_Submitted__c?: Date;
	Date_withdrawn_PREP__c?: Date;
	Date_withdrawn_ARL__c?: Date;
	test_id_formula__c?: string;
	ANZPAC_Member_Status__c?: string;
	ANZPAC_Membership_Category__c?: string;
	ANZPAC_Membership_Type__c?: string;
	ANZPAC_Regional_Turnover_Threshhold__c?: string;
	ANZPAC_Sign_up_Date__c?: Date;
	ANZPAC_Withdrawn_Date__c?: Date;
	Business_Identification_Number__c?: string;
	NZ_SA_Relevant_Membership_Product__c?: string;
	Stakeholder_Type__c?: string;
	Regions_In_Pacific_Islands__c?: string;
	Regional_Location__c?: string;
	Executive_Contact_First_name__c?: string;
	PREP_T_C_s_renew_date__c?: Date;
	Contacts_Serialized_State__c?: string;
	First_Approval_of_Conformance_Received__c?: Date;
	RCL_Access__c?: boolean;
	RCL_Submission_Confirmed_By__c?: string;
	RCL_T_C_s_First_Signed__c?: Date;
	RCL_T_C_s_Last_Signed__c?: Date;
	RCL_T_C_s_Signed_Within_Last_Year__c?: boolean;
	ARL_Audit_Participation__c?: Date;
	NMAP_Date_Submitted__c?: Date;
	NMAP_Due_Date__c?: Date;
	Verification_Audit_Participation__c?: Date;
	Renewal__c?: boolean;
	Executive_Contact_Last_name__c?: string;
	ARL_Report_Due_Date__c?: Date;
	ARL_Requirement__c?: string;
	AR_AP_Requirement__c?: string;
	Covenant_V1__c?: string;
	Covenant_V2__c?: string;
	Covenant_V3__c?: string;
	Covenant_Versions__c?: string;
	Parent_Company__c?: boolean;
	PREP_Competent_Override__c?: boolean;
	ANZPAC_Report_Due_Date__c?: Date;
	Date_ANZPAC_Report_Submitted__c?: Date;
	ARL_use_of_logo__c?: Date;
	Renewal_Opp_Support_Date__c?: Date;
	Invoicing_process__c?: string;
	Invoicing_instructions__c?: string;
	Days_Count_after_APCO_Sign_Up_Date__c?: number;
	Test_Account__c?: boolean;
	NMAP_Due_Date_Count__c?: number;
	Welcome_Email_Override__c?: boolean;
	ARL_Program_Withdrawal_Reason__c?: string;
	ARL_Program_withdrawal_notes__c?: string;
	Primary_contact_email__c?: string;
	ARLMP_Buyer_T_C_Signed__c?: Date;
	ARLMP_Heroku_ID__c?: string;
	ARLMP_T_C_Authorised_By__c?: string;
	ARLMP_Vendor_T_C_Signed__c?: Date;
	ARLMP_T_Cs__c?: Date;
	ARLMP_Vendor_Accreditation_Granted__c?: Date;
	ARLMP_Vendor_Accreditation_Submitted__c?: Date;
	ARL_MP_Company_Email__c?: string;
	ARL_MP_Company_Phone__c?: string;
	ARL_MP_Company_Website__c?: string;
	ARL_MP_Sign_Up_Date__c?: Date;
	Non_Member_Pgm_Participant_Text__c?: string;
	Non_Member_Pgm_Participant__c?: string;
	PREP_Org_Id__c?: string;
	ANZSIC_Code__c?: string;
	Supplier_TYPE__c?: string;
}
