import { BaseDto } from "../baseDto"
/* ScheduledTaskParameterBaseDto generated at 2024-05-26 13:54:05.338 +10:00*/
export class ScheduledTaskParameterBaseDto extends BaseDto {
	ScheduleParameterValueId?: string;
	ScheduledTaskId?: string;
	IsNullValue?: boolean;
	SequenceNumber?: number;
	ParamName?: string;
	ParamDataTypeCode?: string;
	ParamTextValue?: string;
	ParamNumberValue?: number;
	ParamDateValue?: Date;
	ParamBooleanValue?: boolean;
	ParamJSON?: string;
}
