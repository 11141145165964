<div *ngIf="showComponent" class="flex-wrap" style="width:calc(100%);height:calc(100%);overflow:auto;">
    <p-dataView #dv1 [value]="data" [rows]="fetchRows" [loading]="loading" [lazy]="true" [paginator]="true"
        [totalRecords]="totalRecords" filterBy="filterBy" (onLazyLoad)="loadData($event)">
        <ng-template pTemplate="header">
            <div *ngIf="!IsMobileView" class="flex-wrap brad-tl4 brad-tr4 pt-3 pb-3"
                [ngClass]="{'hidden':IsMobileView}" style="background-color:#EBF2F8;">
                <div [ngClass]="{'w8':IsMobileView,'column15P':!IsMobileView}">
                    <div class="center w-fit-content font-bold fontsizegov">Material</div>
                </div>
                <div class="flex-fill font-bold fontsizegov">Description and Update Category</div>
                <div [ngClass]="{'w6':IsMobileView,'column15P':!IsMobileView}">
                    <div class="center w-fit-content font-bold fontsizegov">Status</div>
                </div>
                <div [ngClass]="{'w6':IsMobileView,'column15P':!IsMobileView}">
                    <div class="center w-fit-content font-bold fontsizegov">Date live in PREP</div>
                </div>
                <div [ngClass]="{'w8':IsMobileView,'column15P':!IsMobileView}">
                    <div class="center w-fit-content font-bold fontsizegov">PREP report update timeline</div>
                </div>
                <div [ngClass]="{'w8':IsMobileView,'column15P':!IsMobileView}">
                    <div class="center w-fit-content font-bold fontsizegov">Artwork update timeline</div>
                </div>
            </div>
        </ng-template>
        <ng-template let-item let-index="rowIndex" pTemplate="listItem">
            <div *ngIf="!IsMobileView" class="flex-wrap bb-1 pt-3 pb-3 bgc-white" style="border-color:#E5E5E5!important;">
                <div [ngClass]="{'w8':IsMobileView,'column15P':!IsMobileView}">
                    <div class="pl-2 pr-2 text-multiline fontsizegov">{{getMaterial(item)}}</div>
                </div>
                <div class="flex-fill">
                    <div class="flex-wrap">
                        <div class="flex-wrap">
                            <core-display-html-content class="flex-wrap" [htmlContent]="item.UpdateDescription"></core-display-html-content>
                        </div>
                        <div class="flex-wrap mt-4">
                            <div class="flex-wrap">
                                <div class="font-bold fontsizegov">Region</div>
                            </div>
                            <div class="flex-wrap fontsizegov">
                                <div>{{getRegion(item)}}</div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div [ngClass]="{'w6':IsMobileView,'column15P':!IsMobileView}">
                    <div class="center w-fit-content font-bold text-center fontsizegov" [ngClass]="{'fc-dark-turquoise':isNgClassCyan(item),'fc-dark-brown':isNgClassBrown(item)}">{{item.ProgramStatus}}</div>
                </div>
                <div [ngClass]="{'w6':IsMobileView,'column15P':!IsMobileView}">
                    <div class="center w-fit-content text-center fontsizegov">{{item.DisplayGoLiveDateText}}</div>
                </div>
                <div [ngClass]="{'w8':IsMobileView,'column15P':!IsMobileView}">
                    <div class="pl-2 pr-2 fontsizegov">{{item.PREPUpdateTimeline}}</div>
                </div>
                <div [ngClass]="{'w8':IsMobileView,'column15P':!IsMobileView}">
                    <div class="pl-2 pr-2 fontsizegov">{{item.ArtworkUpdateTimeline}}</div>
                </div>
            </div>
            <div *ngIf="IsMobileView" class="flex-wrap bb-1 bgc-white mb-4" style="border-color:#E5E5E5!important;">
                <div class="flex-wrap bb-1 bgc-lightblue">
                    <div class="w8 pt-3 pb-3 pl-2 fontsizegov">Material</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 bgc-white fontsizegov" style="min-height:3em;">{{getMaterial(item)}}</div>
                </div>
                <div class="flex-wrap bb-1 bgc-lightblue">
                    <div class="w8 pt-3 pb-3 pl-2 fontsizegov">Description and Update Category</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 bgc-white" style="min-height:5em;">
                        <div class="flex-wrap">
                            <div class="flex-wrap">
                                <core-display-html-content class="flex-wrap" [htmlContent]="item.UpdateDescription"></core-display-html-content>
                            </div>
                            <div class="flex-wrap mt-4">
                                <div class="flex-wrap">
                                    <div class="font-bold fontsizegov">Region</div>
                                </div>
                                <div class="flex-wrap fontsizegov">
                                    <div>{{getRegion(item)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-wrap bb-1">
                    <div class="w8 bgc-lightblue pt-3 pb-3 pl-2 fontsizegov">Status</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 font-bold fontsizegov" [ngClass]="{'fc-dark-cyan':isNgClassCyan(item),'fc-dark-brown':isNgClassBrown(item)}">{{item.ProgramStatus}}</div>
                </div>
                <div class="flex-wrap bb-1 bgc-lightblue">
                    <div class="w8 pt-3 pb-3 pl-2 fontsizegov">Date live in PREP</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 bgc-white fontsizegov" style="min-height:4.5em;">{{item.DisplayGoLiveDateText}}</div>
                </div>
                <div class="flex-wrap bb-1 bgc-lightblue">
                    <div class="w8 pt-3 pb-3 pl-2 fontsizegov">PREP report update timeline</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 bgc-white fontsizegov" style="min-height:4.5em;">{{item.PREPUpdateTimeline}}</div>
                </div>
                <div class="flex-wrap bb-1 bgc-lightblue">
                    <div class="w8 pt-3 pb-3 pl-2 fontsizegov">Artwork update timeline</div>
                    <div class="flex-fill pt-3 pb-3 pl-2 bgc-white fontsizegov" style="min-height:4.5em;">{{item.ArtworkUpdateTimeline}}</div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>
<home-update-log-list-filter-dialog class="flex-wrap" *ngIf="isFilterVisible" [visible]="isFilterVisible"
    [materialList]="materialList" [regionList]="regionList" [statusList]="statusList" [lazyLoadArgs]="lazyLoadArgs"
    (onSearch)="onSearchEvent($event)" (onClose)="onCloseEvent($event)">
</home-update-log-list-filter-dialog>
