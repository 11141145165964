import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Observable, combineLatest, map } from "rxjs";
import { ApiService, AppContextService, ConfigService } from "./../../../core/service";
import { ActionResult, DataResult, LazyLoadArgs} from "../../../core/model/shared";
import { Constants, DomainValueDto, PageContentDto, WARLProgramDto, WARLResourceDto, WARLSubmissionDto, WARLUpdateLogDto} from "./../../../core/model/feature/apcomodel";

@Injectable({
    providedIn: "root"
})
export class HomeService {
    private apiSvc: ApiService;
    constructor(private appCtx: AppContextService,
        private configSvc: ConfigService) {
        this.apiSvc = this.appCtx.apiSvc;
    }
    getIpLocationDetail(): Observable<ActionResult> {
        let httpParams: HttpParams;
        httpParams = new HttpParams();
        return this.apiSvc.get("/Authentication/GetIpLocationDetail", httpParams);
    }
    getInitWARLProgramDomain(): Observable<{ programMaterialTypes: DataResult<DomainValueDto>,programStatuses: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.configSvc.getDomainValuesByTypeName(Constants.DomainType.WProgramMaterialType),
            this.configSvc.getDomainValuesByTypeName(Constants.DomainType.WProgramStatus)
        ]).pipe(map(resp => {
            return {
                programMaterialTypes: resp[0],
                programStatuses: resp[1]
            };
        }));
    }
    getInitWARLMaterialType(): Observable<{ programMaterialTypes: DataResult<DomainValueDto> }> {
        return combineLatest([
            this.configSvc.getDomainValuesByTypeName(Constants.DomainType.WProgramMaterialType)
        ]).pipe(map(resp => {
            return {
                programMaterialTypes: resp[0]
            };
        }));
    }
    getWARLProgramPagedList(searchData?: LazyLoadArgs): Observable<DataResult<WARLProgramDto>> {
        return this.apiSvc.post("/ARL/GetARLPrograms", searchData) as Observable<DataResult<WARLProgramDto>>;
    }
    getWARLSubmissionPagedList(searchData?: LazyLoadArgs): Observable<DataResult<WARLSubmissionDto>> {
        return this.apiSvc.post("/ARL/GetARLSubmissions", searchData) as Observable<DataResult<WARLSubmissionDto>>;
    }
    getWARLUpdateLogPagedList(searchData?: LazyLoadArgs): Observable<DataResult<WARLUpdateLogDto>> {
        return this.apiSvc.post("/ARL/GetARLUpdateLogs", searchData) as Observable<DataResult<WARLUpdateLogDto>>;
    }
    getWARLResourcePagedList(searchData?: LazyLoadArgs): Observable<DataResult<WARLResourceDto>> {
        return this.apiSvc.post("/ARL/GetARLResources", searchData) as Observable<DataResult<WARLResourceDto>>;
    }
    getPageContentByContentCode(contentCode: string): Observable<DataResult<PageContentDto>> {
        let httpParams: HttpParams = new HttpParams()
        .append("ContentCode", contentCode);
        return this.apiSvc.get("/ARL/GetPageContent", httpParams) as Observable<DataResult<PageContentDto>>;
    }
}