import { BaseDto } from "../baseDto"
/* ProjectAttributeBaseDto generated at 2024-05-26 13:54:05.316 +10:00*/
export class ProjectAttributeBaseDto extends BaseDto {
	ProjectAttributeId?: string;
	ProjectId?: string;
	ProjectItemId?: string;
	ProjectItemGroupId?: string;
	ObjectAttributeVersionId?: string;
	AttributeJSON?: string;
	StatusCode?: string;
}
