import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from '@angular/core';
import { AppContextService } from "./../../../core/service";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
	private authSvc: AuthenticationService;
	private delayChkTimer: any = null;
	constructor(private appCtx: AppContextService) {
		this.authSvc = new AuthenticationService(appCtx);
	}
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return <boolean>await this.chkAuthentication();
	}
	async chkAuthentication() {
		return new Promise((resolve, reject) => {
			try {
				this.authSvc.checkAuthenticated().subscribe(x => {
					this.appCtx.Authenticated = <boolean>x;
					resolve(x);
				});
			} catch (e) {
				resolve(false);
			}
		});
	}
	// checkAuthentications() {
	// 	return new Promise((resolve, reject) => {
	// 		this.appCtx.apiSvc.checkAuthenticated("authGuard 1").subscribe(authenticatedUser => {
	// 			if (authenticatedUser) {
	// 				resolve(true);
	// 			} else {
	// 				resolve(false);
	// 			}
	// 		});
	// 	});
	// }
}