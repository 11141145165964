<div *ngIf="showComponent" style="width: calc(100%); height: calc(100%);" class="flex-inline bgc-white"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">

  <div style="width:25%">
    <a class="navbar-brand py-0" height="100%" href="https://arl.org.au">
      <img class="brand-logo"
           src="images/ARL_website_logo.png" alt="Logo of the Australasian Recycling Label.">
    </a>
  </div>
  <div style="width:75%">
    <div *ngIf="showMenuItems" class="flex-fill">
      <div class="" [ngClass]="{'hidden':IsMobileView}">
        <p-menubar [model]="browserMenu" ></p-menubar>
      </div>
      <div class="right" [ngClass]="{'hidden':!IsMobileView}">
        <button style="margin-top:8px;" pButton type="button" (click)="toggleTopMenu()" icon="pi pi-bars" label="" [ngClass]="{'hidden':showClose}"></button>
        <button style="margin-top:8px;" pButton type="button" (click)="toggleTopMenu()" icon="pi pi-times" label="" [ngClass]="{'hidden':!showClose}"></button>
      </div>
    </div>
    <div *ngIf="IsTopMenuView && IsMobileView" class="box" style="position:absolute; z-index: 10; right: 0.0em;top: 6em;">
       <div class='bgc-white'>
        <p-panelMenu #menu [model]="mobileMenu"  styleClass="popup"></p-panelMenu>
      </div>
    </div>
  </div>
</div>
