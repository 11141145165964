import { BaseDto } from "../baseDto"
/* SFV2CovenantAnnualReportBaseDto generated at 2024-05-26 13:54:05.383 +10:00*/
export class SFV2CovenantAnnualReportBaseDto extends BaseDto {
	Id?: string;
	OwnerId?: string;
	IsDeleted?: boolean;
	Name?: string;
	CreatedDate?: Date;
	CreatedById?: string;
	LastModifiedDate?: Date;
	LastModifiedById?: string;
	SystemModstamp?: Date;
	LastViewedDate?: Date;
	LastReferencedDate?: Date;
	AR_Sect_1_Evidence__c?: string;
	AR_Sect_2_Evidence__c?: string;
	AR_Sect_3_Evidence__c?: string;
	AR_Sect_4_Evidence__c?: string;
	AR_Sect_5_Evidence__c?: string;
	AR_Sect_6_Evidence__c?: string;
	AR_Sect_7_Evidence__c?: string;
	AR_Sect_8_Evidence__c?: string;
	AR_V2_Q1_1__c?: string;
	AR_V2_Q2_1__c?: string;
	AR_V2_Q2_2__c?: string;
	AR_V2_Q2_3__c?: string;
	AR_V2_Q2_4__c?: number;
	AR_V2_Q3_1__c?: string;
	AR_V2_Q3_2_a__c?: string;
	AR_V2_Q3_2_b__c?: string;
	AR_V2_Q3_2_c__c?: string;
	AR_V2_Q4_1__c?: string;
	AR_V2_Q5_1__c?: string;
	AR_V2_Q6_1__c?: string;
	AR_V2_Q7_1_a__c?: boolean;
	AR_V2_Q7_1_b__c?: boolean;
	AR_V2_Q7_1_c__c?: boolean;
	AR_V2_Q7_1_d__c?: boolean;
	AR_V2_Q7_1_d_notes__c?: string;
	AR_V2_Q8_1_Accuracy__c?: string;
	AR_V2_Q8_1_Selected_Materials__c?: string;
	AR_V2_Q8_1__c?: string;
	AR_V2_Q8_2__c?: string;
	AR_V2_Raw_Score__c?: number;
	External_Id__c?: string;
	Member_Account__c?: string;
	Name_Equals_Naming_Convention__c?: boolean;
	Naming_Convention__c?: string;
	No_additional_information__c?: boolean;
	Reporting_period_start_date__c?: Date;
	Submitted_By__c?: string;
	Submitted_On__c?: Date;
	Subsidiaries_Excluded__c?: string;
	Subsidiaries_Included__c?: string;
	T_C_s_Signed_By__c?: string;
	T_C_s_Signed__c?: Date;
	AR_V2_Q7_1_none__c?: boolean;
	AR_V2_Overall_Score__c?: number;
	Overall_Performance_Level__c?: string;
	Submitted_Name__c?: string;
	Submitted_Company__c?: string;
	Submitted_ABN__c?: number;
	Audit_Declaration__c?: boolean;
	No_Subsidiaries__c?: boolean;
	Subsidiaries_ABN_Included__c?: string;
	Subsidiaries_ABN_Excluded__c?: string;
	Subsidiaries_ANZSIC_Code_Excluded__c?: string;
	Subsidiaries_ANZSIC_Code_Included__c?: string;
}
