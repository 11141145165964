import { BaseDto } from "../base/baseDto";

export class LazyLoadArgs extends BaseDto {
	filterName?: string;
	filterTypeCode?: string;
	first: number = 0;
	rows: number = 20;
	globalSearch?: any;
	sortField?: string;
	sortDesc?: boolean = false;
	filters: ColumnFilter[] = [];
	filterIsUpdated: string = "";
	isSet?: boolean = false;
	get SortKey(): string {
		if (this.sortField) {
			return `${this.sortField} ${this.sortDesc ? 'DESC' : ''}`;
		}
		return '';
	}
	infinteScrollTarget: any;
}
export class ColumnFilter {
	Name: string;
	DisplayName: string;
	FilterType: "guidValue" | "multiValue" | "selectBtn" | "dropdown" | "textValues" | "textValue" | "dateRange" | "dateValues" | "dateValue" | "startDateValue" | "endDateValue" | "numberRange" | "numberValues" | "numberValue" | "startNumberValue" | "endNumberValue" | "checkbox" | "boolValue";
	Values: any[] = [];	
	TextValues: string[];
	TextValue?: string;
	DateValues: Date[];
	DateValue?: Date;
	StartDate?: Date;
	EndDate?: Date;
	NumberValues: number[];
	NumberValue?: number;
	StartNumber?: number;
	EndNumber?: number;
	BoolValue?: boolean;
	IsHidden: boolean;
	Options?: any[];
	OptionLabelPropertyName?: string;
	OptionValuePropertyName?: string = "";
	
	get IsFilterActive(): boolean {
		return (this.Values && this.Values !== undefined)
			|| (this.TextValues && this.TextValues !== undefined)
			|| (this.TextValue !== null && this.TextValue !== undefined)
			|| (this.DateValues && this.DateValues !== undefined)
			|| (this.DateValue !== null && this.DateValue !== undefined)
			|| (this.StartDate !== null && this.StartDate !== undefined)
			|| (this.EndDate !== null && this.EndDate !== undefined)
			|| (this.NumberValues && this.NumberValues !== undefined)
			|| (this.NumberValue !== null && this.NumberValue !== undefined)
			|| (this.StartNumber !== null && this.StartNumber !== undefined)
			|| (this.EndNumber !== null && this.EndNumber !== undefined)
			|| (this.BoolValue !== null && this.BoolValue !== undefined);
	}
	constructor(name: string, displayName?: string, type?: "guidValue" | "multiValue" | "selectBtn" | "dropdown" | "textValues" | "textValue" | "dateRange" | "dateValues" | "dateValue" | "startDateValue" | "endDateValue" | "numberRange" | "numberValues" | "numberValue" | "startNumberValue" | "endNumberValue" | "checkbox" | "boolValue", isHidden = false) {
		this.TextValues = [];
		this.DateValues = [];
		this.NumberValues = [];
		this.Values = [];
		this.Name = name;
		this.DisplayName = displayName ? displayName : this.Name;
		if (type) {
			this.FilterType = type;
		} else {
			this.FilterType = "selectBtn";
		}
		if (isHidden) {
			this.IsHidden = true;
		} else {
			this.IsHidden = false;
		}
	}
	setValues(properties?: any) {
        if (properties) {
			let that: any = this;
            for (let key in properties) {
                if (this.hasOwnProperty(key)) {
                    that[key] = properties[key];
                } else {
                    that[key] = properties[key];
                }
            }
        }
    }
}