import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from './core/component';
import { AppContextService } from './core/service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent implements OnInit {
  title = 'Australasian Recycling Label';
  constructor(private router: Router, appCtx: AppContextService) {
    super(appCtx);
  }
  ngOnInit(): void {
    try {
      window.scrollTo(0, 1);
    } catch (ex) {
      console.error(ex);
    }
  }
  async initializeApp() {
  }
}
