import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable, switchMap } from "rxjs";
import { BaseComponent } from "../../../../core/component";
import { DataResult } from "../../../../core/model/shared";
import { AppContextService } from "../../../../core/service";
import { HomeService } from "../../service/home.service";
import { MenuItem } from "primeng/api";
import { Constants, DomainValueDto } from "../../../../core/model/feature/apcomodel";

@Component({
    selector: 'home-priority-areas-page',
    templateUrl: 'priority-areas-page.component.html'
})
export class HomePriorityAreasPage extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    viewTitle: string = "";
    pagePath: string = "/priority-areas";
    id: string = "";
    isIRCVisible: boolean = false;
    isNZASCVisible: boolean = false;
    isMSCVisible: boolean = false;
    isIRPVisible: boolean = false;
    isProgramFilterVisible: boolean = false;
    constructor(
        private route: ActivatedRoute,
        appCtx: AppContextService,
        public svc: HomeService) {
        super(appCtx);
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.appCtx.pagePath = this.pagePath;
            this.loadConfigData();
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    loadConfigData() {
        try {
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    setConfig() {
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            await this.getMenu();            
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;

            that.navigationSubscription = that.appCtx.onNavigationEnd().subscribe((e: any) => {
                if (that.showComponent && that.isCurrentPage(that.pagePath)) {
                    that.getMenu();
                    that.setMenu();
                }
            });
            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe(data => {
                    that.setViewMode();
                });
            }
            that.setMenu();
            that.setViewMode();
        }, 1000);
    }
    setMenu() {
        try {
            this.showMenuItems = false;
            let that = this;
            this.delaySetMenuTimer = setTimeout(function () {
                if (that.delaySetMenuTimer) {
                    clearTimeout(that.delaySetMenuTimer);
                }
                that.delaySetMenuTimer = undefined;
                that.browserMenu = that.browserMenuItems;
                that.mobileMenu = that.mobileMenuItems;
                that.showMenuItems = true;
            }, 100);
        } catch (ex) {
            console.error(ex);
        }
    }
    async getMenu() {
        try {
            this.browserMenuItems = [];
            this.mobileMenuItems = [];
            let menuItem: MenuItem = {
                label: "ARL Marketplace",
                url: "https://www.arlmarketplace.org.au/what-is-the-arl",
                replaceUrl: true
            };
            this.browserMenuItems.push(menuItem);
            this.mobileMenuItems.push(menuItem);

            menuItem = {
                label: "Governance",
                styleClass:"bgc-yellow brad4",
                routerLink: "/priority-areas",
                replaceUrl: true
            };
            this.browserMenuItems.push(menuItem);
            this.mobileMenuItems.push({
                label: "Governance",
                styleClass:"bgc-yellow",
                routerLink: "/priority-areas",
                replaceUrl: true
            });

            menuItem = {
                label: "Your recycling guide",
                routerLink: "/",
                replaceUrl: true
            };
            this.browserMenuItems.push(menuItem);
            this.mobileMenuItems.push(menuItem);
            
            // menuItem = {
            //     label: "Admin",
            //     routerLink: "/admin",
            //     replaceUrl: true
            // };
            // this.browserMenuItems.push(menuItem);
            // this.mobileMenuItems.push(menuItem);
        } catch (ex) {
            console.error(ex);
        }
    }
    setViewMode() {

    }
    getId(): Observable<string> {
        return this.route.paramMap.pipe(switchMap((params: ParamMap) => {
            try {
                let id = params.getAll("id");
                if (id.length) {
                    return id;
                } else {
                    return [""];
                }
            } catch (ex) {
                console.log(ex);
                return [""];
            }
        }));
    }
    showProgramFilter() {
        this.isProgramFilterVisible = true;
    }
}