<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-darkturquoise">
    <div class="body-container p2 mt-4 mb-4">
      <div class="flex-wrap">
        <div class="flex-wrap p2">
          <div class="flex-wrap fc-white">
            <h2 class="govheaderfontsize">GOVERNANCE</h2>
          </div>
          <div class="flex-wrap fc-white">
            <h1 class="heading text headinglineheight">Resources</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="body-container p2  ">
      <div class="flex-wrap mt-7 mb-8">
        <div class="column2 p2">
          <div class="fc-dark-turquoise parentfont">
            <!--<h2 class="contentheader h2section">Resources</h2>-->
            &nbsp;
          </div>
        </div>
        <div class="column2 p2">
          <form name="thisForm" class="flex-wrap" #thisForm="ngForm" autocomplete="off" novalidate>
            <div class="right flex-inline fontsize">
              Search <input type="text" class="ml-4" name="txtResourceSearch"
                            [(ngModel)]="txtResourceSearch">
            </div>
          </form>
        </div>
      </div>
      <div class="flex-wrap ">
        <home-resource-list #resourceListComponent class="flex-wrap" style="height: calc(100%);"
                            [searchValue]="txtResourceSearch" [name]="'resourceList'"
                            [(isFilterVisible)]="isProgramFilterVisible" [isFromAdmin]="false">
        </home-resource-list>
      </div>
    </div>
  </div>
  <div class="flex-wrap">
    <gov-footer [pagePath]="pagePath" class="flex-wrap"></gov-footer>
  </div>
</div>
