import { BaseDto } from "../baseDto"
/* OrganisationBaseDto generated at 2024-05-26 13:54:05.281 +10:00*/
export class OrganisationBaseDto extends BaseDto {
	OrganisationId?: string;
	OrganisationNo?: string;
	OrganisationName?: string;
	IsAUCompany?: boolean;
	ABN?: string;
	AnnualTurnover?: string;
	PackagingInterestCode?: string;
	ARLM_OrganisationId?: string;
	ARLM_StatusCode?: string;
	PREP_OrganisationId?: number;
	PREP_StatusCode?: string;
	SF_AccountId?: string;
	IsSFAccountName?: boolean;
	IsPREPAccountName?: boolean;
	LastExtractJson?: string;
	AnnualTurnoverBand?: string;
	APCOMemberStatusCode?: string;
	ANZPACMemberStatusCode?: string;
	IsEnableDb?: boolean;
	DbActionStatus?: string;
	IsEnableTestDb?: boolean;
	TestDbActionStatus?: string;
	StatusCode?: string;
}
