import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Guid } from '../../model/shared';
import { AppContextService } from '../../service/base/app-context.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: "core-display-html-content",
    templateUrl: "./display-html-content.component.html"
})
export class CoreDisplayHtmlContentCmpnt extends BaseComponent implements OnInit, OnDestroy {
    compKey: string = Guid.newGuid();
    isShowHelpInfo: boolean = false;
    @Input() htmlContent: string = "";
    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadConfigData();
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }

    loadConfigData() {
        try {
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    setConfig() {
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
        }
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;
            that.setDefaultData();
        }, 1000);
    }
    setDefaultData() {
        let htmlContent = document.getElementById("htmlEditorView_" + this.CMId);
        if (htmlContent && this.compKey && this.htmlContent) {
            let htmlStr = this.appCtx.sanSvc.sanitize(2, this.htmlContent);
            if (htmlStr) {
                htmlContent.innerHTML = htmlStr;
            } else {
                htmlContent.innerHTML = "";
            }
        }
    }
}
