<div *ngIf="showComponent && system !== 'anzpac' && system !== 'anzpacwhite'" class="flex-wrap">
    <div id="help-info-{{compKey}}" class="info-button"
        (click)="isShowHelpInfo=!isShowHelpInfo"
        (clickElseWhere)="hideHelpInfo()">
        <div class="center info-img-container"><i class="info-img fas fa-info-circle"></i></div>
        <div class="flex-fill info-bubble" [ngClass]="{'hidden': !isShowHelpInfo}">
            <div [innerHTML]="helpMessageHtml" style="font-size:0.8em;"></div>
        </div>
    </div>
</div>

<div *ngIf="showComponent && system === 'anzpac'" class="flex-wrap">
  <div id="help-info-{{compKey}}" class="info-button-anzpac"
       (click)="isShowHelpInfo=!isShowHelpInfo"
       (clickElseWhere)="hideHelpInfo()">
    <div class="center info-img-container"><i class="info-img fas fa-info-circle"></i></div>
    <div class="flex-fill info-bubble" [ngClass]="{'hidden': !isShowHelpInfo}">
      <div [innerHTML]="helpMessageHtml" style="font-size:0.8em;"></div>
    </div>
  </div>
</div>

<div *ngIf="showComponent && system === 'anzpacwhite'" class="flex-wrap">
  <div id="help-info-{{compKey}}" class="info-button-anzpacwhite"
       (click)="isShowHelpInfo=!isShowHelpInfo"
       (clickElseWhere)="hideHelpInfo()">
    <div class="center info-img-container"><i class="info-img fas fa-info-circle"></i></div>
    <div class="flex-fill info-bubble" [ngClass]="{'hidden': !isShowHelpInfo}">
      <div [innerHTML]="helpMessageHtml" style="font-size:0.8em;"></div>
    </div>
  </div>
</div>
