import { BaseDto } from "../baseDto"
/* SFVCCovenantAnnualReportBaseDto generated at 2024-05-26 13:54:05.401 +10:00*/
export class SFVCCovenantAnnualReportBaseDto extends BaseDto {
	Id?: string;
	OwnerId?: string;
	IsDeleted?: boolean;
	Name?: string;
	CreatedDate?: Date;
	CreatedById?: string;
	LastModifiedDate?: Date;
	LastModifiedById?: string;
	SystemModstamp?: Date;
	LastViewedDate?: Date;
	LastReferencedDate?: Date;
	Account_Id__c?: string;
	Due_Date__c?: Date;
	External_Id__c?: string;
	Member_Account__c?: string;
	Name_Equals_Naming_Convention__c?: boolean;
	Naming_Convention__c?: string;
	Report_Version__c?: string;
	Status__c?: string;
	Version_1_Id__c?: string;
	Version_2_Id__c?: string;
	Version_3_Id__c?: string;
	PDF_stored_on__c?: Date;
	Upload_to_s3__c?: boolean;
	Review_Complete__c?: boolean;
	APCO_Sign_up_Date__c?: Date;
	Conduct_Report_Review__c?: boolean;
	Date_Reviewed__c?: Date;
	Overall_Score__c?: number;
	Primary_Industry_Sector__c?: string;
	Review_Outcome__c?: string;
	Reviewer__c?: string;
	Submitted_Date__c?: Date;
	Version_Actioned__c?: string;
	Review_Approved__c?: boolean;
}
