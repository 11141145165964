import { BaseDto } from "../baseDto"
/* ProductBaseDto generated at 2024-05-26 13:54:05.308 +10:00*/
export class ProductBaseDto extends BaseDto {
	ProductId?: string;
	ProductVersionNo?: number;
	BrandName?: string;
	PartNo?: string;
	ProductCode?: string;
	ProductName?: string;
	ProductDescription?: string;
	ProductSpecification?: string;
	AvailableOnShop?: boolean;
	IsProductComponent?: boolean;
	IsAttachPart?: boolean;
	SKU?: string;
	UPC?: string;
	MOQ?: number;
	MOQTerms?: string;
	Colour?: string;
	Industry?: string;
	ProductType?: string;
	MaterialType?: string;
	MarketSegment?: string;
	Volume_mm?: number;
	Capacity_ml?: number;
	InteriorHeight?: number;
	InteriorWidth?: number;
	InteriorLength?: number;
	InteriorDiameter?: number;
	InteriorNeckFinish?: number;
	ExteriorHeight?: number;
	ExteriorWidth?: number;
	ExteriorLength?: number;
	ExteriorDiameter?: number;
	ExteriorNeckFinish?: number;
	ProductWeight_g?: number;
	PeakLoad_kg?: number;
	SupportType?: string;
	Shape_Style?: string;
	Usages?: string;
	RecycledContent?: string;
	FoodContactApproval?: boolean;
	Stackability?: boolean;
	Vented?: boolean;
	SearchViewImage?: string;
	DetailViewimages?: string;
	AttributeJSON?: string;
	ARLMarketplaceCode?: string;
	MfgDistType?: string;
	OrganisationId?: string;
	OrganisationName?: string;
	ProjectId?: string;
	ContactId?: string;
	SF_AccountId?: string;
	Supplier_OrganisationId?: string;
	Supplier_ContactId?: string;
	Org_ProductId?: string;
	Org_ProjectId?: string;
	Org_SupplierOrganisationId?: string;
	Org_SupplierContactId?: string;
	Org_StatusCode?: string;
	ARLM_OrganisationId?: string;
	ARLM_ProductId?: string;
	ARLM_ProjectId?: string;
	ARLM_UserId?: string;
	ARLM_StatusCode?: string;
	PREP_OrganisationId?: number;
	PREP_ProjectId?: number;
	PREP_UserId?: number;
	PREP_ProjectName?: string;
	StatusCode?: string;
}
