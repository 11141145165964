import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from "../../../../core/component";
import { AppContextService } from "../../../../core/service";
import { UserDto } from 'src/app/core/model/feature/apcomodel';
@Component({
    selector: "auth-change-password-dialog",
    templateUrl: "./change-password-dialog.component.html"
})
export class AuthChangePasswordDialog extends BaseComponent implements OnInit, OnDestroy {
    @Input() visible: boolean = false;
    @Output() onSuccess = new EventEmitter<any>();
    @Output() onFailed = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();

    @Input() user: UserDto = new UserDto();    
    @Input() showCurrentPassword: boolean = false;

    constructor(appCtx: AppContextService) {
        super(appCtx);
        try {
            // this.navigationSubscription = this.appContext.onNavigationEnd().subscribe((e: any) => {
            //     if (this.showComponent) {
            //         this.loadInitData();
            //     }
            // });
        } catch (ex) {
            
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
        } catch (ex) {
            
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadInitData();
        } catch (ex) {
            
            console.error(ex);
        }
        try {
            this.initPage();
        } catch (ex) {
            
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
        } catch (ex) {
            console.error(ex);
            
        }
    }
    initPage() {
        try {
            this.showComponent = true;
        } catch (ex) {
            
            console.error(ex);
        }
    }
    onDialogSuccess(success: any) {
        this.onSuccess.emit(success);        
    }
    onDialogFailed(error: any){
        this.onFailed.emit(error);
    }
    onDialogClose(close: any){
        this.onClose.emit(close);
    }
}