import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';
import { AuthGuard } from './service/auth-guard';
import { AuthenticationService } from './service/authentication.service';
import { AuthChangePasswordCmpnt, AuthChangePasswordDialog, AuthConfirmAccountPage, AuthForgotAccountCmpnt, AuthForgotAccountDialog, AuthLocalSignInCmpnt, AuthResetPasswordCmpnt, AuthResetPasswordPage, AuthSignInCmpnt, AuthSignInDialog } from './component';

@NgModule({
	imports: [
		CommonModule, FormsModule, ReactiveFormsModule, CoreModule
	],
	declarations: [
		AuthSignInDialog, AuthSignInCmpnt, AuthLocalSignInCmpnt,
		AuthChangePasswordCmpnt, AuthChangePasswordDialog, AuthConfirmAccountPage,
		AuthForgotAccountCmpnt, AuthForgotAccountDialog, 
		AuthResetPasswordCmpnt, AuthResetPasswordPage
	],
	exports: [
		AuthSignInDialog, AuthSignInCmpnt, AuthLocalSignInCmpnt,
		AuthChangePasswordCmpnt, AuthChangePasswordDialog, AuthConfirmAccountPage,
		AuthForgotAccountCmpnt, AuthForgotAccountDialog, 
		AuthResetPasswordCmpnt, AuthResetPasswordPage
	],
	providers: [
		AuthGuard, AuthenticationService
	]
})
export class AuthModule {
}