import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Observable, combineLatest, map } from "rxjs";
import { AppContextService } from "../../../../core/service";
import { DataResult, ColumnFilter, LazyLoadArgs } from "../../../../core/model/shared";
import { DataView } from "primeng/dataview";
import { DataViewBaseComponent } from "../../../../core/component/data-view.base.component";
import { HomeService } from "../../service/home.service";
import { Constants, DomainValueDto, WARLSubmissionDto } from "../../../../core/model/feature/apcomodel";
import { MenuItem } from "primeng/api";

@Component({
    selector: "home-submission-list",
    templateUrl: "./submission-list.component.html",
    styleUrls: ["./submission-list.component.scss"]
})
export class HomeSubmissionListCmpnt extends DataViewBaseComponent<WARLSubmissionDto> implements OnInit, OnDestroy {
    showAdd: boolean = false;
    viewTitle: string = "Domain Values";
    @ViewChild("thisForm") thisForm: NgForm | undefined;
    @ViewChild("dv") dataview: DataView | undefined;
    materialList: DomainValueDto[] = [];
    regionList: DomainValueDto[] = [];
    @Input() name: string = "";
    @Input() isFromAdmin: boolean = false;
    @Input() isFilterVisible: boolean = false;
    @Input() isHistorical: boolean = false;
    @Output() isFilterVisibleChange = new EventEmitter<boolean>();

    constructor(
        appCtx: AppContextService,
        private svc: HomeService
    ) {
        super(appCtx);
        try {
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnDestroy() {
        try {
            this.destroySubscriptions();
            this.onDestroy();
        } catch (ex) {
            console.error(ex);
        }
    }
    ngOnInit() {
        try {
            this.loadConfigData();
            this.loadInitData();
        } catch (ex) {
            console.error(ex);
        }
    }
    loadConfigData() {
        try {
            this.setConfig();
        } catch (ex) {
            console.error(ex);
        }
    }
    setConfig() {
        try {
            this.fetchRows = 5;
            this.defaultSortField = "CommentStartDate";
            this.defaultSortDesc = true;
            this.filterBy = "SubmissionNo,Stakeholder";
            this.sortCols = [{ value: "SubmissionNo", label: "Submission No" },
            { value: "Stakeholder", label: "Brand" },
            { value: "CommentStartDate", label: "Start Date" }];

            let tfilter = new ColumnFilter("SubmissionNo", "Submission No", "textValue", false);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            tfilter = new ColumnFilter("Stakeholder", "Brand", "textValue", false);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            tfilter = new ColumnFilter("CommentStartDate", "Start Date", "startDateValue", false);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            tfilter = new ColumnFilter("SubmissionStatus", "Status", "textValue", true);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);
            
            tfilter = new ColumnFilter("SubmissionRegion", "Region", "textValues", true);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            tfilter = new ColumnFilter("SubmissionMaterial", "Material", "textValues", false);
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            tfilter = new ColumnFilter("RecordType", "RecordType", "textValue", true);
            tfilter.TextValue = this.isHistorical ? "History" : "Current";
            this.filterConfigs = this.sharedSvc.addFilter(this.filterConfigs, tfilter);

            this.setFilters(this.filterConfigs);

            this.regionList = [];
            this.regionList.push(new DomainValueDto({ CodeValue: "AUS", NameValue: "Australia" }));
            this.regionList.push(new DomainValueDto({ CodeValue: "NZ", NameValue: "New Zealand" }));
        } catch (ex) {
            console.error(ex);
        }
    }
    async loadInitData() {
        try {
            this.initPage();
            this.getInitData().subscribe(data => {
                this.materialList = JSON.parse(JSON.stringify(data.programMaterialTypes)).filter((x: DomainValueDto) => x.CodeValue != Constants.WProgramMaterialType.Allmaterials);
                this.initPage();
            }, (loaderror: any) => {
                this.initPage();
            });
        } catch (ex) {
            console.error(ex);
        }
    }
    getInitData(): Observable<any> {
        return combineLatest([
            this.svc.getInitWARLMaterialType()
        ]).pipe(map(resp => {
            return {
                programMaterialTypes: resp[0].programMaterialTypes.DataList
            };
        }));
    }
    initPage() {
        try {
            this.showComponent = true;
            this.setDelayLoadTimer();
        } catch (ex) {
            console.error(ex);
        }
    }
    setDelayLoadTimer() {
        let that = this;
        this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
                clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;

            that.loadCompletedSubscription = that.loadCompleted.subscribe(result => {
                if (that.dataview && that.dataview.first !== that.lazyLoadArgs.first) {
                    that.dataview.paginate({ first: that.lazyLoadArgs.first, rows: that.lazyLoadArgs.rows });
                }
            });

            if (!that.windowSizeChangedSubscription) {
                that.windowSizeChangedSubscription = that.appCtx.iAppCtx.onWindowSizeChanged().subscribe(data => {
                    that.setViewMode();
                });
            }
            that.setMenu();
            that.setViewMode();
        }, 1000);
    }
    setMenu() {
        try {
            this.showMenuItems = false;
            let that = this;
            this.delaySetMenuTimer = setTimeout(function () {
                if (that.delaySetMenuTimer) {
                    clearTimeout(that.delaySetMenuTimer);
                }
                that.delaySetMenuTimer = undefined;
                that.browserMenu = that.browserMenuItems;
                that.mobileMenu = that.mobileMenuItems;
                that.showMenuItems = true;
            }, 100);
        } catch (ex) {
            console.error(ex);
        }
    }
    async getMenu() {
        try {
            this.browserMenuItems = [];
            this.mobileMenuItems = [];
        } catch (ex) {
            console.error(ex);
        }
    }
    setViewMode() {

    }
    getAllowSubmissionText(item: WARLSubmissionDto) {
        if (item.CommentStartDate && this.utilSvc.dateSecondsDiff(new Date(), item.CommentStartDate) > 0) {
            if (item.CommentEndDate && this.utilSvc.dateSecondsDiff(new Date(), item.CommentEndDate) < 0) {
                return "Closed";
            } else {
                return "Yes";
            }
        } else {
            return "Not open";
        }
    }
    getAllowSubmission(item: WARLSubmissionDto) {
        if (item.CommentStartDate && this.utilSvc.dateSecondsDiff(new Date(), item.CommentStartDate) > 0 &&
            (!item.CommentEndDate || (item.CommentEndDate && this.utilSvc.dateSecondsDiff(new Date(), item.CommentEndDate) > 0))) {
            return true;
        } else {
            return false;
        }
    }
    setVisible(item: WARLSubmissionDto) {
        if (item.isVisible) {
            item.isVisible = false;
        } else {
            item.isVisible = true;
        }
    }
    lazyLoad(event?: LazyLoadArgs): Observable<DataResult<WARLSubmissionDto>> {
        return this.svc.getWARLSubmissionPagedList(event);
    }
    isNgClassCyan(item: WARLSubmissionDto) {
        let retValue = false;
        if (Constants.WSubmissionStatus.Values.find(x => x.CodeValue == Constants.WSubmissionStatus.Openforpubliccomment && x.NameValue == item.SubmissionStatus) || 
        Constants.WSubmissionStatus.Values.find(x => x.CodeValue == Constants.WSubmissionStatus.Approved && x.NameValue == item.SubmissionStatus))
            retValue = true;
        return retValue;
    }
    isNgClassBrown(item: WARLSubmissionDto){
        let retValue = false;
        if (Constants.WSubmissionStatus.Values.find(x => x.CodeValue == Constants.WSubmissionStatus.PendingCommitteereview && x.NameValue == item.SubmissionStatus) || 
        Constants.WSubmissionStatus.Values.find(x => x.CodeValue == Constants.WSubmissionStatus.Onhold && x.NameValue == item.SubmissionStatus))
            retValue = true;
        return retValue;
    }
    onSearchEvent(filterArg: LazyLoadArgs) {
        this.lazyLoadArgs = filterArg;
        this.loadData()
        this.isFilterVisible = false;
        this.isFilterVisibleChange.emit(this.isFilterVisible);
    }
    onCloseEvent(close: any) {
        this.isFilterVisible = false;
        this.isFilterVisibleChange.emit(this.isFilterVisible);
    }
}