export class BaseResult {
    IsSuccess?: boolean;
    Messages?: ResultMessage[] = [];
    ErrorDetail?: string;
    LastUpdatedDate?: Date;
    
    // For HTTPResponse
    message?: string;
    status?: number;
    statusText?: string;
    error?: Error;
    url?: string;
    constructor(properties?: any) {
        if (properties) {
            let that: any = this;
            for (let key in properties) {
                if (that.hasOwnProperty(key)) {
                    that[key] = properties[key];
                } else {
                    that[key] = properties[key];
                }
            }
        }
    }
}

export class ResultMessage {
    MessageType?: ResultMessageType;
    Message: string = "";
}

export enum ResultMessageType {
    Error = 0,
    ValidationError = 1,
    Warning = 2,
    Notification = 3,
    Success = 4
}
export class Error {
    error?: string;
    error_description?: string;
}