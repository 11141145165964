<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>


  <div class="flex-wrap" style=" background-color: #0B605E !important; align-items: stretch;">
    <div class="leftheader">
      <div class="headercontent" style="min-height:10em">
        <h1 class="heading text">ARL for business</h1>
        <h2 class="subtext">Supporting organisations to design and communicate packaging recyclability.</h2>
      </div>
    </div>
    <div class="rightheader">
      <img src="images/hero_business.jpg" alt="Flatlay of multiple packaging applicable for ARL.">
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-7">
      <div class="body-container p2">
        <div class="flex-wrap">
            <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">The ARL’s impact</h2>
            </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              The ARL ensures the claims you make about packaging recyclability are accurate and evidence-based. A majority of consumers also want more information about how to recycle - join the movement and help more consumers check it before they chuck it!
            </p>
          </div>
          <div class="flex-wrap center">
            <div class="column3ARL">
              <img style="width: 300px; height: 294px" src="images/ARL_on_packaging_business_page_tile1.png" alt="Stat showing ARL is now on more than 500.000 items." />
            </div>
            <div class="column3ARL">
              <img style="width: 300px; height: 294px" src="images/img_ARL_insight_2.png" alt="Stat showing 62% of consumers look to product packaging for recycling information." />
            </div>
            <div class="column3ARL">
              <img style="width: 300px; height: 294px" src="images/img_ARL_insight_3.png" alt="Stat showing ARL awareness steadily grows to more than 70%." />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2">
        <div class="flex-wrap">
            <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">Organisations with ARL</h2>
            </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize">
              Over 1,000 organisations across Australia and New Zealand have joined the ARL program, with the label featured on over 500,000 SKUs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-4 mb-8">
      <div class="body-container">
        <p-carousel [value]="images"
                    [numVisible]="10"
                    [numScroll]="10"
                    [circular]="true"
                    [responsiveOptions]="responsiveOptions"
                    autoplayInterval="7000"
                    [showIndicators]="false">
          <ng-template let-img pTemplate="item">
            <div class="ribbon-members__logo" style="padding-top:60px;"><img class="ribbon-members__img r-vertical-center" [src]="img"></div>
          </ng-template>
        </p-carousel>

      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-lime">
    <div class="flex-wrap mt-4 mb-4">
      <div class="body-container p2">
        <div class="flex-wrap">
            <div class="fc-dark-turquoise center parentfont">
              <h2 class="contentheader" style="text-align:center">The ARL is available to Australian Packaging Covenant Organisation (APCO) members</h2>
            </div>
          <div class="flex-wrap">
            <div class="center mt-4">
              <div class="brad2 bgc-yellow big-btn w20">
                <div class="r-centroid w-fit-content font-bold">
                  <a class="fc-black fontsize" target="_blank" rel="noopener noreferrer"
                     href="https://apco.org.au/about-membership-signatory">
                    Become an APCO member
                  </a>
                </div>
              </div>

            </div>
          </div>
          <div class="flex-wrap mt-4">
            <div class="center">
              <a style="color: #0B6261;text-decoration: underline;" class="fontsize"  href="/how-to-join">Learn more about membership</a>
              <a style="color: #0B6261;" class="fontsize"  href="/how-to-join"> ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2">
        <div class="flex-wrap">
            <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">Testimonials</h2>
            </div>
          <div class="flex-wrap mt-4">
            <div class="column15PARL">
              <img style="width: 140px; height: 79px" src="images/testimonial_logo_Aldi.png" alt="Logo of the supermarket chain Aldi." />
            </div>
            <div class="column85PARL">
              <p class="fontsize">
                “At ALDI, we are dedicated to making sustainability both affordable and accessible for our customers. That's why we’re working towards having the Australasian Recycling Label (ARL) on all our exclusive brand product packaging. Using the ARL lets us label our products confidently, because we know it's backed by solid evidence. The ARL gives shoppers clear and easy-to-understand instructions for responsible recycling.”
              </p>
            </div>
          </div>
          <div class="flex-wrap mt-4">
            <div class="column15PARL">
              <img style="width: 138px; height: 43px" src="images/testimonial_logo_Coles.png" alt="Logo of the supermarket chain Coles." />
            </div>
            <div class="column85PARL">
              <p class="fontsize">
                “At Coles, we put our customers first, that’s why we were early adopters of the ARL Program. We know our customers want us to reduce unnecessary packaging and make it easier for them to recycle. Today, we are proud to say that we have added the ARL to the artwork of 100% of our Coles Own Brand product packaging. We encourage all our suppliers and industry partners to join us in this commitment to make recycling simpler and easier to understand by adding the ARL to their packaging.”
              </p>
            </div>
          </div>
          <div class="flex-wrap mt-4">
            <div class="column15PARL">
              <img style="width: 135px; height: 32px" src="images/testimonial_logo_Woolworths.png" alt="Logo of the supermarket chain Woolworths." />
            </div>
            <div class="column85PARL">
              <p class="fontsize">
                “We are immensely proud of our commitment to promoting recycling across all our operations. A key part of this initiative is displaying the ARL on 100% of our own-brand product packaging. Through the ARL program, we've designed our packaging with increased recyclability and have clearly labelled it with responsible disposal instructions to guide our customers.”
              </p>
            </div>
          </div>
          <div class="flex-wrap mt-4">
            <div class="column15PARL">
              <img style="width: 130px; height: 58px" src="images/testimonial_logo_GlowCP.png" alt="Logo of Glow Consumer Products organisation." />
            </div>
            <div class="column85PARL">
              <p class="fontsize">
                “As an SME, we appreciate the support provided by the ARL program (PREP and the ARL team) in evaluating the recyclability of our packaging and providing guidance on packaging queries. The ARL has proven to be an effective marketing resource in communicating packaging disposal information and our commitment to sustainable packaging to our customers.”
              </p>
            </div>
          </div>
          <div class="flex-wrap mt-4">
            <div class="column15PARL">
              <img style="width: 121px; height: 30px" src="images/testimonial_logo_VTech.png" alt="Logo of VTech toy company." />
            </div>
            <div class="column85PARL">
              <p class="fontsize">
                “As a world leader in children’s electronic learning toys, our high-quality products are mostly sold in open giftbox packaging with try-me functionality, requiring a certain amount of different packaging materials. The ARL program and team have guided us to improve this packaging with increased recyclability. This program ensures that our customers in Australia and New Zealand can easily identify how to properly dispose of our product packaging. We’re committed to the continued use of the ARL to build a more sustainable future for children and their toys.”
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-8">
      <div class="body-container p2">
        <div class="flex-wrap">
            <div class="fc-dark-turquoise parentfont">
              <h2 class="contentheader h2section">Join the ARL program</h2>
            </div>
            <div class="flex-wrap parentfont">
              <p class="fontsize psection">
                The ARL program is a valuable benefit provided to members of the Australian Packaging Covenant Organisation (APCO). APCO is a not-for-profit organisation leading the development of a circular economy for packaging in Australia.  You can join APCO as either a large or small business:
                <br />
                <br />
              </p>
              <div class="flex-wrap parentfont">
                <ul class="fontsize">
                  <li class="mt-1">
                    <strong>Large business:</strong> Applies to all Australian / New Zealand businesses in the packaging supply chain with a turnover greater than $5 million.
                  </li>
                  <li class="mt-1">
                    <strong>Small business:</strong> Australian / New Zealand Businesses with a turnover up to $5 million are eligible to join as SMEs for a reduced membership fee.
                  </li>
                </ul>
              </div>
            </div>
            </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="flex-wrap mt-4 mb-8">
      <div class="body-container p2">
        <div class="flex-wrap">
          <div class="flex-wrap">
            <div class="center">
              <div class="brad2 bgc-yellow big-btn w20">
                <div class="r-centroid w-fit-content font-bold">
                  <a class="fc-black fontsize" target="_blank" rel="noopener noreferrer"
                     href="https://apco.org.au/about-membership-signatory">
                    Become an APCO member
                  </a>
                </div>
              </div>

            </div>
          </div>
          <div class="flex-wrap mt-4">
            <div class="center">
              <a style="color: #0B6261;text-decoration: underline;" class="fontsize"  href="/how-to-join">Learn more about membership</a>
              <a style="color: #0B6261;" class="fontsize"  href="/how-to-join"> ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap">
    <arl-footer [pagePath]="pagePath" class="flex-wrap"></arl-footer>
  </div>

</div>
